import { EnvironmentProviders, NgModule, importProvidersFrom } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  providers: [ConfirmationService],
})
export class ConfirmationModule {}

export function provideConfirmation(): EnvironmentProviders {
  return importProvidersFrom(ConfirmationModule);
}
