import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { BookingForTrainingFollowUp } from '@/shared/models/booking-for-training-follow-up';
import { BookingsService, bookingKeys } from '@/shared/services/booking.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { seasonKeys } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { TrainingsService, trainingKeys } from '@/shared/services/training.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { TrainingFollowUpDetailOptionsTableComponent } from '@/trainings/components/training-follow-up-detail-options-table/training-follow-up-detail-table-options.component';
import { TrainingFollowUpDetailTableComponent } from '@/trainings/components/training-follow-up-detail-table/training-follow-up-detail-table.component';
import { TrainingFollowUpFilterHeaderComponent } from '@/trainings/components/training-follow-up-filter-header/training-follow-up-filter-header.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, computed, effect, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TrainingDetailInfoComponent } from '../../components/training-detail-info/training-detail-info.component';

type SearchParams = {
  search: string;
  sort: SortTypesTrainingFollowUpDetailTable;
  order: string;
};

export type SortTypesTrainingFollowUpDetailTable = 'requesterName' | 'status' | 'selectedOptions.length';

export type TrainingFollowUpDetailTableSort = {
  sort: SortTypesTrainingFollowUpDetailTable;
  order: number;
};

export type OptionCounters = {
  option: string;
  count: number;
};

@Component({
  selector: 'tc-training-follow-up-detail.ts',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // primeNG
    CardModule,
    TableModule,
    TabViewModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextareaModule,
    // custom
    TrainingDetailInfoComponent,
    TrainingFollowUpDetailOptionsTableComponent,
    TrainingFollowUpFilterHeaderComponent,
    TrainingFollowUpDetailTableComponent,
    LoaderComponent,
  ],
  providers: [TrainingsService, SearchParamsService, BookingsService],
  templateUrl: './training-follow-up-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFollowUpDetailComponent implements OnInit {
  private readonly id = this._route.snapshot.paramMap.get('id');
  private readonly _queryClient = useQueryClient();
  readonly selectedBookings: WritableSignal<BookingForTrainingFollowUp[]> = signal([]);

  bookingCount = signal(0);

  readonly training = useQuery(
    trainingKeys.followUpById(this.id),
    () => {
      return this._trainingService.getTrainingFollowUpAsync(this.id ?? '');
    },
    {
      onSuccess: () => {
        this.training.data()?.bookings.sort((a, b) => a.requesterName.localeCompare(b.requesterName));
        this.bookingCount.set(this.training.data()?.bookings.length ?? 0);
      },
      onError: () => {
        this._router.navigate(['/404']);
      },
      enabled: computed(() => !!this.id),
    },
  );

  readonly optionCounters = computed(() => {
    const bookings = this.training.data()?.bookings;
    const counters = this.training.data()?.options.map((option) => {
      const count = bookings?.reduce((acc, item) => {
        return item.selectedOptions.includes(option.id) ? acc + 1 : acc;
      }, 0);
      if (!count) {
        return {
          option: option.name!,
          count: 0,
        };
      }
      return {
        option: option.name!,
        count: count!,
      };
    });
    return counters;
  });

  readonly filteredBookings = computed(() => {
    const searchTerms = this.searchValue() ? this.searchValue().split(' ') : [];
    const filtered = this.training
      .data()
      ?.bookings.filter(
        (b) =>
          searchTerms.every((searchTerm) => this.concatBooking(b).includes(searchTerm.toLowerCase())) &&
          b.status !== 'Cancelled',
      );
    return filtered;
  });

  readonly searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchValue = signal(this.searchParams.search);
  readonly sort = signal<TrainingFollowUpDetailTableSort>({
    sort: this.searchParams.sort,
    order: parseInt(this.searchParams.order ?? '-1'),
  });

  readonly verifyBookingMutation = useMutation(this._bookingService.verifyBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Verified.');
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
    },
  });
  readonly cancelBookingMutation = useMutation(this._bookingService.cancelBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Canceled.');
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
    },
  });
  readonly updateBookingMutation = useMutation(this._bookingService.updatePendingBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Update added.');
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
    },
  });
  readonly bookBookingMutation = useMutation(this._bookingService.bookBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Booked.');
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
    },
  });
  readonly startPendingBookingMutation = useMutation(this._bookingService.startPendingBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Pending started.');
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
    },
  });
  readonly startPendingBookingBulkMutation = useMutation(this._bookingService.startPendingBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      if (unchangedIds.length > 0)
        this._toaster.warning(
          `Unable to start pending for ${unchangedIds.length} booking(s) because booking was not in approved state`,
        );
      if (changedIds.length > 0) {
        this.training
          .data()
          ?.bookings.filter((booking) => changedIds.includes(booking.id))
          .forEach((booking) => (booking.status = 'PendingBooking'));
        this._toaster.success(`Successfully started pending ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });
  readonly updatePendingBookingBulkMutation = useMutation(this._bookingService.updatePendingBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      if (unchangedIds.length > 0)
        this._toaster.warning(
          `Unable to update pending for ${unchangedIds.length} booking(s) because booking was not in pending state`,
        );
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully updated ${changedIds.length} pending booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });
  readonly bookBookingBulkMutation = useMutation(this._bookingService.bookBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      if (unchangedIds.length > 0)
        this._toaster.warning(`Unable to book ${unchangedIds.length} booking(s) because booking was already booked`);
      if (changedIds.length > 0) {
        this.training
          .data()
          ?.bookings.filter((booking) => changedIds.includes(booking.id))
          .forEach((booking) => (booking.status = 'Booked'));
        this._toaster.success(`Successfully booked ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });
  readonly verifyBookingBulkMutation = useMutation(this._bookingService.verifyBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.followUpById(this.id));
      this._queryClient.invalidateQueries(bookingKeys.all);
      if (unchangedIds.length > 0)
        this._toaster.warning(`Unable to verify ${unchangedIds.length} booking(s) because booking was not booked`);
      if (changedIds.length > 0) {
        this.selectedBookings()
          .filter((booking) => changedIds.includes(booking.id))
          .forEach((booking) => (booking.status = 'Verified'));
        this._toaster.success(`Successfully verified ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });
  form = new FormGroup({
    comment: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.maxLength(255)],
    }),
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _trainingService: TrainingsService,
    private _bookingService: BookingsService,
    private _searchParamService: SearchParamsService,
    private _toaster: ToastService,
    private _confirmationService: ConfirmationService,
  ) {
    // When changing the filters, update the url
    effect(() => {
      const queryParams = {
        search: this.searchValue() || undefined, // must be undefined to delete the search when not set
        sort: this.sort().sort,
        order: this.sort().sort ? this.sort().order : undefined,
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }
  initializeForm() {
    this.form = new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.maxLength(255)],
      }),
    });
  }
  onVerify(id: string) {
    this.verifyBookingMutation.mutate(id);
  }
  onUpdate({ id, comment }: { id: string; comment: string }) {
    this.updateBookingMutation.mutate({ bookingId: id, comment: comment });
  }
  onBook({ id, comment }: { id: string; comment: string }) {
    const booking = this.training.data()?.bookings.find((b) => b.id === id);
    if (booking)
      this.bookBookingMutation.mutate({ bookingId: id, comment: comment, selectedOptions: booking.selectedOptions });
  }
  onStartPending({ id, comment }: { id: string; comment?: string }) {
    this.startPendingBookingMutation.mutate({ bookingId: id, comment: comment });
  }

  onBulk(mutation: string) {
    switch (mutation) {
      case 'Start Pending':
        this._confirmationService.confirm({
          header: 'Start pending',
          key: 'dialogForBulkActions',
          acceptLabel: 'Start Pending',
          reject: () => {
            this.initializeForm();
          },
          accept: () => {
            this.startPendingBookingBulkMutation.mutate({
              body: { bookingIds: this.selectedBookings().map((b) => b.id), comment: this.form.controls.comment.value },
            });
            this.initializeForm();
          },
        });
        break;
      case 'Update':
        this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

        this._confirmationService.confirm({
          header: 'Update pending',
          key: 'dialogForBulkActions',
          acceptLabel: 'Update pending',
          reject: () => {
            this.initializeForm();
          },
          accept: () => {
            this.updatePendingBookingBulkMutation.mutate({
              bookingIds: this.selectedBookings().map((b) => b.id),
              comment: this.form.controls.comment.value,
            });
            this.initializeForm();
          },
        });
        break;
      case 'Book':
        this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

        this._confirmationService.confirm({
          header: 'Book booking',
          key: 'dialogForBulkActions',
          acceptLabel: 'Book',
          reject: () => {
            this.initializeForm();
          },
          accept: () => {
            this.bookBookingBulkMutation.mutate({
              body: {
                bookingIds: this.selectedBookings().map((b) => b.id),
                comment: this.form.controls.comment.value,
              },
            });
            this.initializeForm();
          },
        });
        break;
      case 'Verify':
        this.verifyBookingBulkMutation.mutate({
          body: {
            bookingIds: this.selectedBookings().map((b) => b.id),
          },
        });
        break;
    }
  }

  onCancel(id: string) {
    const newAmount = this.bookingCount() - 1;
    this.bookingCount.set(newAmount);
    this.cancelBookingMutation.mutate({ bookingId: id });
    if (newAmount <= 0) {
      this._queryClient.invalidateQueries(trainingKeys.followUp);
      this._router.navigate(['/follow-up/trainings']);
    }
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Training details follow-up');
  }

  concatBooking(booking: BookingForTrainingFollowUp) {
    const name = booking.requesterName;
    const status = booking.status;
    const nrOfOptionsSelected = booking.selectedOptions.length;
    return [name, status, nrOfOptionsSelected].join(' ').toLowerCase();
  }

  verifiedBookings() {
    return this.training.data()?.bookings.filter((b) => b.status === 'Verified').length;
  }

  onSortChanges(sort: SortTypesTrainingFollowUpDetailTable, order: number) {
    this.sort.set({ sort: sort, order: order });
  }

  onSelectBookings(bookings: BookingForTrainingFollowUp[]) {
    this.selectedBookings.set(bookings);
  }
}
