<tc-loader *ngIf="training.isLoading()" />
<div *ngIf="!training.isLoading()" class="flex md:flex-row gap-4 p-4 min-h-full flex-col">
  <p-card class="md:w-3/4 w-full">
    <div class="flex flex-col gap-6">
      <tc-training-detail-info
        [trainingDetail]="training.data()"
        [nrOfOptionsSelected]="selectedOptions()?.length"
        (getIcal)="onGetIcal()"
      />
      <div class="flex lg:flex-row flex-col">
        <div class="lg:w-4/6">
          <tc-training-options
            [options]="training.data()?.options"
            (selectedOptionsChange)="onSelectedOptionsChange($event)"
            [selectedOptions]="selectedOptions()!"
          />
        </div>
        <div
          class="flex items-center lg:w-2/6 lg:flex-col lg:items-start lg:justify-start w-full flex-row justify-between"
        >
          <tc-participants-list
            *ngIf="training.data()?.participants && training.data()?.showParticipants"
            class="w-full"
            [participants]="training.data()!.participants"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-6 mt-4">
      <tc-reviews *ngIf="training.data()?.reviews" [reviews]="training.data()!.reviews" />
    </div>
  </p-card>
  <div class="flex flex-col gap-4 w-full md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card>
      <tc-add-booking
        [loading]="addBookingMutation.isLoading"
        [passedDeadline]="isPassedDeadline()"
        [alreadyBooked]="isAlreadyBookedByUser()"
        [users]="users.data()"
        (add)="onAddBooking()"
        (selectedChange)="forUser.set($event)"
      />
    </p-card>
    <p-card *ngIf="!isAlreadyBookedByUser() && !isPassedDeadline() && showRequestPanel()">
      <tc-request-panel
        [loading]="requestBookingMutation.isLoading"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [optionSelected]="hasOptionSelected()"
        (request)="onRequestTraining($event)"
        [training]="training.data()"
      />
    </p-card>
  </div>
</div>
