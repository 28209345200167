<tc-loader *ngIf="!bookings || !options" />

<p-table
  *ngIf="bookings && options"
  [value]="options"
  sortField="name"
  sortMode="single"
  [sortOrder]="1"
  rowGroupMode="subheader"
  [(selection)]="selectedBookings"
  groupRowsBy="id"
  [styleClass]="'p-datatable-sm'"
  [rowHover]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="hidden md:table-cell" style="width: 5%; max-width: 5%"></th>
      <th style="width: 20%; max-width: 20%">Requester</th>
      <th style="width: 20%; max-width: 20%" class="hidden md:table-cell">Status</th>
      <th style="width: 45%; max-width: 45%">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-option>
    <tr pRowGroupHeader style="top: 39px">
      <td colspan="9">
        <div class="w-full flex gap-3 items-center py-2 font-bold">
          <p class="min-w-[33%] max-w-[33%] md:min-w-[10%]">{{ option.name }}:</p>
          <p class="cursor-default flex gap-2 md:gap-4 flex-col md:flex-row">
            <span> Approved: {{ calculateBookingCount(option.id, 'Approved') }} </span>
            <span class="hidden md:block">-</span>
            <span> Pending: {{ calculateBookingCount(option.id, 'PendingBooking') }} </span>
            <span class="hidden md:block">-</span>
            <span> Booked: {{ calculateBookingCount(option.id, 'Booked') }} </span>
            <span class="hidden md:block">-</span>
            <span> Verified: {{ calculateBookingCount(option.id, 'Verified') }} </span>
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-option>
    <ng-container *ngFor="let booking of getBookingsWithOption(option.id)">
      <tr class="w-fit overflow-x-auto cursor-pointer" (click)="onClickBooking(booking.id)">
        <td class="hidden md:table-cell" (click)="stopPropagation($event)">
          <div class="text-center">
            <p-tableCheckbox (click)="onSelect()" [value]="booking" name="select" />
          </div>
        </td>
        <td class="w-min md:w-fit md:max-w-full" style="margin-right: 5px">
          <div class="line-clamp-2 md:line-clamp-none mr-1 md:m-0 cursor-text" (click)="stopPropagation($event)">
            <p>{{ booking.requesterName }}</p>
            <p class="text-xs">{{ booking.requesterEmail }}</p>
          </div>
          <p
            class="md:hidden pl-2 truncate ... max-w-[90%]"
            [class]="booking.status === 'Verified' ? 'text-accent' : ''"
          >
            - {{ booking.status | fStatus }}
          </p>
        </td>
        <td class="hidden md:table-cell" [class]="booking.status === 'Verified' ? 'text-accent' : ''">
          {{ booking.status | fStatus }}
        </td>
        <td>
          <div
            class="grid md:grid-cols-[14rem_1fr] justify-center text-center md:justify-start md:text-left"
            (click)="stopPropagation($event)"
          >
            <p-button
              class="w-fit"
              *ngIf="booking.status === 'Approved'"
              label="Start Pending Booking"
              [link]="true"
              (click)="onStartPending(booking.id)"
            ></p-button>
            <p-button
              class="w-fit"
              *ngIf="booking.status === 'PendingBooking'"
              label="Update Pending Booking"
              [link]="true"
              (click)="onUpdate(booking.id)"
            ></p-button>
            <p-button
              class="w-fit"
              *ngIf="['Approved', 'PendingBooking'].includes(booking.status)"
              label="Book"
              [link]="true"
              (click)="onBook(booking.id)"
            ></p-button>
            <p-button
              class="w-fit"
              *ngIf="['Booked', 'Verified'].includes(booking.status)"
              [label]="booking.status === 'Verified' ? 'Unverify' : 'Verify'"
              [link]="true"
              (click)="onVerify(booking.id)"
            ></p-button>
            <p-button
              class="w-fit"
              *ngIf="booking.status === 'Booked'"
              label="Cancel"
              [link]="true"
              (click)="onCancel(booking.id)"
            ></p-button>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

<p-confirmDialog
  #cd
  key="dialogForStartPendingAndUpdatePending"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
  <ng-template pTemplate="message">
    <div class="min-w-[35rem]">
      <div class="flex content-center">
        <p *ngIf="cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">Deze comment wordt verstuurd op mail.</p>
        <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">
          Deze comment wordt niet op mail verstuurd, maar wel toegevoegd aan de booking.
        </p>
      </div>
      <div *ngIf="cd.confirmation?.acceptLabel?.includes('Book')" class="border rounded text-sm p-2 m-2">
        <ul class="list-disc ml-8">
          <li *ngFor="let comment of comments()">
            <p>{{ comment.date | fDate }} - {{ comment.message }}</p>
          </li>
        </ul>
      </div>
      <form [formGroup]="form">
        <label class="flex gap-1" for="motivation"
          >Comment
          <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Start')" class="text-error">*</p></label
        >

        <textarea
          rows="3"
          id="motivation"
          name="motivation"
          pInputTextarea
          class="w-full"
          [formControl]="form.controls.comment"
        ></textarea>
        <div class="text-error">
          <p
            *ngIf="
              !cd.confirmation?.acceptLabel?.includes('Start') &&
              form.controls.comment.dirty &&
              (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
            "
          >
            Comment is required.
          </p>
          <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
            Comment is too long.
          </p>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()"></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      [disabled]="
        !cd.confirmation?.acceptLabel?.includes('Start') &&
        (form.controls.comment.hasError('required') ||
          form.controls.comment.hasError('whitespace') ||
          form.controls.comment.hasError('maxlength'))
      "
      [label]="cd.confirmation?.acceptLabel ?? 'Send'"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>
