import { Booking } from '@/shared/models/booking';
import { getFormatColor } from '@/shared/models/format';
import { Holiday } from '@/shared/models/holiday';
import { Training } from '@/shared/models/training';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import {
  checkIfBookingsOnDay,
  checkIfHolidaysOnDay,
  checkIfTrainingsOnDay,
  generateColorString,
} from '@/trainings/pages/trainings-calendar/helpers';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfWeek,
} from 'date-fns';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tc-training-calendar-month',
  standalone: true,
  imports: [CommonModule, DialogModule, OverlayPanelModule, FormatDatePipe, TooltipModule, StatusDisplayPipe],
  templateUrl: './training-calendar-month.component.html',
})
export class TrainingCalendarMonthComponent {
  readonly days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  readonly colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
  ];
  readonly trainingsOnSelectedDay = signal<Training[]>([]);
  readonly bookingsOnSelectedDay = signal<Booking[]>([]);
  readonly holidaysOnSelectedDay = signal<Holiday[]>([]);

  private _startDay = signal(new Date());
  private _trainings = signal<Training[]>([]);
  private _bookings = signal<Booking[]>([]);
  private _publicHolidays = signal<Holiday[]>([]);
  private _schoolHolidays = signal<Holiday[]>([]);

  @Input() set startDay(value: Date | undefined) {
    if (!value) return;
    this._startDay.set(value);
  }
  get startDay() {
    return this._startDay();
  }

  @Input() set trainings(value: Training[]) {
    this._trainings.set(value);
  }

  get trainings() {
    return this._trainings();
  }

  @Input() set bookings(value: Booking[]) {
    this._bookings.set(value);
  }
  get bookings() {
    return this._bookings();
  }

  @Input() set publicHolidays(value: Holiday[]) {
    this._publicHolidays.set(value);
  }

  get publicHolidays() {
    return this._publicHolidays();
  }

  @Input() set schoolHolidays(value: Holiday[]) {
    this._schoolHolidays.set(value);
  }

  get schoolHolidays() {
    return this._schoolHolidays();
  }

  @Output() getIcal = new EventEmitter<Booking>();

  readonly currMonth = computed(() => {
    return format(this._startDay(), 'MMM-yyyy');
  });

  readonly firstDayOfMonth = computed(() => {
    return parse(this.currMonth(), 'MMM-yyyy', new Date());
  });

  readonly daysInMonth = computed(() => {
    return eachDayOfInterval({
      start: startOfWeek(this.firstDayOfMonth(), { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(this.firstDayOfMonth()), { weekStartsOn: 1 }),
    });
  });

  constructor(private _router: Router) {}

  getTrainingStyle(day: Date) {
    const trainingsOnDay = checkIfTrainingsOnDay(this.trainings, day);

    if (trainingsOnDay.length === 0) return null;

    const colors = trainingsOnDay.map((training) => {
      return getFormatColor(training.format);
    });

    const colorString = generateColorString(colors);
    return { 'box-shadow': `${colorString}` };
  }

  getBookingStyle(day: Date) {
    const bookingsOnDay = checkIfBookingsOnDay(this.bookings, day);
    if (bookingsOnDay.length > 0) {
      if (isToday(day)) {
        return { 'font-weight': 'bolder', color: '#00E500' };
      }
      return { 'font-weight': 'bolder', color: '#00ff00' };
    }

    return { 'font-weight': '400', color: '#607d8b' };
  }

  getPublicHolidayStyle(day: Date) {
    const publicHolidayOnDay = checkIfHolidaysOnDay(this.publicHolidays, day);
    if (publicHolidayOnDay.length > 0) return { 'background-color': '#d3d3d3' };

    return null;
  }

  getSchoolHolidayStyle(day: Date) {
    const schoolHolidayOnDay = checkIfHolidaysOnDay(this.schoolHolidays, day);
    if (schoolHolidayOnDay.length > 0) return { 'background-color': '#efefef' };

    return null;
  }

  getDayStyle(day: Date) {
    // check if date is in this month
    if (!isSameMonth(day, this.firstDayOfMonth())) return null;

    const trainingStyle = this.getTrainingStyle(day);
    const bookingStyle = this.getBookingStyle(day);
    const publicHolidayStyle = this.getPublicHolidayStyle(day);
    const schoolHolidayStyle = this.getSchoolHolidayStyle(day);

    return { ...trainingStyle, ...bookingStyle, ...schoolHolidayStyle, ...publicHolidayStyle };
  }

  updateTrainingsOnSelectedDay(day: Date) {
    const trainingsOnDay = checkIfTrainingsOnDay(this.trainings, day);
    const bookingsOnDay = checkIfBookingsOnDay(this.bookings, day);
    const publicHolidaysOnDay = checkIfHolidaysOnDay(this.publicHolidays, day);
    const schoolHolidaysOnDay = checkIfHolidaysOnDay(this.schoolHolidays, day);

    this.bookingsOnSelectedDay.set(bookingsOnDay.sort((a, b) => a.training.name.localeCompare(b.training.name)));
    this.trainingsOnSelectedDay.set(trainingsOnDay.sort((a, b) => a.name.localeCompare(b.name)));
    this.holidaysOnSelectedDay.set(
      [...publicHolidaysOnDay, ...schoolHolidaysOnDay].sort((a, b) => a.name.localeCompare(b.name)),
    );
  }

  navigateTrainingDetails(training: Training) {
    this._router.navigate(['/trainings', training.id]);
  }

  navigateBookingDetails(booking: Booking) {
    this._router.navigate(['/bookings', booking.id]);
  }

  onGetIcal(booking: Booking) {
    this.getIcal.emit(booking);
  }

  protected readonly isToday = isToday;
  protected readonly isSameMonth = isSameMonth;
  protected readonly getDay = getDay;
  protected readonly isSameDay = isSameDay;
}
