/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { UserDto } from '../models/user-dto';
import { UserProfileDetailDto } from '../models/user-profile-detail-dto';
import { UserProfileDto } from '../models/user-profile-dto';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPracticeManagers()` */
  static readonly GetPracticeManagersPath = '/api/Users/practice-managers';

  /**
   * Retrieves all PM-Employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPracticeManagers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPracticeManagers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetPracticeManagersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Retrieves all PM-Employees.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPracticeManagers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPracticeManagers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<UserDto>> {
    return this.getPracticeManagers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `getHumanResources()` */
  static readonly GetHumanResourcesPath = '/api/Users/human-resources';

  /**
   * Retrieves all HR-Employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHumanResources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHumanResources$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetHumanResourcesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Retrieves all HR-Employees.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHumanResources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHumanResources(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<UserDto>> {
    return this.getHumanResources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `getCatalogResponsibles()` */
  static readonly GetCatalogResponsiblesPath = '/api/Users/catalog-responsibles';

  /**
   * Retrieves all Catalog Responsibles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogResponsibles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogResponsibles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetCatalogResponsiblesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Retrieves all Catalog Responsibles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalogResponsibles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogResponsibles(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<UserDto>> {
    return this.getCatalogResponsibles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `getResponsiblePracticeManager()` */
  static readonly GetResponsiblePracticeManagerPath = '/api/Users/pm-of-employee';

  /**
   * Get PM of Employee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResponsiblePracticeManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResponsiblePracticeManager$Response(
    params?: {

    /**
     * The email of the employee.
     */
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetResponsiblePracticeManagerPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Get PM of Employee.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResponsiblePracticeManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResponsiblePracticeManager(
    params?: {

    /**
     * The email of the employee.
     */
      email?: string;
    },
    context?: HttpContext
  ): Observable<UserDto> {
    return this.getResponsiblePracticeManager$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/api/Users';

  /**
   * Get Profiles of Employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserProfileDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUsersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserProfileDto>>;
      })
    );
  }

  /**
   * Get Profiles of Employees.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<UserProfileDto>> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserProfileDto>>): Array<UserProfileDto> => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/api/Users/{email}';

  /**
   * Get Profile of Employee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(
    params: {

    /**
     * The email of the employee.
     */
      email: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserProfileDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDetailDto>;
      })
    );
  }

  /**
   * Get Profile of Employee.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(
    params: {

    /**
     * The email of the employee.
     */
      email: string;
    },
    context?: HttpContext
  ): Observable<UserProfileDetailDto> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileDetailDto>): UserProfileDetailDto => r.body)
    );
  }

}
