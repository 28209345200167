import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CancelResult } from '../booking-cancel-panel/booking-cancel-panel.component';
@Component({
  selector: 'tc-booking-book-panel',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    InputTextareaModule,
    CheckboxModule,
    ReactiveFormsModule,
    InfoTooltipComponent,
    ConfirmDialogModule,
  ],
  templateUrl: './booking-book-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingBookPanelComponent {
  readonly form = this._setupForm();

  @Output() readonly book = new EventEmitter<string>();
  @Output() readonly cancel = new EventEmitter<CancelResult>();

  @Input() loading: Signal<boolean> = signal(false);

  @Input() pending: boolean = false;

  @Input() optionSelected?: boolean;

  @Input() set noPassedOptionSelected(value: boolean) {
    this.form.patchValue({ noPassedOptionSelected: value });
  }

  private _requireComment = false;

  @Input() set requireComment(val: boolean | null) {
    this._requireComment = val ?? false;
    if (this._requireComment) {
      this.form.controls.comment.addValidators([
        Validators.required,
        Validators.maxLength(255),
        NoWhitespaceValidatorDirective.noWhitespace(),
      ]);
    } else {
      this.form.controls.comment.clearValidators();
    }
    this.form.controls.comment.updateValueAndValidity();
  }

  get requireComment(): boolean {
    return this._requireComment;
  }

  constructor(private _confirmationService: ConfirmationService) {}

  onBook() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const value = this.form.value;
    this.book.emit(value.comment as string);
  }

  onCancel() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;
    const value = this.form.value;
    if (this.pending) {
      this._confirmationService.confirm({
        header: 'Confirmation cancel',
        key: 'dialogForCancel',
        accept: () =>
          this.cancel.emit({
            comment: this.form.controls.comment.value,
            refundCredits: this.form.controls.refund.value,
          }),
      });
    } else {
      this._confirmationService.confirm({
        message: 'Are you sure you want to cancel the booking?',
        header: 'Confirmation cancel',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.cancel.emit({ comment: value.comment as string, refundCredits: null }),
      });
    }
  }

  private _setupForm() {
    return new FormGroup({
      comment: new FormControl<string>('', { nonNullable: true }),
      noPassedOptionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
      refund: new FormControl<boolean>(false, { nonNullable: true }),
    });
  }
}
