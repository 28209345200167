import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { PageTitleService } from '@/shared/services/page-title.service';
import { reviewKeys, ReviewService } from '@/shared/services/review.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, OnInit, signal, ViewChild } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { RatingModule } from 'primeng/rating';
import { Table, TableModule } from 'primeng/table';

type SortTypes = 'year' | 'reviewer' | 'trainingName' | 'message' | 'rating';

@Component({
  selector: 'tc-all-reviews',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    RatingModule,
    FormatDisplayPipe,
    LoaderComponent,
    SharedModule,
    TableModule,
  ],
  providers: [ReviewService, SearchParamsService],
  templateUrl: './all-reviews.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsFollowUpComponent implements OnInit {
  readonly reviews = useQuery(reviewKeys.all, async () => {
    const reviews = await this._reviewsService.getReviewsAsync(false);
    return reviews.sort((a, b) => (a.trainingName < b.trainingName ? 1 : -1));
  });

  @ViewChild('reviewFollowUpTable') reviewFollowUpTable: Table | undefined;

  readonly searchParams = this._searchParamService.getSearchParams<{
    sort: SortTypes | undefined;
    order: string | undefined;
  }>();
  readonly sort = signal<SortTypes | undefined>(this.searchParams.sort);
  readonly sortOrder = parseInt(this.searchParams.order ?? '-1');

  constructor(
    private _pageTitleService: PageTitleService,
    private _reviewsService: ReviewService,
    private _searchParamService: SearchParamsService,
  ) {
    effect(() => {
      const order = this.reviewFollowUpTable?.sortOrder;
      const queryParams = {
        sort: this.sort(),
        order: order ?? this.sortOrder.toString(),
      };
      if (this.sort()) this._searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Reviews follow-up');
  }

  setSort(sortValue: SortTypes) {
    this.sort.set(undefined);
    this.sort.set(sortValue);
  }
}
