import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'fDate' })
export class FormatDatePipe implements PipeTransform {
  transform(value: Date | undefined, format = 'long') {
    const datePipe = new DatePipe('en-US');
    if (format === 'short') {
      return datePipe.transform(value, 'dd/MM/yy');
    } else if (format === 'longDate') {
      return datePipe.transform(value, 'd MMM, y');
    }

    return datePipe.transform(value, 'dd/MM/yyyy');
  }
}
