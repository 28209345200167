<div class="p-4">
  <div class="hidden md:block">
    <tc-loader *ngIf="bookings.isLoading() && !bookings.data()" />
    <p-card class="w-full" *ngIf="bookings.data()">
      <p-table
        [value]="bookings.data()!"
        sortField="training.season.year"
        sortMode="single"
        [sortOrder]="-1"
        [scrollable]="true"
        rowGroupMode="subheader"
        groupRowsBy="training.season.year"
        scrollHeight="75vh"
        [styleClass]="'p-datatable-sm'"
        [rowHover]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Year</th>
            <th>Status</th>
            <th>Name</th>
            <th>Deadline</th>
            <th>Start</th>
            <th>Format</th>
            <th>Credits</th>
            <th *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
              Responsible
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-booking>
          <tr pRowGroupHeader style="top: 39px">
            <td colspan="9">
              <div class="w-full flex gap-3 items-center py-2 font-bold">
                <p>{{ booking.training.season.year }}:</p>
                <i class="pi pi-credit-card flex self-center ml-6"></i>
                <p class="cursor-default">
                  <span pTooltip="Status booked & cancelled without refund" tooltipPosition="top">
                    Used credits: {{ calculateUsedCredits(booking.training.season.year) }}
                  </span>
                  -
                  <span pTooltip="Status approved" tooltipPosition="top">
                    Approved:
                    {{ calculateApprovedCredits(booking.training.season.year) }}
                  </span>
                  -
                  <span pTooltip="Status added" tooltipPosition="top">
                    Not requested:
                    {{ calculateNotRequestedCredits(booking.training.season.year) }}
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-booking>
          <tr class="w-fit overflow-x-auto cursor-pointer" (click)="onClickBooking(booking.id)">
            <td>{{ booking.training.season.year }}</td>
            <td
              [class]="
                ['Verified', 'Booked', 'Approved'].includes(booking.status)
                  ? 'text-accent'
                  : ['Cancelled', 'Rejected'].includes(booking.status)
                  ? 'text-error'
                  : ''
              "
            >
              {{ booking.status | fStatus }}
            </td>
            <td>{{ booking.training.name }}</td>
            <td>{{ booking.training.deadline ? (booking.training.deadline | fDate) : '/' }}</td>
            <td>
              {{ booking.training.startDate ? (booking.training.startDate | fDate) : '/' }}
            </td>
            <td>
              {{ booking.training.format | fDisplay }}
            </td>
            <td>
              <div class="w-1/2 flex justify-end">{{ booking.credits }}</div>
            </td>
            <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
              {{ booking.training.responsibleName }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="bookings.data()?.length === 0 && !bookings.isLoading()">
        <p class="text-center font-semibold mt-3">No trainings in your plan</p>
      </div>
    </p-card>
  </div>
</div>
<div class="p-4 pt-0">
  <div class="md:hidden">
    <tc-loader *ngIf="!bookingsCurrentSeason()" />
    <p-card class="w-full" *ngIf="bookingsCurrentSeason()">
      <p-table [value]="bookingsCurrentSeason()!" responsiveLayout="stack" [breakpoint]="'1000px'">
        <ng-template pTemplate="body" let-booking>
          <tr (click)="onClickBooking(booking.id)" class="">
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold w-1/3">Season:</span>
                <span class="self-start">{{ booking.training.season.year }}</span>
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Status:</span>{{ booking.status | fStatus }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Name:</span>
                {{ booking.training.name }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Start date:</span>
                {{ booking.training.startDate ? (booking.training.startDate | fDate) : '/' }}
              </div>
            </td>
            <td
              *ngIf="booking.training.deadline && ['Added', 'Requested'].includes(booking.status)"
              style="padding: 0.3rem"
            >
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Deadline:</span>
                {{ booking.training.deadline ? (booking.training.deadline | fDate) : '/' }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="bookingsCurrentSeason()!.length === 0">
        <p class="text-center font-semibold mt-3">No trainings in your plan for this season</p>
      </div>
    </p-card>
  </div>
</div>
