import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
@Component({
  selector: 'tc-booking-verify-panel',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, InfoTooltipComponent],
  templateUrl: './booking-verify-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingVerifyPanelComponent {
  @Input() status?: string;

  @Output() readonly verify = new EventEmitter();

  @Input() loading: Signal<boolean> = signal(false);
}
