/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HolidayDto } from '../models/holiday-dto';

@Injectable({ providedIn: 'root' })
export class HolidaysService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHolidaysPublic()` */
  static readonly GetHolidaysPublicPath = '/api/Holidays/holidays-public';

  /**
   * Retrieves a list of public holidays.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHolidaysPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHolidaysPublic$Response(
    params?: {

    /**
     * Date with the requested year.
     */
      date?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HolidayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, HolidaysService.GetHolidaysPublicPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HolidayDto>>;
      })
    );
  }

  /**
   * Retrieves a list of public holidays.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHolidaysPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHolidaysPublic(
    params?: {

    /**
     * Date with the requested year.
     */
      date?: string;
    },
    context?: HttpContext
  ): Observable<Array<HolidayDto>> {
    return this.getHolidaysPublic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HolidayDto>>): Array<HolidayDto> => r.body)
    );
  }

  /** Path part for operation `getHolidaysSchool()` */
  static readonly GetHolidaysSchoolPath = '/api/Holidays/holidays-school';

  /**
   * Retrieves a list of school holidays.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHolidaysSchool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHolidaysSchool$Response(
    params?: {

    /**
     * Date with the requested year.
     */
      date?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HolidayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, HolidaysService.GetHolidaysSchoolPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HolidayDto>>;
      })
    );
  }

  /**
   * Retrieves a list of school holidays.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHolidaysSchool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHolidaysSchool(
    params?: {

    /**
     * Date with the requested year.
     */
      date?: string;
    },
    context?: HttpContext
  ): Observable<Array<HolidayDto>> {
    return this.getHolidaysSchool$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HolidayDto>>): Array<HolidayDto> => r.body)
    );
  }

}
