<div>
  <tc-toast />
  <p-confirmDialog rejectButtonStyleClass="p-button-outlined p-button-sm" acceptButtonStyleClass="p-button-sm" />
  <router-outlet />
  <!-- prettier-ignore-->
  <div>
        <!-- placeholders to retrieve breakpoints are runtime -->
        <div id="breakpoint-sm" class="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden w-0 h-0"></div>
        <div id="breakpoint-md" class="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden w-0 h-0"></div>
        <div id="breakpoint-lg" class="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden w-0 h-0"></div>
        <div id="breakpoint-xl" class="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden w-0 h-0"></div>
        <div id="breakpoint-2xl" class="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block w-0 h-0"></div>
    </div>
</div>
