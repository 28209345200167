<div *ngIf="user" class="flex items-center">
  <p-avatar *ngIf="userImage" styleClass="mr-2" class="text-base-100" size="xlarge">
    <img [src]="userImage" alt="ProfilePic"/>
  </p-avatar>
  <p-avatar
    *ngIf="!userImage"
    icon="pi pi-user"
    styleClass="mr-2"
    class="text-base-100"
    size="xlarge"
  />
  <div>
    <p class="text-lg">{{ user.name }}</p>
    <p>{{ user.email }}</p>
  </div>
</div>
<p-divider/>
<div class="flex w-full justify-between">
  <p class="text-sm text-secondary flex items-end">v: {{version}}</p>
  <p-button (onClick)="onLogout()" label="Sign out" size="small" [outlined]="true"/>
</div>
