<section class="w-auto mt-4 lg:ml-8 lg:mt-0">
  <p-divider align="left">
    <b class="text-lg">Participants</b>
  </p-divider>
  <section>
    <ul class="list-disc ml-2">
      <li
        *ngFor="let participant of showAllFlag ? participants : participants.slice(0, 5)"
        class="flex gap-1 items-center"
      >
        <p *ngIf="['Approved', 'PendingBooking'].includes(participant.status); else booked">
          <i class="pi pi-clock text-warning" pTooltip="Approved, waiting for booking"></i>
        </p>
        <ng-template #booked><i class="pi pi-check-circle text-info" pTooltip="Booked"></i></ng-template>
        <p class="pl-1">{{ participant.name }}</p>
      </li>
    </ul>
    <p-button
      *ngIf="!showAllFlag && participants.length > 5"
      label="See more..."
      [link]="true"
      (onClick)="onSetShowAllFlag()"
    />
  </section>
  <section *ngIf="participants.length === 0">
    <p class="text-secondary">No participants</p>
  </section>
</section>
