import { BookingDetail } from '@/shared/models/bookingDetail';
import { TrainingDetail } from '@/shared/models/training-detail';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { UserProfileDetail, UsersService } from '@/shared/services/user.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { useAuth } from '@euricom/angular-shared';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { CancelResult } from '../booking-cancel-panel/booking-cancel-panel.component';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';

@Component({
  selector: 'tc-booking-info',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, AvatarModule, TableModule, FormatDatePipe, StatusDisplayPipe],
  providers: [UsersService],
  templateUrl: './booking-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingInfoComponent {
  private _booking = signal<BookingDetail | undefined>(undefined);
  private _training = signal<TrainingDetail | undefined>(undefined);
  readonly user = useAuth().user;
  readonly userImageError = signal<boolean>(false);

  @Output() cancel = new EventEmitter<CancelResult>();
  @Output() delete = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() ownBooking?: boolean;
  @Input() isUpdated = false;

  private _userProfile = signal<UserProfileDetail | undefined>(undefined);

  @Input()
  set userProfile(value: UserProfileDetail | undefined) {
    this._userProfile.set(value);
  }
  get userProfile() {
    return this._userProfile();
  }

  @Input()
  set booking(value: BookingDetail | undefined) {
    this._booking.set(value);
  }
  get booking() {
    return this._booking();
  }

  @Input()
  set training(value: TrainingDetail | undefined) {
    this._training.set(value);
  }
  get training() {
    return this._training();
  }

  constructor(private _confirmationService: ConfirmationService) {}

  onCancel() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.cancel.emit(),
    });
  }

  onDelete() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.delete.emit(),
    });
  }

  onDeleteAdmin() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.delete.emit(),
    });
  }

  onImageError() {
    this.userImageError.set(true);
  }
}
