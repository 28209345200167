<tc-loader *ngIf="training.isLoading() || seasons.isLoading()" />

<div *ngIf="!training.isLoading() && !seasons.isLoading()" class="flex md:flex-row gap-4 p-4 h-full flex-col">
  <div class="w-full">
    <div class="flex flex-col gap-4 pb-4">
      <p-card class="w-full">
        <tc-training-add
          [training]="training.data()"
          [setSeasonOptions]="seasons.data() ?? []"
          (newTraining)="onUpsertTraining($event)"
          [loadingUpsert]="upsertTraining.isLoading"
        />
      </p-card>
    </div>
  </div>
  <div *ngIf="training.data()" class="flex flex-col gap-4 pb-4 md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card class="w-full">
      <tc-training-delete (delete)="onDeleteTraining($event)" />
    </p-card>
    <p-card class="w-full" *ngIf="seasons.data() && seasons.data()!.length > 1">
      <tc-training-copy-to-season
        [seasons]="seasons.data()!"
        (copyToSeason)="onCopyTrainingToSeason($event)"
        [currentSeason]="training.data()?.season"
      />
    </p-card>
    <p-card *ngIf="user()!.isAdmin">
      <tc-add-force-booking
        [loading]="addBookingForceMutation.isLoading"
        [users]="users.data()"
        (add)="onAddBookingForce()"
        (selectedChange)="forUser.set($event)"
      />
    </p-card>
  </div>
</div>
