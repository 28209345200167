import { ToastService } from '@/shared/services/toast.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'tc-toast',
  standalone: true,
  imports: [CommonModule, ToastModule],
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  constructor(private _toastService: ToastService) {}
  onCopyToClipboard(transactionId: string) {
    navigator.clipboard
      .writeText(transactionId)
      .then(() => {
        this._toastService.closeAllToasts();
        this._toastService.success('Copied to clipboard');
      })
      .catch(() => this._toastService.error('Something went wrong'));
  }
}
