<tc-loader *ngIf="!collections.data() && collections.isLoading()" />
<div
  class="p-4 flex flex-col lg:flex-row gap-4 min-h-full w-full"
  *ngIf="collections.data() && !collections.isLoading()"
>
  <div class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4" *ngIf="collections.data()!.length > 0">
    <tc-filter-facet
      type="multiple"
      header="Format"
      [options]="formats()"
      (selectedChange)="formatFilters.set($event)"
      [selected]="formatFilters()"
      class="w-full"
      *ngIf="formats().length > 0"
    />
  </div>
  <p-card class="w-full">
    <div class="flex justify-between">
      <p-button label="Add" size="small" (click)="onClickAddCollection()" />
    </div>
    <p-divider />
    <tc-collection-filter-header (search)="onSearchCollections($event)" [searchValue]="searchValue()" />
    <p-divider />
    <tc-collection-admin-table
      [collections]="filteredCollections()"
      [tableSort]="sort()"
      (clickCollection)="onClickCollection($event)"
      (clickNewTraining)="onClickAddNewTraining($event)"
      (sortChanges)="onSortChanges($event.sort, $event.order)"
    />
  </p-card>
</div>
