<div [tcFormKeydownEvent]="{ onSubmit: onSubmit, onCancel: onCancel, context: this }">
  <p-divider align="left">
    <b>Period</b>
  </p-divider>

  <form [formGroup]="form">
    <div class="flex flex-column gap-2 mt-2 w-full">
      <div class="flex flex-col w-full">
        <label for="year">Year <span class="text-error">*</span></label>
        <input
          id="year"
          name="year"
          class="p-inputtext-sm w-full"
          pInputText
          type="number"
          min="2015"
          max="2040"
          [formControl]="form.controls.year"
          pAutoFocus
          [autofocus]="true"
        />
        <p *ngIf="form.controls.year.dirty && form.controls.year.hasError('required')" class="text-error">
          Year is required
        </p>
        <p *ngIf="form.controls.year.dirty && form.controls.year.hasError('max')" class="text-error">
          Year is too high
        </p>

        <p *ngIf="form.controls.year.dirty && form.controls.year.hasError('min')" class="text-error">Year is too low</p>
        <p
          *ngIf="
            form.controls.year.dirty &&
            form.controls.year.hasError('pattern') &&
            !form.controls.year.hasError('min') &&
            !form.controls.year.hasError('max')
          "
          class="text-error"
        >
          Year should consist of 4 digits only
        </p>
        <p
          *ngIf="
            (form.controls.year.dirty || form.controls.year) && form.controls.year.hasError('seasonYearAlreadyExist')
          "
          class="text-error"
        >
          Season year already exist
        </p>
      </div>
      <div class="flex flex-col w-full">
        <label for="start">Start date <span class="text-error">*</span></label>
        <p-calendar
          inputId="start"
          name="start"
          [firstDayOfWeek]="1"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          [formControl]="form.controls.date.controls.startDate"
        />
        <p
          *ngIf="(form.controls.date.dirty || form.controls.date) && form.controls.date.hasError('falseDate')"
          class="text-error"
        >
          End date should be after start date
        </p>
        <p
          *ngIf="
            (form.controls.date.controls.startDate.dirty || form.controls.date.controls.startDate) &&
            form.controls.date.controls.startDate.hasError('required')
          "
          class="text-error"
        >
          Start date is required
        </p>
      </div>
      <div class="flex flex-col w-full">
        <label for="end">End date <span class="text-error">*</span></label>
        <p-calendar
          inputId="end"
          name="end"
          [firstDayOfWeek]="1"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          [formControl]="form.controls.date.controls.endDate"
        />
        <p
          *ngIf="
            (form.controls.date.controls.endDate.dirty || form.controls.date.controls.endDate) &&
            form.controls.date.controls.endDate.hasError('required')
          "
          class="text-error"
        >
          End date is required
        </p>
      </div>
    </div>

    <p-divider align="left">
      <b>Credit</b>
    </p-divider>

    <div class="flex flex-column gap-2 mt-2 w-full">
      <div class="flex flex-col w-full">
        <label for="multiplier">Multiplier <span class="text-error">*</span></label>
        <input
          id="multiplier"
          name="multiplier"
          class="p-inputtext-sm w-full"
          min="0"
          pInputText
          type="number"
          [formControl]="form.controls.multiplier"
        />
        <p *ngIf="form.controls.multiplier.dirty && form.controls.multiplier.hasError('required')" class="text-error">
          Multiplier is required
        </p>

        <p *ngIf="form.controls.multiplier.dirty && form.controls.multiplier.hasError('min')" class="text-error">
          Multiplier should be positive
        </p>
      </div>
      <div class="flex flex-col w-full">
        <label for="goal">Goal <span class="text-error">*</span></label>
        <input
          id="goal"
          name="goal"
          class="p-inputtext-sm w-full"
          min="0"
          step="1"
          pInputText
          type="number"
          [formControl]="form.controls.goal"
        />
        <p *ngIf="form.controls.goal.dirty && form.controls.goal.hasError('required')" class="text-error">
          Goal is required
        </p>

        <p *ngIf="form.controls.goal.dirty && form.controls.goal.hasError('min')" class="text-error">
          Goal should be positive
        </p>
        <p *ngIf="form.controls.goal.dirty && form.controls.goal.hasError('pattern')" class="text-error">
          Goal doesn't allow commas
        </p>
      </div>

      <div class="flex flex-col w-full">
        <label for="dayCost">Day cost <span class="text-error">*</span></label>
        <input
          id="dayCost"
          name="dayCost"
          class="p-inputtext-sm w-full"
          min="0"
          step="1"
          pInputText
          type="number"
          [formControl]="form.controls.dayCost"
        />
        <p *ngIf="form.controls.dayCost.dirty && form.controls.dayCost.hasError('required')" class="text-error">
          Day cost is required
        </p>

        <p *ngIf="form.controls.dayCost.dirty && form.controls.dayCost.hasError('min')" class="text-error">
          Day cost should be positive
        </p>
        <p *ngIf="form.controls.dayCost.dirty && form.controls.dayCost.hasError('pattern')" class="text-error">
          Day cost doesn't allow commas
        </p>
      </div>
    </div>

    <p-divider align="left">
      <b>Other</b>
    </p-divider>

    <div>
      <label for="nrOfDaysBeforeDeadline"
        >Nr of days before deadline reminder is sent (0 is being ignored)<span class="text-error">*</span></label
      >
      <input
        id="nrOfDaysBeforeDeadline"
        name="nrOfDaysBeforeDeadline"
        min="0"
        class="p-inputtext-sm w-full"
        pInputText
        type="number"
        [formControl]="form.controls.daysBeforeDeadlineToGetNotified"
      />
      <p
        *ngIf="
          form.controls.daysBeforeDeadlineToGetNotified.dirty &&
          form.controls.daysBeforeDeadlineToGetNotified.hasError('required')
        "
        class="text-error"
      >
        Nr of days before deadline is required
      </p>

      <p
        *ngIf="
          form.controls.daysBeforeDeadlineToGetNotified.dirty &&
          form.controls.daysBeforeDeadlineToGetNotified.hasError('min')
        "
        class="text-error"
      >
        Nr of days before deadline should be positive
      </p>
      <p
        *ngIf="
          form.controls.daysBeforeDeadlineToGetNotified.dirty &&
          form.controls.daysBeforeDeadlineToGetNotified.hasError('pattern')
        "
        class="text-error"
      >
        Nr of days before deadline doesn't allow commas
      </p>
    </div>

    <div class="flex flex-row gap-2 mt-4">
      <p-button label="Save" size="small" (onClick)="onSubmit()" [loading]="loadingUpsert" />
      <p-button label="Cancel" size="small" [outlined]="true" (onClick)="onCancel()" />
    </div>
  </form>
</div>
