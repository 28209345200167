import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
@Component({
  selector: 'tc-booking-start-pending-panel',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, InputTextareaModule, ReactiveFormsModule, InfoTooltipComponent],
  templateUrl: './booking-start-pending-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingStartPendingPanelComponent {
  readonly form = this._setupForm();

  @Output() readonly startPending = new EventEmitter<string>();

  @Input() loading: Signal<boolean> = signal(false);

  @Input() optionSelected?: boolean;

  @Input() set noPassedOptionSelected(value: boolean) {
    this.form.patchValue({ noPassedOptionSelected: value });
  }

  constructor() {}

  onStartPending() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const value = this.form.value;
    this.startPending.emit(value.comment as string);
  }

  private _setupForm() {
    return new FormGroup({
      comment: new FormControl<string>('', { nonNullable: true }),
      noPassedOptionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
    });
  }
}
