import { Directive, HostListener, Input } from '@angular/core';
import { Context } from 'vm';

type FormKeydownEventVariables = {
  onSubmit: () => void;
  onCancel: () => void;
  context: Context;
};

@Directive({
  standalone: true,
  selector: '[tcFormKeydownEvent]',
})
export class KeydownDirective {
  @Input('tcFormKeydownEvent') keyConfig?: FormKeydownEventVariables;

  @HostListener('document:keydown', ['$event'])
  handleKeydown(e: KeyboardEvent): void {
    if (this.keyConfig) {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        this.keyConfig.onSubmit.call(this.keyConfig.context || null);
      }
      if (e.key === 'Escape') {
        this.keyConfig.onCancel.call(this.keyConfig.context || null);
      }
    }
  }
}
