import { OptionCounters } from '@/trainings/pages/training-follow-up-detail/training-follow-up-detail.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'tc-training-follow-up-filter-header',
  standalone: true,
  imports: [CommonModule, FormsModule, ChipsModule, ReactiveFormsModule],
  templateUrl: './training-follow-up-filter-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFollowUpFilterHeaderComponent {
  readonly searchControl = new FormControl('', { nonNullable: true });

  @Input()
  set searchValue(value: string) {
    this.searchControl.setValue(value);
  }

  @Input() verifiedAmount?: number;
  @Input() totalAmount?: number;
  @Input() optionCounters?: OptionCounters[];

  @Output()
  readonly searchValueChange = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
}
