import { TrainingDetail } from '@/shared/models/training-detail';
import { TrainingOption } from '@/shared/models/training-option';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';

export function getDefaultSelectableOptions(training: MaybeSignal<TrainingDetail | undefined>): TrainingOption[] {
  const unwrappedTraining = unsignal(training);
  if (!unwrappedTraining) return [];
  return unwrappedTraining.options.filter((option) => {
    if (option.deadline) {
      return option.defaultSelected && option.deadline > new Date();
    }
    if (option.endDate) {
      return option.defaultSelected && option.endDate > new Date();
    }
    return option.defaultSelected;
  });
}
