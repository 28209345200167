<div>
  <div class="flex justify-between">
    <h1 *ngIf="!toFollowUp" class="text-2xl">{{ trainingDetail?.name }} - {{ seasonYear() }}</h1>
    <h1 *ngIf="toFollowUp" class="text-2xl">{{ trainingDetail?.name }}</h1>
    <div class="flex gap-2">
      <p-button
        *ngIf="!toFollowUp && showOnCalendar()"
        size="small"
        (onClick)="getIcal.emit()"
        label="Add to calendar"
        icon="pi pi-calendar"
        [outlined]="true"
        [disabled]="nrOfOptionsSelected === 0"
      />
      <p-button
        *ngIf="currentUser?.isCatalogResponsible || currentUser?.isAdmin"
        size="small"
        (onClick)="onClickEdit()"
        label="Edit"
      />
    </div>
  </div>
  <p-divider />

  <div class="flex justify-between">
    <div class="flex flex-wrap gap-3">
      <ng-container *ngFor="let domain of trainingDetail?.knowledgeDomains ?? []">
        <p-tag [value]="domain.name"></p-tag>
      </ng-container>
    </div>
    <p-tag
      [style]="{ background: getFormatColor(trainingDetail?.format) }"
      [value]="trainingDetail?.format | fDisplay"
    />
  </div>

  <div class="grid lg:grid-cols-3">
    <section class="lg:col-span-2">
      <p-divider class="hidden lg:block" align="left">
        <b class="text-lg">Description</b>
      </p-divider>
      <markdown
        [class]="getMarkdownClass()"
        style="max-width: 100%"
        [data]="trainingDetail?.description"
      />
      <p class="text-secondary" *ngIf="!trainingDetail?.description">No description</p>
      <div *ngIf="trainingDetail?.comment && canSeeAdminInfo()" class="mt-8">
        <p><span class="font-bold">Admin info: </span>{{ trainingDetail?.comment }}</p>
      </div>
    </section>
    <section class="flex flex-col justify-between mt-4 lg:block lg:ml-8 lg:mt-0">
      <p-divider class="hidden lg:block" align="left">
        <b class="text-lg">Info</b>
      </p-divider>
      <div class="flex items-center gap-2">
        <span class="pi pi-user mr-1"></span>
        <p>{{ trainingDetail?.responsibleName }}</p>
      </div>
      <div *ngIf="trainingDetail?.address" class="flex items-center gap-2">
        <span class="pi pi-home mr-1"></span>
        <p>{{ trainingDetail!.address }}</p>
      </div>
      <div *ngIf="trainingDetail?.website" class="flex items-center gap-2">
        <span class="pi pi-globe mr-1"></span>
        <div class="hover:text-primary whitespace-nowrap text-ellipsis overflow-hidden">
          <a class="hidden lg:inline text-info hover:underline" target="_blank" [href]="trainingDetail!.website">
            {{ trainingDetail!.website }}
          </a>
          <a class="lg:hidden text-info underline" target="_blank" [href]="trainingDetail!.website"> Website </a>
        </div>
      </div>
    </section>
  </div>
</div>
