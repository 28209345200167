import { StandInPeopleDirective } from '@/shared/validators/stand-in-people.validator';
import { StartBeforeEndDirective } from '@/shared/validators/start-before-end.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileDto } from '@euricom/angular-training-catalog-api/models';

export function getStandInForm() {
  return new FormGroup({
    people: new FormGroup(
      {
        absentee: new FormControl<UserProfileDto | undefined>(undefined, {
          validators: [Validators.required],
        }),
        replacement: new FormControl<UserProfileDto | undefined>(undefined, {
          validators: [Validators.required],
        }),
      },
      { validators: [StandInPeopleDirective.absenteeReplacementNotSame()] },
    ),
    date: new FormGroup(
      {
        startDate: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        endDate: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      },
      { validators: [StartBeforeEndDirective.startBeforeEnd()] },
    ),
  });
}
