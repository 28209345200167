/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { StandInAddCommand } from '../models/stand-in-add-command';
import { StandInDto } from '../models/stand-in-dto';
import { StandInUpdateCommand } from '../models/stand-in-update-command';

@Injectable({ providedIn: 'root' })
export class StandInsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getStandIns()` */
  static readonly GetStandInsPath = '/api/StandIns';

  /**
   * Retrieves all stand-in records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandIns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandIns$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StandInDto>>> {
    const rb = new RequestBuilder(this.rootUrl, StandInsService.GetStandInsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StandInDto>>;
      })
    );
  }

  /**
   * Retrieves all stand-in records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStandIns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandIns(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<StandInDto>> {
    return this.getStandIns$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StandInDto>>): Array<StandInDto> => r.body)
    );
  }

  /** Path part for operation `addStandIn()` */
  static readonly AddStandInPath = '/api/StandIns';

  /**
   * Creates a new stand-in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addStandIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addStandIn$Response(
    params?: {
  
    /**
     * The request payload.
     */
    body?: StandInAddCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StandInDto>> {
    const rb = new RequestBuilder(this.rootUrl, StandInsService.AddStandInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandInDto>;
      })
    );
  }

  /**
   * Creates a new stand-in.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addStandIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addStandIn(
    params?: {
  
    /**
     * The request payload.
     */
    body?: StandInAddCommand
    },
    context?: HttpContext
  ): Observable<StandInDto> {
    return this.addStandIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandInDto>): StandInDto => r.body)
    );
  }

  /** Path part for operation `getStandIn()` */
  static readonly GetStandInPath = '/api/StandIns/{id}';

  /**
   * Retrieves a stand-in record by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandIn()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandIn$Response(
    params: {

    /**
     * ID of the stand-in to retrieve.
     */
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StandInDto>> {
    const rb = new RequestBuilder(this.rootUrl, StandInsService.GetStandInPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandInDto>;
      })
    );
  }

  /**
   * Retrieves a stand-in record by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStandIn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandIn(
    params: {

    /**
     * ID of the stand-in to retrieve.
     */
      id: number;
    },
    context?: HttpContext
  ): Observable<StandInDto> {
    return this.getStandIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandInDto>): StandInDto => r.body)
    );
  }

  /** Path part for operation `updateStandIn()` */
  static readonly UpdateStandInPath = '/api/StandIns/{id}';

  /**
   * Updates an existing stand-in with new details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStandIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateStandIn$Response(
    params: {

    /**
     * ID of the stand-in to update.
     */
      id: number;
  
    /**
     * The request payload.
     */
    body?: StandInUpdateCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StandInDto>> {
    const rb = new RequestBuilder(this.rootUrl, StandInsService.UpdateStandInPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandInDto>;
      })
    );
  }

  /**
   * Updates an existing stand-in with new details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStandIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateStandIn(
    params: {

    /**
     * ID of the stand-in to update.
     */
      id: number;
  
    /**
     * The request payload.
     */
    body?: StandInUpdateCommand
    },
    context?: HttpContext
  ): Observable<StandInDto> {
    return this.updateStandIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandInDto>): StandInDto => r.body)
    );
  }

  /** Path part for operation `deleteStandIn()` */
  static readonly DeleteStandInPath = '/api/StandIns/{id}';

  /**
   * Deletes a stand-in record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStandIn()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStandIn$Response(
    params: {

    /**
     * ID of the stand-in to delete.
     */
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, StandInsService.DeleteStandInPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a stand-in record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStandIn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStandIn(
    params: {

    /**
     * ID of the stand-in to delete.
     */
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteStandIn$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
