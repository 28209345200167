<div class="flex justify-between">
  <h1 class="text-2xl">Verify</h1>
  <tc-info-tooltip text="You can verify a training, this means that the booking is verified with the timesheet zone." />
</div>
<p-divider />
<p-button
  [label]="status === 'Verified' ? 'Unverify' : 'Verify'"
  type="submit"
  size="small"
  (onClick)="verify.emit()"
/>
