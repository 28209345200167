import { KeydownDirective } from '@/shared/directives/form-keydown-events.directive';
import { SeasonDetail } from '@/shared/models/season-detail';
import { TrainingDetail } from '@/shared/models/training-detail';
import { collectionKeys, CollectionsService } from '@/shared/services/collections.service';
import { domainKeys, KnowledgeDomainsService } from '@/shared/services/knowledge-domains.service';
import { NavigationTrainingAdminService } from '@/shared/services/navigation-training-admin.service';
import { SeasonsService } from '@/shared/services/season.service';
import { responsiblesKeys, UsersService } from '@/shared/services/user.service';
import { useQuery } from '@/shared/utils/query';
import { DeadlineBeforeStartValidatorDirective } from '@/shared/validators/deadline-before-start.validator';
import { StartAndEndInSeasonValidatorDirective } from '@/shared/validators/start-and-end-in-season.validator';
import { StartBeforeEndDirective } from '@/shared/validators/start-before-end.validator';
import { TrainingAddOptionSimpleComponent } from '@/trainings/components/training-add-option-simple/training-add-option-simple.component';
import {
  getNameBasedOnFormat,
  getTrainingForm,
  getTrainingOptionForm,
  getTrainingOptionFormFilledIn,
} from '@/trainings/pages/training-upsert/helpers';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { Format, TrainingUpsertDto, UpsertTrainingOptionDto } from '@euricom/angular-training-catalog-api/models';
import { formatISO } from 'date-fns';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TrainingAddOptionComponent } from '../training-add-option/training-add-option.component';

@Component({
  selector: 'tc-training-add',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    //custom
    TrainingAddOptionComponent,
    TrainingAddOptionSimpleComponent,
    //primeNG
    DividerModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    MultiSelectModule,
    InputTextareaModule,
    CalendarModule,
    CardModule,
    AutoFocusModule,
    KeydownDirective,
  ],
  providers: [SeasonsService, CollectionsService, KnowledgeDomainsService, UsersService],
  templateUrl: './training-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAddComponent implements AfterViewInit {
  @ViewChild('dropdown') dropdown?: Dropdown;

  private readyForInit = signal({
    seasons: false,
    collections: false,
    responsibles: false,
  });
  private readonly _collectionId = this._route.snapshot.queryParamMap.get('collectionId');
  private readonly _seasonId = this._route.snapshot.queryParamMap.get('season');
  private readonly _currentUser = useAuth().user;
  private readonly _training = signal<TrainingDetail | undefined>(undefined);
  readonly activeOptions = [
    {
      value: undefined,
      display: 'Draft',
    },
    {
      value: false,
      display: 'Booking Not Enabled',
    },
    {
      value: true,
      display: 'Booking Enabled',
    },
  ];

  readonly form = getTrainingForm();
  formArrayInvalid = false;
  readonly loadingForm = signal(true);

  readonly trainingCollectionOptions = useQuery(
    collectionKeys.all,
    () => {
      return this._collectionService.getTrainingCollectionsAsync();
    },
    {
      onSettled: () => {
        this.readyForInit.update((ready) => {
          return {
            collections: true,
            responsibles: ready.responsibles,
            seasons: ready.seasons,
          };
        });
      },
    },
  );

  readonly domainOptions = useQuery(domainKeys.all, () => {
    return this._domainService.getAllKnowledgeDomainsAsync();
  });
  readonly responsibleOptions = useQuery(
    responsiblesKeys.all,
    () => {
      return this._userService.getCatalogResponsiblesAsync();
    },
    {
      onSettled: () => {
        this.readyForInit.update((ready) => {
          return {
            collections: ready.collections,
            responsibles: true,
            seasons: ready.seasons,
          };
        });
      },
    },
  );

  readonly sortedCollections = computed(() => {
    return this.trainingCollectionOptions.data()?.sort((a, b) => a.title.localeCompare(b.title));
  });
  readonly sortedDomains = computed(() => {
    return this.domainOptions.data()?.sort((a, b) => a.name.localeCompare(b.name));
  });
  readonly defaultCollection = computed(() =>
    this.sortedCollections()?.find((collection) => collection.id === this._collectionId),
  );

  readonly formSaved = signal(0);
  readonly formArrayChanges = toSignal(this.form.controls.trainingOptions.valueChanges);
  readonly seasonChanges = toSignal(this.form.controls.season.valueChanges);
  readonly collectionChanges = toSignal(this.form.controls.collection.valueChanges);
  readonly formatChanges = toSignal(this.form.controls.format.valueChanges);

  seasonOptions: SeasonDetail[] = [];

  @Input() set setSeasonOptions(value: SeasonDetail[] | undefined) {
    this.seasonOptions = value ?? [];
    this.readyForInit.update((ready) => {
      return {
        collections: ready.collections,
        responsibles: ready.responsibles,
        seasons: true,
      };
    });
  }
  @Input() loadingUpsert: Signal<boolean> = signal(false);
  @Output() newTraining = new EventEmitter<TrainingUpsertDto>();

  @Input() set training(value: TrainingDetail | undefined) {
    if (!value) return;
    this._training.set(value);
  }

  get training(): TrainingDetail | undefined {
    return this._training();
  }

  constructor(
    private _collectionService: CollectionsService,
    private _route: ActivatedRoute,
    private _domainService: KnowledgeDomainsService,
    private _userService: UsersService,
    private _router: Router,
    private _navigation: NavigationTrainingAdminService,
  ) {
    // when the formArray changes, we need to calculate if the array is invalid
    effect(() => {
      this.formArrayChanges();

      this.formArrayInvalid = this.form.controls.trainingOptions.controls.reduce((acc, cur) => {
        if (cur.invalid) return true;
        return acc;
      }, false);
    });

    // when the season changes, we need to update the validators of the options
    effect(
      () => {
        this.seasonChanges();
        this.updateValidationOptions();
      },
      { allowSignalWrites: true },
    );

    // when the season changes, we need to update the dates of the first option
    effect(
      () => {
        const season = this.seasonChanges();
        const format = this.formatChanges();

        if (!season || !format || this.training) return;

        const dateControls = this.form.controls.trainingOptions.at(0).controls.date.controls;
        if (dateControls.endDate.value !== null || dateControls.startDate.value !== null) return;

        this.form.controls.trainingOptions.at(0).patchValue({
          date: {
            startDate: format === Format.SelfPaced ? season?.startDate : null,
            endDate: format === Format.SelfPaced ? season?.endDate : null,
          },
        });
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        const ready = this.readyForInit();
        if (ready.collections && ready.responsibles && ready.seasons) {
          this.initializeForm();
        }
      },
      { allowSignalWrites: true },
    );
    // when the collection changes, we need to update the format & when there is only 1 option, we need to update the name of the option & the training name
    effect(
      () => {
        this.loadingForm();
        const collection = this.collectionChanges();
        if (!collection) return;
        const newFormat = collection.format;

        this.form.controls.format.setValue(newFormat);

        if (this.training) return;

        if (this.form.controls.trainingOptions.controls.length === 1) {
          this.form.controls.trainingOptions.at(0).patchValue({
            name: getNameBasedOnFormat(newFormat),
          });
        }
        if (!this.form.value.trainingName)
          this.form.controls.trainingName.setValue(this.form.controls.collection.value?.title ?? '');
      },
      { allowSignalWrites: true },
    );
    // when the collection is set, we need to set the default collection
    effect(
      () => {
        this.form.controls.collection.setValue(this.defaultCollection() ?? null);
      },
      { allowSignalWrites: true },
    );
  }

  ngAfterViewInit() {
    if (this.dropdown) {
      this.dropdown.applyFocus();
    }
  }

  private initializeForm() {
    // when the collection is set, we need to fill in the form if we are editing a training
    const collections = this.trainingCollectionOptions.data();
    if (!collections) return;

    // Prevents double options in the form
    this.form.controls.trainingOptions.clear();

    const training = this.training;

    if (!training) {
      // if we are adding a new training, we need to add a first option
      this.form.controls.trainingOptions.push(getTrainingOptionForm());

      // if current user is responsible, set responsible to current user
      this.form.patchValue({
        responsible: this.responsibleOptions.data()?.find((user) => user.email === this._currentUser()?.email) ?? null,
        season: this.seasonOptions.find((season) => season.id === this._seasonId) ?? null,
      });
      this.loadingForm.set(false);
      return;
    }

    // if we are editing a training, we need to fill in the form
    this.form.patchValue({
      id: training.id,
      season: this.seasonOptions.find((season) => season.id === training.season.id),
      collection: this.trainingCollectionOptions
        .data()
        ?.find((collection) => collection.id === training.trainingCollectionId),
      format: training.format,
      trainingName: training.name,
      trainingIsEnabled: this.activeOptions.find((option) => option.value === training.isEnabled),
      knowledgeDomains: training.knowledgeDomains,
      responsible: this.responsibleOptions.data()?.find((user) => user.email === training.responsibleEmail),
      website: training.website,
      address: training.address,
      description: training.description,
      info: training.comment,
      autoBook: training.autoBook,
      showOnCalendar: training.showOnCalendar,
      allowMultipleBookings: training.allowMultipleBookings,
      showParticipants: training.showParticipants,
    });

    this.loadingForm.set(false);
    if (!training.options) return;

    training.options.forEach((option) => {
      this.form.controls.trainingOptions.push(getTrainingOptionFormFilledIn(option));
    });
  }

  onAddOption() {
    this.form.controls.trainingOptions.push(getTrainingOptionForm(false));
    this.updateValidationOptions();
  }
  copyOptionIsPossible() {
    const controls = this.form.controls.trainingOptions.at(0).controls;
    const optionControls = { ...controls, ...controls.date.controls, date: null, default: null };
    return Object.values(optionControls).find((control) => !!control?.value) !== undefined;
  }

  onDeleteOption(index: number) {
    this.form.controls.trainingOptions.removeAt(index);

    const format = this.formatChanges();

    if (this.form.controls.trainingOptions.controls.length === 1 && format) {
      this.form.controls.trainingOptions.at(0).patchValue({ name: getNameBasedOnFormat(format) });
    }
  }
  onCopyOption(index: number) {
    const optionToCopy = this.form.controls.trainingOptions.at(index);

    const newOption = getTrainingOptionForm(false);
    newOption.patchValue(optionToCopy.value);
    newOption.controls.id.setValue(null);
    newOption.controls.name.setValue(`${newOption.controls.name.value} - copy`);
    this.form.controls.trainingOptions.push(newOption);
    this.updateValidationOptions();
  }

  onSubmit() {
    this.formSaved.update((prev) => prev + 1);

    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });

    this.form.controls.trainingOptions.controls.forEach((control) => {
      Object.values(control.controls).forEach((innerControl) => {
        innerControl.markAsDirty();
      });
      Object.values(control.controls.date.controls).forEach((innerControlDate: FormControl) => {
        innerControlDate.markAsDirty();
      });
    });

    if (this.form.invalid || this.formArrayInvalid) {
      return;
    }

    let trainingOptions: UpsertTrainingOptionDto[] = [];

    if (
      this.form.controls.trainingOptions.getRawValue() &&
      this.form.controls.trainingOptions.getRawValue().length > 0
    ) {
      trainingOptions = this.form.controls.trainingOptions.getRawValue().map((option) => {
        const deadline = option.date.deadline ?? null;
        const startDate = option.date.startDate ?? null;
        const endDate = option.date.endDate ?? null;

        return {
          comment: option.comment ?? '',
          cost: option.cost ?? 0,
          deadline: deadline ? formatISO(deadline) : null,
          default: option.default ?? false,
          description: option.description ?? '',
          endDate: endDate ? formatISO(endDate) : null,
          halfDays: option.halfDays,
          name: option.name ?? '',
          startDate: startDate ? formatISO(startDate) : null,
          trainingOptionId: option.id ?? '',
        };
      });
    }

    const newTrainingDto: TrainingUpsertDto = {
      trainingId: this.form.value.id ?? '',
      seasonId: this.form.value.season?.id ?? '',
      trainingCollectionId: this.form.value.collection?.id ?? '',
      trainingName: this.form.value.trainingName ?? '',
      isActive: this.form.value.trainingIsEnabled?.value,
      knowledgeDomainIds: this.form.value.knowledgeDomains?.map((domain) => domain.id) ?? [],
      responsibleEmail: this.form.value.responsible?.email ?? '',
      link: this.form.controls.website.value,
      address: this.form.controls.address.value,
      description: this.form.controls.description.value,
      adminInfo: this.form.controls.info.value,
      trainingOptions: trainingOptions,
      autoBook: this.form.value.autoBook ?? false,
      showOnCalendar: this.form.value.showOnCalendar ?? false,
      allowMultipleBookings: this.form.value.allowMultipleBookings ?? false,
      showParticipants: this.form.value.showParticipants ?? true,
    };

    this.newTraining.emit(newTrainingDto);
  }

  onCancel() {
    this._navigation.back();
  }

  onCreateNewCollection() {
    this._router.navigate(['/admin/collections/add'], {
      queryParams: { returnUrl: this._router.url.split('?')[0] },
    });
  }

  checkIfOptionHasBookings(index: number) {
    if (!this.training?.options[index]) return false;

    const hasBookings = this.training?.options[index].hasBookings;
    return !!hasBookings;
  }

  // when there is only 1 option and format is not self-paced, we need to copy start date to end date
  setOptionEndDate(params: { startDate: Date; id: number }) {
    if (
      this.form.controls.format.value === Format.SelfPaced ||
      this.form.controls.trainingOptions.at(params.id).controls.date.controls.endDate.value !== null
    )
      return;

    //check if startDate is a date, onInput returns any
    try {
      params.startDate.getTime();
    } catch (e) {
      return;
    }

    this.form.controls.trainingOptions.at(params.id).patchValue({
      date: {
        endDate: params.startDate,
      },
    });
  }
  onSetSeasonDates() {
    this.form.controls.trainingOptions.controls[0].controls.date.controls.startDate.setValue(
      this.form.controls.season.value?.startDate ?? null,
    );
    this.form.controls.trainingOptions.controls[0].controls.date.controls.endDate.setValue(
      this.form.controls.season.value?.endDate ?? null,
    );
  }

  getGridColumnClass() {
    const lengthOptions = this.form.controls.trainingOptions.controls.length;
    if (!this.training) return `grid grid-cols-2 ${lengthOptions > 2 && 'ml:grid-cols-3'}`;
    return `grid grid-cols-1 ml:grid-cols-2 ${lengthOptions > 2 && '3xl:grid-cols-3'}`;
  }

  updateValidationOptions() {
    const season = this.seasonChanges();
    if (!season) return;

    this.form.controls.trainingOptions.controls.forEach((option) => {
      option.controls.date.setValidators([
        StartBeforeEndDirective.startBeforeEnd(),
        DeadlineBeforeStartValidatorDirective.deadlineBeforeStart(),
        StartAndEndInSeasonValidatorDirective.startAndEndInSeason(season),
      ]);
      option.controls.date.updateValueAndValidity({ emitEvent: true });
    });
  }
}
