import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, ModuleWithProviders, NgModule, importProvidersFrom } from '@angular/core';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { provideCustomConfigurations } from '../configuration/configuration.module';
import {
  APP_ADMIN_ROLE,
  APP_CATALOG_RESPONSIBLE_ROLE,
  APP_HUMAN_RESOURCE_ROLE,
  APP_PRACTICE_MANAGER_ROLE,
  AuthenticationService,
} from './authentication.service';
import { AuthenticationSettings } from './authentication.settings';
import { MSALInstanceConfigFactory, MSALInterceptorConfigFactory } from './msal-configuration';

@NgModule({
  imports: [MsalModule],
  exports: [MsalModule],
  providers: [
    provideCustomConfigurations([
      {
        provide: AuthenticationSettings,
        key: AuthenticationSettings.key,
      },
    ]),

    MsalService,
    MsalBroadcastService,
    AuthenticationService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceConfigFactory,
      deps: [AuthenticationSettings],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AuthenticationSettings],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
})
export class AuthenticationModule {
  public static withRoles(options?: {
    adminRole?: string;
    humanResourcesRole?: string;
    catalogResponsibleRole?: string;
    practiceManagerRole?: string;
  }): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        { provide: APP_ADMIN_ROLE, useValue: options?.adminRole ?? null },
        { provide: APP_HUMAN_RESOURCE_ROLE, useValue: options?.humanResourcesRole ?? null },
        { provide: APP_CATALOG_RESPONSIBLE_ROLE, useValue: options?.catalogResponsibleRole ?? null },
        { provide: APP_PRACTICE_MANAGER_ROLE, useValue: options?.practiceManagerRole ?? null },
      ],
    };
  }
}

export function provideAuthentication(options?: {
  adminRole?: string;
  humanResourcesRole?: string;
  catalogResponsibleRole?: string;
  practiceManagerRole?: string;
}): EnvironmentProviders {
  return importProvidersFrom(AuthenticationModule.withRoles(options));
}
