import { useQueryClient } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthenticationService, User } from '@euricom/angular-shared';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tc-user-panel',
  standalone: true,
  imports: [CommonModule, AvatarModule, DividerModule, ButtonModule],
  templateUrl: './user-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPanelComponent {
  private readonly _queryClient = useQueryClient();
  readonly version = environment.appVersion;

  @Input() user?: User;
  @Input() userImage?: string;

  constructor(private readonly _authenticationService: AuthenticationService) {}

  onLogout() {
    this._authenticationService.logout();
    this._queryClient.clear();
  }
}
