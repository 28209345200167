import { MsalInterceptorAuthRequest, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AuthenticationSettings } from '@euricom/angular-shared';

export function MSALInstanceConfigFactory(settings: AuthenticationSettings): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: settings.clientId,
      authority: settings.authority,
      redirectUri: settings.redirectUri,
      postLogoutRedirectUri: settings.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  });
}

export function MSALInterceptorConfigFactory(settings: AuthenticationSettings): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: AuthenticationSettings.protectedResourceMap(settings),
    authRequest: { scopes: AuthenticationSettings.scopes(settings) } as MsalInterceptorAuthRequest,
  };
}
