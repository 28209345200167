import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { PageTitleService } from '@/shared/services/page-title.service';
import { standInKeys, StandInService } from '@/shared/services/stand-in.service';
import { ToastService } from '@/shared/services/toast.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { StandInDeleteComponent } from '@/stand-ins/components/stand-in-delete/stand-in-delete.component';
import { StandInEditComponent } from '@/stand-ins/components/stand-in-edit/stand-in-edit.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StandInAddCommand, StandInUpdateCommand } from '@euricom/angular-training-catalog-api/models';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'tc-stand-in-upsert',
  standalone: true,
  imports: [CommonModule, LoaderComponent, CardModule, StandInDeleteComponent, StandInEditComponent],
  providers: [StandInService],
  templateUrl: './stand-in-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandInUpsertComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  readonly standInId: number | null;
  readonly standIn = useQuery(
    computed(() => standInKeys.byId(this.standInId!)),
    () => this._standInsService.getStandInByIdAsync(this.standInId!),
    {
      enabled: computed(() => this.standInId != null),
    },
  );

  readonly upsertStandInMutation = useMutation(this._standInsService.upsertStandInAsync, {
    onSuccess: () => {
      const message = 'The stand-in was successfully ' + (this.standIn == null ? 'added' : 'updated');
      this._toaster.success(message);
      this._queryClient.invalidateQueries(standInKeys.all);
      return this._router.navigate(['/follow-up/stand-ins']);
    },
  });
  readonly deleteStandInMutation = useMutation(this._standInsService.deleteStandInAsync, {
    onSuccess: () => {
      this._toaster.success('The stand-in was successfully deleted!');
      this._queryClient.invalidateQueries(standInKeys.all);
      return this._router.navigate(['/follow-up/stand-ins']);
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _standInsService: StandInService,
    private _toaster: ToastService,
    private _router: Router,
  ) {
    const param = this._route.snapshot.paramMap.get('id');
    const number = param != null ? Number(param) : null;
    this.standInId = number != null && !isNaN(number) ? number : null;
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Stand-in');
  }

  onUpsertStandIn(payload: StandInAddCommand | StandInUpdateCommand) {
    this.upsertStandInMutation.mutate(payload);
  }

  onDeleteStandIn() {
    if (this.standInId == null) {
      this._toaster.error('No stand-in selected');
      return;
    }

    this.deleteStandInMutation.mutate(this.standInId);
  }
}
