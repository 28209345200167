import { getFormatColorByString } from '@/shared/models/format';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BadgeModule } from 'primeng/badge';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { delay, map } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { useCurrentBreakpoint } from '../../utils/breakpoints';
import { Facet } from './facet';

@Component({
  selector: 'tc-filter-facet',
  standalone: true,
  imports: [
    CommonModule,
    PanelModule,
    RadioButtonModule,
    ReactiveFormsModule,
    CheckboxModule,
    DropdownModule,
    BadgeModule,
  ],
  templateUrl: './filter-facet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetComponent<T extends string | number> implements OnInit {
  readonly selectionControl = new FormControl<T[]>([], { nonNullable: true });

  private _selected: T[] | null | undefined = null;
  @Input() storageId: string | null = null;

  @Input() options: Facet<T>[];

  @Input() header: string;

  @Input() type: 'single' | 'multiple';

  @Input() default: T[] | null = null;

  @Input() showFormatLegenda = false;

  @Input()
  set selected(value: T[] | T | null | undefined) {
    if (typeof value === 'string' || typeof value === 'number') {
      this._selected = [value];
    } else {
      this._selected = value;
    }
    this.selectionControl.setValue(this._selected ?? [], { emitEvent: false });
  }

  @Output()
  selectedChange = this.selectionControl.valueChanges.pipe(
    delay(0),
    map((value) => {
      return Array.isArray(value) ? value : [value];
    }),
  );

  readonly breakpoint = useCurrentBreakpoint();

  readonly isSmallScreen = computed(() => {
    const breakpoint = this.breakpoint();
    if (!breakpoint) return false;

    return ['unknown', 'sm'].includes(breakpoint);
  });

  constructor(private _localStorageService: LocalStorageService) {
    this.options = [];
    this.header = 'Filter';
    this.type = 'multiple';
    this.selectedChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (this.storageId) {
        _localStorageService.saveData<T[]>(this.storageId, value);
      }
    });
  }

  ngOnInit() {
    if (this.default != null && !this._selected) {
      this.selectionControl.setValue(this.default);
    }
    if (this.storageId) {
      const storedValue = this._localStorageService.getData<T[]>(this.storageId);
      if (storedValue != null) {
        this.selectionControl.setValue(storedValue);
      }
    }
  }

  getFormatColor(format: string) {
    const color = getFormatColorByString(format);
    return { 'background-color': color };
  }
}
