<div class="h-full flex flex-col justify-center items-center">
  <h2 class="font-bold text-2xl">Looks like you've lost connection to the internet!</h2>
  <p-button
    (onClick)="_router.navigate(['/'])"
    icon="pi pi-refresh"
    styleClass="p-button-secondary"
    class="mt-8"
    label="Try again"
  />
</div>
