<form [formGroup]="form" [tcFormKeydownEvent]="{ onSubmit: onSubmit, onCancel: onCancel, context: this }">
  <div class="mt-2 w-full">
    <label for="title">Title <span class="text-error">*</span></label>
    <input
      id="title"
      name="title"
      class="p-inputtext-sm w-full"
      pInputText
      [formControl]="form.controls.title"
      pAutoFocus
      [autofocus]="true"
    />
    <p
      *ngIf="
        form.controls.title.dirty &&
        (form.controls.title.hasError('required') || form.controls.title.hasError('whitespace'))
      "
      class="text-error"
    >
      Title is required
    </p>
    <p *ngIf="form.controls.title.dirty && form.controls.title.hasError('maxlength')" class="text-error">
      Title is too long.
    </p>
  </div>

  <div class="mt-2 flex flex-col w-1/2">
    <label for="format">Format <span class="text-error">*</span></label>
    <p-dropdown
      inputId="format"
      [options]="formats"
      optionLabel="displayValue"
      optionValue="key"
      [formControl]="form.controls.format"
      placeholder="Select a format"
    />
    <p *ngIf="form.controls.format.dirty && form.controls.format.hasError('required')" class="text-error">
      Format is required
    </p>
  </div>

  <div class="flex flex-row gap-2 mt-4">
    <p-button label="Save" size="small" (onClick)="onSubmit()" [loading]="loadingUpsert" />
    <p-button label="Cancel" size="small" [outlined]="true" (onClick)="onCancel()" />
  </div>
</form>
