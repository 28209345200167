import { getReviewForm } from '@/reviews/pages/review-update/helpers';
import { Review } from '@/shared/models/review';
import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewUpdateDto } from '@euricom/angular-training-catalog-api/models';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';

@Component({
  selector: 'tc-review-update-form',
  standalone: true,
  imports: [
    CommonModule,
    AutoFocusModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    InputTextareaModule,
    RatingModule,
  ],
  templateUrl: './review-update-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewUpdateFormComponent {
  @Input() loadingUpdate = false;
  private readonly _review = signal<Review | undefined>(undefined);
  readonly form = getReviewForm();

  @Input() set review(review: Review | undefined) {
    this._review.set(review);
  }
  get collection() {
    return this._review();
  }

  @Output() newReview = new EventEmitter<ReviewUpdateDto>();
  constructor(private location: Location) {
    // set form values when review is set
    effect(() => {
      const review = this._review();
      if (!review) return;

      this.form.setValue({
        message: review.message,
        rating: review.rating,
      });
    });
  }

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const formValue = this.form.value;

    const newReview: ReviewUpdateDto = {
      id: '',
      message: formValue.message ?? '',
      rating: formValue.rating ?? 0,
    };

    this.newReview.emit(newReview);
  }

  onCancel() {
    this.location.back();
  }
}
