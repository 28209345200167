import { mapParticipant } from '@/shared/services/participant.service';
import { mapReview } from '@/shared/services/review.service';
import { HttpClient } from '@angular/common/http';
import { computed, Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import {
  BookingForTrainingFollowUpDto,
  BulkActionDto,
  Format,
  TrainingCopyBulkDto,
  TrainingDetailDto,
  TrainingDetailFollowUpDto,
  TrainingDto,
  TrainingFollowUpDto,
  TrainingOptionDto,
  TrainingUpsertDto,
} from '@euricom/angular-training-catalog-api/models';
import { TrainingsService as RootTrainingsService } from '@euricom/angular-training-catalog-api/services';
import { formatISO } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { BookingForTrainingFollowUp } from '../models/booking-for-training-follow-up';
import { Training } from '../models/training';
import { TrainingDetail } from '../models/training-detail';
import { TrainingDetailFollowUp } from '../models/training-detail-follow-up';
import { TrainingFollowUp } from '../models/training-follow-up';
import { TrainingOption } from '../models/training-option';
import { formatDisplay } from '../pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '../utils/signals';
import { mapComment } from './booking.service';
import { mapKnowledgeDomain } from './knowledge-domains.service';
import { mapSeason } from './season.service';

export type UpdateTrainingIsEnabledVariables = { isEnabled: boolean; trainingId: string };
export type UpdateTrainingBulkIsEnabledVariables = { isEnabled: boolean; trainingIds: string[] };
export type getTrainingsVariables = {
  startDate?: Date;
  endDate?: Date;
  afterDate?: Date;
  seasons?: string[];
  collectionId?: string;
};

export function mapTraining(dto: TrainingDto): Training {
  return {
    autoBook: dto.autoBook,
    autoApprove: dto.autoApprove,
    showOnCalendar: dto.showOnCalendar,
    allowMultipleBookings: dto.allowMultipleBookings,
    showParticipants: dto.showParticipants,
    credits: dto.credits,
    halfDays: dto.halfDays,
    id: dto.id,
    isEnabled: dto.isEnabled ?? undefined,
    name: dto.name,
    responsibleEmail: dto.responsibleEmail,
    responsibleName: dto.responsibleName,
    address: dto.address ?? undefined,
    startDate: dto.startDate ? new Date(dto.startDate) : undefined,
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    deadline: dto.deadline ? new Date(dto.deadline) : undefined,
    domains: dto.knowledgeDomains
      .map((domain) => mapKnowledgeDomain(domain))
      .sort((a, b) => a.name.localeCompare(b.name)),
    format: dto.format as Format,
    season: mapSeason(dto.season),
    collectionId: dto.trainingCollectionId,
  };
}

export function mapTrainingFollowUp(dto: TrainingFollowUpDto): TrainingFollowUp {
  return {
    id: dto.id,
    name: dto.name,
    responsibleName: dto.responsibleName ?? undefined,
    startDate: dto.startDate ? new Date(dto.startDate) : undefined,
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    format: dto.format as Format,
    season: mapSeason(dto.season),
    nrOfBookings: dto.nrOfBookings,
  };
}

export function mapTrainingDetail(dto: TrainingDetailDto): TrainingDetail {
  return {
    ...dto,
    startDate: dto.startDate ? new Date(dto.startDate) : undefined,
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    deadline: dto.deadline ? new Date(dto.deadline) : undefined,
    responsibleName: dto.responsibleName ?? undefined,
    responsibleEmail: dto.responsibleEmail ?? undefined,
    knowledgeDomains: dto.knowledgeDomains
      .map((domain) => mapKnowledgeDomain(domain))
      .sort((a, b) => a.name.localeCompare(b.name)),
    format: dto.format as Format,
    address: dto.address ?? undefined,
    createdOn: dto.createdOn ? new Date(dto.createdOn) : undefined,
    description: dto.description ?? undefined,
    lastModifiedOn: dto.lastModifiedOn ? new Date(dto.lastModifiedOn) : undefined,
    options: dto.options.map((option) => mapTrainingOption(option)),
    website: dto.website ?? undefined,
    isEnabled: dto.isEnabled ?? undefined,
    season: mapSeason(dto.season),
    participants: dto.participants?.map((participant) => mapParticipant(participant)) ?? [],
    isAlreadyBookedBy: Array.from(new Set(dto.isAlreadyBookedBy)) ?? [],
    reviews: dto.reviews?.map((review) => mapReview(review)) ?? [],
  };
}

export function mapTrainingDetailFollowUp(dto: TrainingDetailFollowUpDto): TrainingDetailFollowUp {
  return {
    ...dto,
    responsibleName: dto.responsibleName ?? undefined,
    responsibleEmail: dto.responsibleEmail ?? undefined,
    knowledgeDomains: dto.knowledgeDomains
      .map((domain) => mapKnowledgeDomain(domain))
      .sort((a, b) => a.name.localeCompare(b.name)),
    format: dto.format as Format,
    address: dto.address ?? undefined,
    description: dto.description ?? undefined,
    options: dto.options.map((option) => mapTrainingOption(option)),
    website: dto.website ?? undefined,
    bookings: dto.bookings.map((b) => mapBookingForTrainingFollowUp(b)),
    comment: dto.comment ?? undefined,
  };
}

export function mapBookingForTrainingFollowUp(dto: BookingForTrainingFollowUpDto): BookingForTrainingFollowUp {
  return {
    id: dto.id,
    requesterEmail: dto.requesterEmail ?? undefined,
    requesterName: dto.requesterName ?? undefined,
    selectedOptions: dto.selectedOptions,
    status: dto.status,
    comments: dto.comments.map((comment) => mapComment(comment)),
  };
}

export const mapTrainingOption = (dto: TrainingOptionDto): TrainingOption => {
  return {
    ...dto,
    startDate: dto.startDate ? new Date(dto.startDate) : undefined,
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    deadline: dto.deadline ? new Date(dto.deadline) : undefined,
  };
};

export const getDomains = (trainings: MaybeSignal<Training[] | undefined>) => {
  const trainingData = unsignal(trainings) ?? [];
  return trainingData
    .reduce((acc, item) => {
      item.domains.forEach((domain) => {
        if (!acc.some((accItem) => accItem.id === domain.id)) {
          acc.push(domain);
        }
      });
      return acc;
    }, [] as { id: string; name: string }[])
    .map((item) => {
      return {
        key: item.id,
        displayValue: item.name,
      };
    })
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
};

export const getFormats = (trainings: MaybeSignal<Training[] | TrainingFollowUp[] | undefined>) => {
  const trainingData = unsignal(trainings) ?? [];
  return trainingData
    .reduce((acc, item) => {
      if (!acc.some((accItem) => accItem.id === item.format)) {
        acc.push({ id: item.format, name: formatDisplay(item.format) });
      }
      return acc;
    }, [] as { id: string; name: string }[])
    .map((item) => {
      return {
        key: item.id,
        displayValue: item.name,
      };
    })
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
};

export const trainingKeys = {
  // all trainings
  all: ['trainings'] as const,

  //specific training
  byId: (id: string | null) => [...trainingKeys.all, id] as const,

  // all the lists
  lists: () => [...trainingKeys.all, 'list'] as const,

  // training list by season
  listBySeasons: (seasons: MaybeSignal<string[]>) =>
    computed(() => [...trainingKeys.lists(), unsignal(seasons)] as const),

  // all available trainings
  available: ['trainings', 'available'] as const,

  // all available trainings
  availableCalendar: (seasons: MaybeSignal<string>) =>
    computed(() => ['trainings', 'availableCalendar', unsignal(seasons)] as const),

  //trainings to follow-up
  followUp: ['trainings', 'followUp'] as const,

  //training to follow-up by id
  followUpById: (id: string | null) => ['trainings', 'followUp', id] as const,

  //Trainings in a collection
  byCollectionId: (id: string | null) => [...trainingKeys.all, 'collection', id] as const,
};

@Injectable()
export class TrainingsService extends RootTrainingsService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  async getTrainingsAsync({
    startDate,
    endDate,
    afterDate,
    seasons,
    collectionId,
  }: getTrainingsVariables): Promise<Training[]> {
    const data = await firstValueFrom(
      this.getTrainings({
        startDate: startDate ? formatISO(startDate) : undefined,
        endDate: endDate ? formatISO(endDate) : undefined,
        afterDate: afterDate ? formatISO(afterDate) : undefined,
        seasons: seasons,
        collectionId: collectionId,
      }),
    );
    return data.map(mapTraining);
  }

  async getTrainingsFollowUpAsync(): Promise<TrainingFollowUp[]> {
    const data = await firstValueFrom(this.getTrainingsFollowUp());
    return data.map(mapTrainingFollowUp);
  }

  async getTrainingAsync(trainingId: string | undefined): Promise<TrainingDetail> {
    const data = await firstValueFrom(
      this.getTraining({
        id: trainingId ?? '',
      }),
    );

    return mapTrainingDetail(data);
  }

  async getTrainingFollowUpAsync(trainingId: string | undefined): Promise<TrainingDetailFollowUp> {
    const data = await firstValueFrom(
      this.getTrainingFollowUp({
        id: trainingId ?? '',
      }),
    );

    return mapTrainingDetailFollowUp(data);
  }

  updateTrainingIsEnabledAsync = ({ isEnabled, trainingId }: UpdateTrainingIsEnabledVariables): Promise<void> => {
    return firstValueFrom(
      this.updateTrainingIsEnabled({
        body: {
          id: trainingId,
          isEnabled: isEnabled,
        },
      }),
    );
  };

  deleteTrainingsAsync = (trainingIds: string[]): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.deleteTrainingBulk({ body: trainingIds }));
  };

  deleteTrainingAsync = async (body: { trainingId: string; withForce: boolean }): Promise<string> => {
    await firstValueFrom(this.deleteTraining({ id: body.trainingId, force: body.withForce }));
    return body.trainingId;
  };

  upsertTrainingAsync = (variables: TrainingUpsertDto): Promise<TrainingDetailDto> => {
    return firstValueFrom(this.upsertTraining({ body: variables }));
  };

  copyTrainingToSeasonAsync = (body: { trainingId: string; seasonId: string }): Promise<TrainingDto> => {
    return firstValueFrom(this.copyTrainingToSeason({ trainingId: body.trainingId, seasonId: body.seasonId }));
  };

  copyTrainingToSeasonBulkAsync = (
    trainingCopyBulkDto: TrainingCopyBulkDto,
  ): Promise<{ changedIds: string[]; unchangedIds: string[] }> => {
    return firstValueFrom(this.copyTrainingToSeasonBulk({ body: trainingCopyBulkDto }));
  };

  updateTrainingIsEnabledBulkAsync = ({
    isEnabled,
    trainingIds,
  }: UpdateTrainingBulkIsEnabledVariables): Promise<BulkActionDto> => {
    return firstValueFrom(
      this.updateTrainingIsEnabledBulk({ body: { isEnabled: isEnabled, trainingIds: trainingIds } }),
    );
  };

  getIcalOfTrainingAsync = async (params: { id: string; options: string[] }): Promise<Blob> => {
    return firstValueFrom(this.getIcalOfTraining({ id: params.id, selectedOptions: params.options }));
  };
}
