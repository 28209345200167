<div class="flex justify-between">
  <h1 class="text-2xl">Add</h1>
  <tc-info-tooltip
    text="You can add a training to your trainings so you can define a yearly training plan. Adding a training is no official request and you can always delete it."
  />
</div>
<p-divider />
<p *ngIf="passedDeadline">This training is over, you can't book anymore.</p>
<p *ngIf="alreadyBooked">You already added this training to your plan.</p>
<div class="mt-5 flex items-center">
  <p-button
    [loading]="loading()"
    [disabled]="passedDeadline || alreadyBooked"
    label="Add"
    size="small"
    (onClick)="onCreateBooking()"
  />

  <div *ngIf="users && users.length > 0 && currentUser" class="ml-4 w-3/4">
    <p-dropdown
      [options]="users"
      inputId="user"
      optionLabel="displayValue"
      filterBy="displayValue"
      [filter]="true"
      [showClear]="true"
      [formControl]="addForUser"
      [placeholder]="currentUser.name!"
    />
  </div>
</div>
