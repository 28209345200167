<p-table
  [value]="bookings"
  styleClass="p-datatable-sm"
  [(selection)]="selectedBookings"
  [rowHover]="true"
  [scrollable]="true"
  rowGroupMode="subheader"
  scrollHeight="75vh"
  [groupRowsBy]="groupedBy === 'month' ? getMonthFromStartDate : groupedBy"
  class="h-full"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>
        <div class="text-center">
          <p-tableHeaderCheckbox (click)="onSelect()" name="selectAll" />
        </div>
      </th>
      <th style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Start date</div>
        </div>
      </th>
      <th style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Status</div>
        </div>
      </th>
      <th style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Format</div>
        </div>
      </th>
      <th style="width: 20vw; max-width: 20vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Name</div>
        </div>
      </th>
      <th style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Requester</div>
        </div>
      </th>

      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        style="width: 8vw; max-width: 8vw"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Practice manager</div>
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        style="width: 15vw; max-width: 15vw"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Responsible</div>
        </div>
      </th>
      <th style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Deadline</div>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-booking>
    <tr pRowGroupHeader style="top: 39px">
      <td colspan="9">
        <div *ngIf="groupedBy === 'requesterName'" class="w-full flex gap-3 items-center py-2 font-bold">
          <p class="min-w-1/5">{{ booking.requesterName }}:</p>
          <i class="pi pi-credit-card flex self-center ml-6"></i>
          <p class="cursor-default">
            <span pTooltip="Status booked & cancelled without refund" tooltipPosition="top">
              Used credits: {{ calculateUsedCredits(booking.requesterEmail) }}
            </span>
            -
            <span pTooltip="Status approved" tooltipPosition="top">
              Approved:
              {{ calculateApprovedCredits(booking.requesterEmail) }}
            </span>
            -
            <span pTooltip="Status added" tooltipPosition="top">
              Not requested:
              {{ calculateNotRequestedCredits(booking.requesterEmail) }}
            </span>
          </p>
        </div>
        <div *ngIf="groupedBy === 'training.name'" class="w-full flex gap-3 items-center py-2 font-bold">
          <p class="min-w-1/5">{{ booking.training.name }}</p>
        </div>
        <div *ngIf="groupedBy === 'month'" class="w-full flex gap-3 items-center py-2 font-bold">
          <p class="min-w-1/5">{{ booking.training.startDate?.toLocaleString('default', { month: 'long' }) }}</p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-booking>
    <tr (click)="onClickBooking(booking.id)" class="cursor-pointer">
      <td (click)="stopPropagation($event)">
        <div class="text-center">
          <p-tableCheckbox (click)="onSelect()" [value]="booking" name="select" />
        </div>
      </td>
      <td>{{ booking.training.startDate | fDate }}</td>
      <td [class]="booking.status === 'Verified' ? 'text-accent' : ''">{{ booking.status | fStatus }}</td>
      <td>
        <p-tag [style]="{ background: getFormatColor(booking.training.format), height: '10%' }"
          ><p class="w-max">{{ booking.training.format | fDisplay }}</p>
        </p-tag>
      </td>
      <td>{{ booking.training.name }}</td>
      <td>{{ booking.requesterName }}</td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ booking.practiceManagerName }}
      </td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ booking.training.responsibleName }}
      </td>
      <td>{{ booking.deadline ? (booking.deadline | fDate) : '/' }}</td>
    </tr>
  </ng-template>
</p-table>
<p class="text-center font-semibold mt-3" *ngIf="bookings.length === 0">No trainings found</p>
