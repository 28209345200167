<div class="flex justify-between">
  <h1 class="text-2xl">Force add</h1>
  <tc-info-tooltip text="Add a booking to someones plan even if the training isn't enabled or is already passed."/>
</div>
<p-divider/>
<div class="mt-5 flex items-center">
  <p-button
    class="w-1/4"
    [loading]="loading()"
    label="Add"
    size="small"
    [outlined]="true"
    (onClick)="onCreateBooking()"
  />

  <div *ngIf="users && users.length > 0" class="ml-4 w-3/4">
    <p-dropdown
      [options]="users"
      optionLabel="displayValue"
      [filter]="true"
      filterBy="displayValue"
      [showClear]="true"
      [formControl]="addForUser"
      placeholder="Select a user"
    />
  </div>
</div>
