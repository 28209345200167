import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class SearchParamsService {
  constructor(private _route: ActivatedRoute, private _router: Router, private _myLocation: Location) {}

  /**
   * Get the search params from the current url.
   * And return it as a typed object
   * @param url The url to get the search params from. If not specified, the current url is used.
   */
  getSearchParams<TResult>(url?: string) {
    const parsedUrl = new URL(url ? url : window.location.href);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const params: Record<string, string | string[]> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    for (const [key, value] of searchParams as any) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          (params[key] as string[]).push(value);
        } else {
          params[key] = [params[key], value];
        }
      } else {
        params[key] = value;
      }
    }
    return params as TResult;
  }

  /**
   * Set the search params in the url, without reloading the page.
   * @param queryParams The search params to set.
   */
  setQueryParams(queryParams: Record<string, unknown | unknown[] | undefined | null>) {
    const urlTree = this._router.createUrlTree([], {
      relativeTo: this._route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    this._myLocation.go(urlTree.toString());
  }
}
