import { Booking } from '@/shared/models/booking';
import { getFormatColor } from '@/shared/models/format';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Format } from '@euricom/angular-training-catalog-api/models';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tc-booking-hr-table-grouped',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TableModule,
    CheckboxModule,
    FormsModule,
    TooltipModule,
    FormatDatePipe,
    FormatDisplayPipe,
    StatusDisplayPipe,
  ],
  templateUrl: './booking-hr-table-grouped.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHrTableGroupedComponent {
  @Input() bookings: Booking[] = [];
  @Input() selectedBookings: Booking[] = [];
  @Input() allBookings: Booking[] = [];
  @Input() seasons: number[] = [];
  @Input() groupedBy?: string;

  @Output() selectBookings = new EventEmitter<Booking[]>();

  readonly breakpoint = useCurrentBreakpoint();
  readonly hideColumnsBreakpoints = ['sm', 'md', 'lg', 'unknown'];

  constructor(private router: Router) {}

  onClickBooking(id: number) {
    this.router.navigate(['/bookings', id]);
  }

  onSelect() {
    this.selectBookings.emit(this.selectedBookings);
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  calculateUsedCredits(requester: string) {
    if (!this.allBookings) return 0;
    const bookedBookingsOrCancelledBookingWithRefund = this.allBookings.filter(
      (b) =>
        this.seasons.includes(b.training.season.year) &&
        b.requesterEmail === requester &&
        (b.status === 'Booked' || b.status === 'Verified' || (b.status === 'Cancelled' && !b.refundCreditsOnCancel)),
    );

    return bookedBookingsOrCancelledBookingWithRefund.reduce((acc, curr) => {
      return (acc += curr.credits);
    }, 0);
  }

  calculateNotRequestedCredits(requester: string) {
    if (!this.allBookings) return 0;
    const AddedBookings = this.allBookings.filter(
      (b) => this.seasons.includes(b.training.season.year) && b.requesterEmail === requester && b.status === 'Added',
    );

    return AddedBookings.reduce((acc, curr) => {
      return (acc += curr.credits);
    }, 0);
  }

  calculateApprovedCredits(requester: string) {
    if (!this.allBookings) return 0;
    const ApprovedBookings = this.allBookings.filter(
      (b) => this.seasons.includes(b.training.season.year) && b.requesterEmail === requester && b.status === 'Approved',
    );
    return ApprovedBookings.reduce((acc, curr) => {
      return (acc += curr.credits);
    }, 0);
  }

  getFormatColor(format: Format) {
    return getFormatColor(format);
  }

  getMonthFromStartDate(booking: Booking): number {
    return booking.training.startDate?.getMonth() ?? 0;
  }
}
