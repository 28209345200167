import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';

export type ReviewResult = {
  rating: number;
  message: string;
};

@Component({
  selector: 'tc-booking-review-panel',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    //primeNG
    DividerModule,
    InputTextareaModule,
    RatingModule,
    ButtonModule,
    InfoTooltipComponent,
  ],
  templateUrl: './booking-review-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingReviewPanelComponent {
  form = new FormGroup({
    rating: new FormControl<number>(1, { nonNullable: true, validators: [Validators.required] }),
    message: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace()],
    }),
  });

  @Output() submitReview = new EventEmitter<ReviewResult>();

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    this.submitReview.emit({ rating: this.form.controls.rating.value, message: this.form.controls.message.value });
  }
}
