<div *ngIf="booking && training">
  <h1 class="text-2xl">Info - {{ booking.status | fStatus }}</h1>
  <ng-container *ngIf="userProfile">
    <p-divider />
    <section class="flex items-center">
      <p-avatar
        *ngIf="userProfile && !userImageError()"
        [image]="userProfile.imageUrl!"
        (onImageError)="onImageError()"
        styleClass="mr-2"
        size="xlarge"
      />
      <p-avatar
        *ngIf="!userProfile || userImageError()"
        [label]="booking.requesterName.charAt(0).toUpperCase()"
        styleClass="mr-2"
        size="xlarge"
      />
      <div class="text-sm">
        <p class="font-bold">{{ userProfile.name }}</p>
        <p>Used credits: {{ userProfile.creditsUsed }}</p>
        <a
          class="text-info hover:underline"
          href="https://performance-cycle.euri.com/objective?search={{ userProfile.name }}"
          target="_blank"
        >
          Performance Cycle
        </a>
      </div>
    </section>
  </ng-container>
  <p-divider />
  <section class="mb-2" *ngIf="booking.motivation">
    <p class="font-semibold">Motivation:</p>
    <p class="ml-4">{{ booking.motivation }}</p>
  </section>
  <section class="mb-2" *ngIf="booking.comments">
    <p class="font-semibold">Comment:</p>
    <p class="ml-4">{{ booking.comments[booking.comments.length - 1].message }}</p>
  </section>
  <section class="mb-2" *ngIf="booking.status === 'Cancelled'">
    <p class="font-semibold">Refunded:</p>
    <p class="ml-4">{{ booking.refundCreditsOnCancel ? 'Yes' : 'No' }}</p>
  </section>

  <section *ngIf="(user()!.isAdmin || user()!.isPracticeManager) && userProfile && !ownBooking">
    <p class="font-semibold">Previous booked/requested trainings:</p>
    <p class="ml-4" *ngIf="userProfile.plan.length === 0">No previous bookings for this season</p>
    <div class="ml-4 mt-2">
      <p-table *ngIf="userProfile.plan.length > 0" [value]="userProfile.plan" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th>Date</th>
            <th>Title</th>
            <th>Credits</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-booking>
          <tr>
            <td>{{ booking.date | fDate : 'short' }}</td>
            <td>{{ booking.title }}</td>
            <td>{{ booking.credits }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </section>

  <section class="mt-2 flex gap-1">
    <p-button
      *ngIf="
        (ownBooking && booking.status === 'Added' && training!.options.length > 1) ||
        (booking.status === 'Added' && (user()!.isPracticeManager || user()!.isAdmin) && training!.options.length > 1)
      "
      (click)="update.emit()"
      [disabled]="!isUpdated"
      label="Save"
      [outlined]="true"
      size="small"
    />
    <p-button
      *ngIf="
        (ownBooking && booking.status === 'Added') ||
        (booking.status === 'Added' && (user()!.isPracticeManager || user()!.isAdmin))
      "
      (click)="onDelete()"
      label="Delete"
      severity="danger"
      [outlined]="true"
      size="small"
    />
    <p-button
      *ngIf="ownBooking && (booking.status === 'Requested' || booking.status === 'Approved')"
      (click)="onCancel()"
      label="Cancel"
      severity="danger"
      [outlined]="true"
      size="small"
    />
    <p-button
      *ngIf="booking.status !== 'Added' && user()!.isAdmin"
      (click)="onDeleteAdmin()"
      label="Delete with force"
      severity="danger"
      [outlined]="true"
      size="small"
    />
  </section>
</div>
