import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export function getReviewForm() {
  return new FormGroup({
    message: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace()],
    }),
    rating: new FormControl<number>(1, {
      validators: [Validators.required, Validators.minLength(0), Validators.maxLength(5)],
    }),
  });
}
