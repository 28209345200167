import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { TrainingDetail } from '@/shared/models/training-detail';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NoWhitespaceValidatorDirective } from '../../validators/no-white-space.validator';

@Component({
  selector: 'tc-request-panel',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, DividerModule, InputTextareaModule, InfoTooltipComponent],
  templateUrl: './request-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestPanelComponent {
  private _training = signal<TrainingDetail | undefined>(undefined);
  readonly form = this._setupForm();

  @Input() loading: Signal<boolean> = signal(false);
  @Output() readonly request = new EventEmitter<string>();

  @Input() set optionSelected(value: boolean) {
    this.form.patchValue({ optionSelected: value });
  }
  @Input() set noPassedOptionSelected(value: boolean) {
    this.form.patchValue({ noPassedOptionSelected: value });
  }

  @Input()
  set training(value: TrainingDetail | undefined) {
    this._training.set(value);
  }
  get training() {
    return this._training();
  }

  constructor() {
    effect(() => {
      if (this.training?.autoApprove) {
        this.form.controls.motivation.setValidators([Validators.maxLength(255)]);
        this.form.controls.motivation.disable();
        this.form.controls.motivation.updateValueAndValidity();
      }
    });
  }

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const value = this.form.value;

    this.request.emit(value.motivation ?? '');
  }

  private _setupForm() {
    return new FormGroup({
      motivation: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(255), NoWhitespaceValidatorDirective.noWhitespace()],
      }),
      optionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
      noPassedOptionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
    });
  }
}
