import { ReviewDeleteComponent } from '@/reviews/components/review-delete/review-delete.component';
import { ReviewUpdateFormComponent } from '@/reviews/components/review-update/review-update-form.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { PageTitleService } from '@/shared/services/page-title.service';
import { reviewKeys, ReviewService } from '@/shared/services/review.service';
import { ToastService } from '@/shared/services/toast.service';
import { trainingKeys } from '@/shared/services/training.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewUpdateDto } from '@euricom/angular-training-catalog-api/models';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'tc-review-update',
  standalone: true,
  imports: [CommonModule, CardModule, ReviewUpdateFormComponent, LoaderComponent, ReviewDeleteComponent],
  providers: [ReviewService],
  templateUrl: './review-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewUpdateComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  readonly reviewId = this._route.snapshot.paramMap.get('id');

  readonly review = useQuery(
    computed(() => reviewKeys.byId(this.reviewId)),
    () => {
      return this._reviewService.getReviewAsync(this.reviewId ?? '');
    },
    {
      onError: () => {
        this._router.navigate(['/404']);
      },
      enabled: computed(() => !!this.reviewId),
    },
  );

  readonly updateReviewMutation = useMutation(this._reviewService.updateReviewAsync, {
    onSuccess: () => {
      this._toaster.success('The review was successfully updated!');
      this._queryClient.invalidateQueries(reviewKeys.all);
      this._queryClient.invalidateQueries(reviewKeys.byId(this.reviewId));
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._router.navigate(['/my-reviews']);
    },
  });

  readonly deleteReviewMutation = useMutation(this._reviewService.deleteReviewAsync, {
    onSuccess: () => {
      this._toaster.success('The review was successfully deleted!');
      this._queryClient.invalidateQueries(reviewKeys.all);
      this._queryClient.invalidateQueries(reviewKeys.byId(this.reviewId));
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._router.navigate(['/my-reviews']);
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toaster: ToastService,
    private _reviewService: ReviewService,
  ) {}

  ngOnInit() {
    this._pageTitleService.setPageTitle('Review Update');
  }

  onUpdateReview(review: ReviewUpdateDto) {
    if (!this.reviewId) {
      this._toaster.error('No review selected');
      return;
    }

    review.id = this.reviewId;
    this.updateReviewMutation.mutate(review);
  }

  onDeleteReview() {
    if (!this.reviewId) {
      this._toaster.error('No review selected');
      return;
    }

    this.deleteReviewMutation.mutate(this.reviewId);
  }
}
