<p-table
  #bookingsHRTable
  class="hidden md:block w-full"
  [value]="bookings"
  styleClass="p-datatable-sm"
  (sortFunction)="customSort($event)"
  [(selection)]="selectedBookings"
  [customSort]="true"
  [rowHover]="true"
  [sortOrder]="tableSort?.order ?? -1"
  [sortField]="tableSort?.sort"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4vw; max-width: 4vw">
        <div class="text-center">
          <p-tableHeaderCheckbox (click)="onSelect()" name="selectAll" />
        </div>
      </th>
      <th
        pSortableColumn="training.startDate"
        (click)="setSort('training.startDate')"
        style="width: 8vw; max-width: 8vw"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Start date</div>
          <p-sortIcon field="training.startDate" />
        </div>
      </th>

      <th pSortableColumn="status" (click)="setSort('status')" style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Status</div>
          <p-sortIcon field="status" />
        </div>
      </th>
      <th pSortableColumn="training.format" (click)="setSort('training.format')" style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Format</div>
          <p-sortIcon field="training.format" />
        </div>
      </th>
      <th pSortableColumn="training.name" (click)="setSort('training.name')" style="width: 12vw; max-width: 20vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Name</div>
          <p-sortIcon field="training.name" />
        </div>
      </th>
      <th pSortableColumn="requesterName" (click)="setSort('requesterName')" style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Requester</div>
          <p-sortIcon field="requesterName" />
        </div>
      </th>

      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="practiceManagerName"
        (click)="setSort('practiceManagerName')"
        style="width: 8vw; max-width: 8vw"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Practice manager</div>
          <p-sortIcon field="practiceManagerName" />
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="training.responsibleName"
        (click)="setSort('training.responsibleName')"
        style="width: 15vw; max-width: 15vw"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Responsible</div>
          <p-sortIcon field="training.responsibleName" />
        </div>
      </th>
      <th pSortableColumn="deadline" (click)="setSort('deadline')" style="width: 8vw; max-width: 8vw">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Deadline</div>
          <p-sortIcon field="deadline" />
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-booking>
    <tr (click)="onClickBooking(booking.id)" class="cursor-pointer">
      <td (click)="stopPropagation($event)">
        <div class="text-center">
          <p-tableCheckbox (click)="onSelect()" [value]="booking" name="select" />
        </div>
      </td>
      <td>{{ booking.training.startDate ? (booking.training.startDate | fDate) : '/' }}</td>
      <td [class]="booking.status === 'Verified' ? 'text-accent' : ''">{{ booking.status | fStatus }}</td>
      <td>
        <p-tag [style]="{ background: getFormatColor(booking.training.format), height: '10%' }"
          ><p class="w-max">{{ booking.training.format | fDisplay }}</p>
        </p-tag>
      </td>
      <td>{{ booking.training.name }}</td>
      <td>{{ booking.requesterName }}</td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ booking.practiceManagerName !== '' ? booking.practiceManagerName : '/' }}
      </td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ booking.training.responsibleName }}
      </td>
      <td>{{ booking.deadline ? (booking.deadline | fDate) : '/' }}</td>
    </tr>
  </ng-template>
</p-table>
<p class="text-center font-semibold mt-3" *ngIf="bookings.length === 0">No trainings found</p>

<p-table
  class="md:hidden"
  [value]="bookings"
  responsiveLayout="stack"
  [breakpoint]="'768px'"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [rowHover]="true"
>
  <ng-template class="md:hidden" pTemplate="body" let-booking>
    <tr (click)="onClickBooking(booking.id)" class="cursor-pointer">
      <td style="padding: 0.3rem">
        <div class="grid grid-cols-[7rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Created:</span>
          <span class="self-start overflow-x-hidden">{{ booking.createdOn | fDate }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem">
        <div class="grid grid-cols-[7rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Status:</span>
          <span class="self-start overflow-x-hidden">{{ booking.status | fStatus }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem">
        <div class="grid grid-cols-[7rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Name:</span>
          <span class="self-start overflow-x-hidden">{{ booking.training.name }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem">
        <div class="grid grid-cols-[7rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Requester:</span>
          <span class="self-start overflow-x-hidden">{{ booking.requesterName }}</span>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
