<div class="bg-base text-base-content h-screen grid grid-rows-[3rem_1fr]">
  <header class="bg-primary-400 text-primary-content items-center text-xl flex gap-5 px-5">
    <img src="../assets/icons/icon-192x192.png" class="h-6" (click)="onClickHome()" />
    <h1>Training Catalog</h1>
    <div class="flex lg:gap-4 text-[1rem] justify-end flex-grow">
      <ng-container *ngIf="user()">
        <div (click)="UserPanel.toggle($event)" class="flex cursor-pointer">
          <p-avatar *ngIf="profilePic()" styleClass="mr-2" class="flex align-middle" size="normal">
            <img [src]="profilePic()" alt="ProfilePic" />
          </p-avatar>
          <p-avatar
            *ngIf="!profilePic()"
            icon="pi pi-user"
            styleClass="mr-2"
            size="normal"
            class="flex align-middle text-base-100"
          />
          <p class="hidden md:block self-center">Hi, {{ user()!.name }}!</p>
        </div>
        <p-overlayPanel #UserPanel>
          <tc-user-panel [user]="user()!" [userImage]="profilePic()" />
        </p-overlayPanel>
      </ng-container>
    </div>
  </header>
  <div class="xl:grid xl:grid-cols-[12rem_1fr] overflow-hidden">
    <menu class="bg-base-100 hidden xl:block h-full overflow-hidden">
      <div class="m-3 mt-10 w-40">
        <div class="m-3 mt-10 w-40">
          <p-menu [model]="items()" />
        </div>
      </div>
    </menu>
    <div class="grid grid-rows-[3.5rem_1fr] border-l-2 border-base-300 h-full overflow-hidden">
      <div class="bg-base-100 text-2xl p-3 border-b-2 border-base-300 flex">
        <p-button
          class="xl:hidden flex align-middle"
          [link]="true"
          (click)="sideBarVisible.set(true)"
          icon="pi pi-bars"
        />
        <h1 class="ml-5 max-w-[80%] truncate ...">{{ title() }}</h1>
      </div>
      <div class="overflow-y-auto overflow-x-hidden bg-base-200" id="PageContent">
        <router-outlet />
      </div>
    </div>
  </div>
</div>

<p-sidebar
  [visible]="sideBarVisible()"
  (visibleChange)="sideBarVisible.set($event)"
  [style]="{ 'margin-top': '6.5rem', width: 'fit-content' }"
>
  <menu class="bg-base-100 w-fit">
    <div class="w-fit">
      <div class="w-fit">
        <p-menu [model]="items()" />
      </div>
    </div>
  </menu>
</p-sidebar>
