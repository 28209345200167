import { getSeasonForm } from '@/seasons/pages/season-upsert/helpers';
import { KeydownDirective } from '@/shared/directives/form-keydown-events.directive';
import { SeasonDetail } from '@/shared/models/season-detail';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { useQuery } from '@/shared/utils/query';
import { SeasonYearAlreadyExistValidatorDirective } from '@/shared/validators/season-year-already-exist.validator';
import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SeasonUpsertDto } from '@euricom/angular-training-catalog-api/models';
import { formatISO } from 'date-fns';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'tc-season-add',
  standalone: true,
  imports: [
    // primeng
    ButtonModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    CalendarModule,
    // common
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoFocusModule,
    KeydownDirective,
  ],
  providers: [SeasonsService],
  templateUrl: './season-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonAddComponent {
  private readonly _season = signal<SeasonDetail | undefined>(undefined);

  readonly seasons = useQuery(seasonKeys.all, () => {
    return this._seasonsService.getSeasonsAsync();
  });

  readonly mappedSeasons = computed(() =>
    this.seasons
      .data()
      ?.map((season) => season.year)
      .filter((year) => year !== this.season?.year),
  );

  readonly form = getSeasonForm();

  @Input() loadingUpsert = false;

  @Input() set season(season: SeasonDetail | undefined) {
    if (!season) return;
    this._season.set(season);
  }
  get season() {
    return this._season();
  }

  @Output() newSeason = new EventEmitter<SeasonUpsertDto>();

  readonly yearChange = toSignal(this.form.controls.year.valueChanges);

  constructor(private _router: Router, private _seasonsService: SeasonsService, private _location: Location) {
    //when the year changes, the year in the dates is updated (only when no season is selected)
    effect(
      () => {
        const season = this._season();
        if (season) return;

        this.yearChange();
        const year = this.form.controls.year.value;
        if (year > 2040 || year < 2015) return;
        const startDate = this.form.controls.date.controls.startDate;
        const endDate = this.form.controls.date.controls.endDate;
        startDate.setValue(new Date(startDate.value.setFullYear(year)));
        endDate.setValue(new Date(endDate.value.setFullYear(year)));
      },
      { allowSignalWrites: true },
    );

    // add validator to year field (season year must be unique)
    effect(
      () => {
        this.form.controls.year.setValidators([
          Validators.required,
          Validators.min(2015),
          Validators.max(2040),
          Validators.pattern('^[0-9]{4}$'),
          SeasonYearAlreadyExistValidatorDirective.seasonYearAlreadyExist(this.mappedSeasons() ?? []),
        ]);
        this.form.controls.year.updateValueAndValidity({ emitEvent: true });
      },
      { allowSignalWrites: true },
    );

    // when a season is selected, the form is updated
    effect(
      () => {
        const season = this._season();
        if (!season) return;

        this.form.setValue({
          year: season.year,
          date: {
            startDate: season.startDate,
            endDate: season.endDate,
          },
          multiplier: season.creditMultiplier,
          goal: season.creditGoal,
          dayCost: season.dayCost,
          daysBeforeDeadlineToGetNotified: season.daysBeforeDeadlineToGetNotified,
        });
      },
      { allowSignalWrites: true },
    );
  }

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const formValue = this.form.value;
    const startDate = formValue.date?.startDate ?? new Date();
    const endDate = formValue.date?.endDate ?? new Date();

    const newSeason: SeasonUpsertDto = {
      year: formValue.year ?? 0,
      startDate: formatISO(startDate),
      endDate: formatISO(endDate),
      creditMultiplier: formValue.multiplier ?? 0,
      creditGoal: formValue.goal ?? 0,
      dayCost: formValue.dayCost ?? 0,
      daysBeforeDeadlineToGetNotified: formValue.daysBeforeDeadlineToGetNotified ?? 0,
    };

    this.newSeason.emit(newSeason);
  }

  onCancel() {
    this._location.back();
  }
}
