import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { UserPanelComponent } from '../../components/user-panel/user-panel.component';
import { PageTitleService } from '../../services/page-title.service';
import { ProfileImageService } from '../../services/profile-image.service';
import { useCurrentBreakpoint } from '../../utils/breakpoints';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MenuModule,
    ButtonModule,
    SidebarModule,
    AvatarModule,
    OverlayPanelModule,
    UserPanelComponent,
  ],
  selector: 'tc-main-layout',
  templateUrl: './main-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  readonly title = this._pageTitleService.getPageTitle();

  readonly profilePic = signal('');
  readonly currentBreakpoint = useCurrentBreakpoint();

  readonly items = computed(() => {
    const breakpoint = this.currentBreakpoint();
    if (!breakpoint) return;
    return this._setNavItems(breakpoint);
  });

  readonly sideBarVisible = signal(false);
  readonly user = useAuth().user;

  constructor(
    private _pageTitleService: PageTitleService,
    private readonly _imageService: ProfileImageService,
    private router: Router,
  ) {
    // Close sidebar when breakpoint changes
    effect(
      () => {
        const breakpoint = this.currentBreakpoint();
        if (!breakpoint) return;
        if (!['sm', 'md', 'unknown'].includes(breakpoint)) this.sideBarVisible.set(false);
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {
    this._fetchUserProfilePic();
  }

  private _handleClickMenuItem() {
    this.sideBarVisible.set(false);
  }

  onClickHome() {
    this.router.navigate(['/']);
  }

  // private async _fetchUserProfilePic() {
  //   const result = await this._imageService.getProfileImage();

  //   if (!result) return;

  //   const buffer = await result.arrayBuffer();
  //   const base64 = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  //   this.profilePic.set('data:image/png;base64,' + base64);
  // }
  private async _fetchUserProfilePic() {
    const result = await this._imageService.getProfileImage();

    if (!result) return;

    const buffer = await result.arrayBuffer();
    const base64 = this.arrayBufferToBase64(buffer);
    this.profilePic.set('data:image/png;base64,' + base64);
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    const CHUNK_SIZE = 8192; // Chunk size of 8KB

    let base64String = '';
    const uint8Array = new Uint8Array(buffer);

    for (let i = 0; i < uint8Array.length; i += CHUNK_SIZE) {
      const chunk = uint8Array.subarray(i, i + CHUNK_SIZE);
      base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
  }

  private _setNavItems(breakpoint: string): MenuItem[] {
    return [
      {
        label: 'Catalog',
        items: [
          {
            label: 'Search',
            icon: 'pi pi-search',
            routerLink: ['/trainings'],
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'Calendar',
            icon: 'pi pi-calendar',
            routerLink: ['/trainings-calendar'],
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'My bookings',
            icon: 'pi pi-folder',
            routerLink: [`/bookings`],

            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'My reviews',
            icon: 'pi pi-comments',
            routerLink: [`/my-reviews`],

            command: () => this._handleClickMenuItem(),
          },
        ],
      },
      {
        label: 'Follow up',
        items: [
          {
            label: 'Bookings',
            icon: 'pi pi-book',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/follow-up/bookings'],
            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'Trainings',
            icon: 'pi pi-folder',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/follow-up/trainings'],
            command: () => this._handleClickMenuItem(),
            visible: this.user()?.isAdmin || this.user()?.isPracticeManager || this.user()?.isHumanResources,
          },

          {
            label: 'Reviews',
            icon: 'pi pi-comments',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/follow-up/reviews'],
            command: () => this._handleClickMenuItem(),
            visible: this.user()?.isAdmin || this.user()?.isPracticeManager || this.user()?.isHumanResources,
          },

          {
            label: 'Stand-ins',
            icon: 'pi pi-users',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/follow-up/stand-ins'],
            command: () => this._handleClickMenuItem(),
            visible: this.user()?.isAdmin || this.user()?.isPracticeManager || this.user()?.isHumanResources,
          },
        ],
        visible: this.user()?.isAdmin || this.user()?.isPracticeManager || this.user()?.isHumanResources,
      },
      {
        label: 'Admin',
        items: [
          {
            label: 'Trainings',
            icon: 'pi pi-folder',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/admin/trainings'],
            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'Collections',
            icon: 'pi pi-folder',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: ['/admin/collections'],
            command: () => this._handleClickMenuItem(),
          },
          {
            label: 'Seasons',
            icon: 'pi pi-calendar',
            routerLinkActiveOptions: {
              queryParams: 'ignored',
              matrixParams: 'exact',
            },
            routerLink: '/admin/seasons',
            command: () => this._handleClickMenuItem(),
          },
        ],
        visible:
          breakpoint !== 'sm' &&
          breakpoint !== 'unknown' &&
          (this.user()?.isAdmin || this.user()?.isCatalogResponsible),
      },
    ];
  }
}
