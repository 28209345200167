import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { BookingsService, bookingKeys } from '@/shared/services/booking.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SeasonsService, getActiveSeasonIds, seasonKeys } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    CheckboxModule,
    FormatDisplayPipe,
    CardModule,
    LoaderComponent,
    ButtonModule,
    TooltipModule,
    FormatDatePipe,
    StatusDisplayPipe,
  ],
  providers: [BookingsService, SeasonsService, ToastService],
  selector: 'tc-bookings-overview',
  templateUrl: './bookings-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsOverviewComponent implements OnInit {
  readonly bookings = useQuery(bookingKeys.listMy(), async () => {
    const bookings = await this._bookingsService.getBookingsAsync({ myBookings: true });
    return [...bookings].reverse();
  });

  readonly seasons = useQuery(seasonKeys.all, () => {
    return this._seasonsService.getSeasonsAsync();
  });
  readonly activeSeasons = computed(() => getActiveSeasonIds(this.seasons.data));

  readonly bookingsCurrentSeason = computed(() => {
    const seasons = this.activeSeasons();
    const bookings = this.bookings.data();
    if (!seasons || !bookings) return null;

    return bookings.filter((x) => seasons.includes(x.training.season.id));
  });

  readonly breakpoint = useCurrentBreakpoint();
  readonly hideColumnsBreakpoints = ['sm', 'md', 'lg', 'unknown'];

  constructor(
    private _pageTitleService: PageTitleService,
    private _router: Router,
    private _bookingsService: BookingsService,
    private _seasonsService: SeasonsService,
  ) {}

  ngOnInit(): void {
    this._pageTitleService.setPageTitle('My bookings');
  }

  onClickBooking(id: string): void {
    this._router.navigate(['/bookings', id]);
  }

  calculateUsedCredits(year: number) {
    const bookings = this.bookings.data();
    if (!bookings) return 0;
    const bookedBookingsOrCancelledBookingWithRefund = bookings.filter(
      (b) =>
        b.training.season.year === year &&
        (b.status === 'Booked' || b.status === 'Verified' || (b.status === 'Cancelled' && !b.refundCreditsOnCancel)),
    );

    return bookedBookingsOrCancelledBookingWithRefund.reduce((acc, curr) => {
      return acc + curr.credits;
    }, 0);
  }

  calculateNotRequestedCredits(year: number) {
    const bookings = this.bookings.data();
    if (!bookings) return 0;
    const AddedBookings = bookings.filter((x) => x.training.season.year === year && x.status === 'Added');

    return AddedBookings.reduce((acc, curr) => {
      return acc + curr.credits;
    }, 0);
  }

  calculateApprovedCredits(year: number) {
    const bookings = this.bookings.data();
    if (!bookings) return 0;
    const ApprovedBookings = bookings.filter((x) => x.training.season.year === year && x.status === 'Approved');
    return ApprovedBookings.reduce((acc, curr) => {
      return acc + curr.credits;
    }, 0);
  }
}
