<div class="flex justify-between">
  <h1 class="text-2xl">Cancel booking</h1>
  <tc-info-tooltip
    text="Cancel the booking when the training is cancelled or postponed."/>
</div>
<p-divider/>
<form [formGroup]="form">
  <label for="motivation">Comment <span class="text-error">*</span></label>
  <textarea
    rows="5"
    id="motivation"
    name="motivation"
    [formControl]="form.controls.comment"
    pInputTextarea
    class="w-full"
  ></textarea>
  <p
    *ngIf="
      form.controls.comment.dirty &&
      (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
    "
    class="text-error"
  >
    Comment is required.
  </p>
  <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
    Comment is too long.
  </p>
  <div class="flex align-baseline gap-2">
    <p-checkbox
      [value]="form.controls.refund"
      [binary]="true"
      inputId="refund"
      name="refund"
      [formControl]="form.controls.refund"
    />
    <label for="refund" class="cursor-pointer">Refund credits</label>
  </div>
  <div class="mt-3">
    <div>
      <p-button
        class="mr-1"
        label="Cancel"
        type="submit"
        size="small"
        [outlined]="true"
        severity="danger"
        (onClick)="onCancel()"
      />
    </div>
  </div>
</form>
