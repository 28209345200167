import { SortOptionKeys } from '@/shared/components/sort/sort.component';
import { Training } from '@/shared/models/training';
import { formatDisplay } from '@/shared/pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';
import { format } from 'date-fns';

export const concatTraining = (training: Training) => {
  const name = training.name;
  const credits = training.credits.toString();
  const deadline = format(training.deadline ?? 0, 'dd/MM/yy');
  const startDate = format(training.startDate ?? 0, 'dd/MM/yy');
  const trainingFormat = training.format;
  const trainingFormatDisplay = formatDisplay(training.format);
  return [name, credits, deadline, startDate, trainingFormat, trainingFormatDisplay].join(' ').toLowerCase();
};

export const compareTraining = (sortBy: SortOptionKeys | null) => {
  return (a: Training, b: Training) => {
    const undefinedValue = Number.POSITIVE_INFINITY;

    switch (sortBy) {
      case 'Date':
        return (!a.showOnCalendar ? undefinedValue : a.startDate?.valueOf() ?? undefinedValue) -
          (!b.showOnCalendar ? undefinedValue : b.startDate?.valueOf() ?? undefinedValue) ===
          0
          ? a.name.localeCompare(b.name)
          : (!a.showOnCalendar ? undefinedValue : a.startDate?.valueOf() ?? undefinedValue) -
              (!b.showOnCalendar ? undefinedValue : b.startDate?.valueOf() ?? undefinedValue);
      case 'Deadline': {
        const comparison = (a.deadline?.valueOf() ?? undefinedValue) - (b.deadline?.valueOf() ?? undefinedValue);
        return isNaN(comparison) || comparison === 0
          ? a.name.localeCompare(b.name)
          : (a.deadline?.valueOf() ?? undefinedValue) - (b.deadline?.valueOf() ?? undefinedValue);
      }
      case 'TitleAsc':
        return a.name.localeCompare(b.name);
      case 'TitleDesc':
        return b.name.localeCompare(a.name);
      default:
        return a.name.localeCompare(b.name);
    }
  };
};

export const filterAndSortTrainings = (
  trainings: MaybeSignal<Training[] | undefined>,
  searchExpression: MaybeSignal<string>,
  domains: MaybeSignal<string[] | null>,
  formats: MaybeSignal<string[] | null>,
  sortBy: MaybeSignal<SortOptionKeys | null>,
) => {
  return (unsignal(trainings) ?? [])
    .filter((item) => {
      const domainFilter = unsignal(domains) || [];
      return domainFilter.length === 0 || item.domains.some((domain) => domainFilter.includes(domain.id));
    })
    .filter((item) => {
      const formatFilter = unsignal(formats) || [];
      return formatFilter.length === 0 || formatFilter.includes(item.format);
    })
    .filter((item) => {
      const search = unsignal(searchExpression) || '';
      const searchTerms = search.split(' ') ?? [];
      const trainingText = concatTraining(item);
      return searchTerms.every((searchTerm) => trainingText.includes(searchTerm.toLowerCase()));
    })
    .sort((a, b) => compareTraining(unsignal(sortBy))(a, b));
};
