<p-panel [header]="header" class="cursor-pointer" [toggleable]="true" toggler="header" [collapsed]="isSmallScreen()">
  <div class="flex flex-col gap-3" *ngIf="type === 'multiple'">
    <div *ngFor="let facet of options" class="field-checkbox flex flex-row justify-between">
      <div class="flex justify-center">
        <p-checkbox [value]="facet.key" [inputId]="facet.displayValue" name="filter" [formControl]="selectionControl"/>
        <label [for]="facet.displayValue" class="ml-2 hover:cursor-pointer">{{ facet.displayValue }}</label>
      </div>
      <div *ngIf="showFormatLegenda" class="rounded-full h-4 w-4 my-auto"
           [ngStyle]="getFormatColor(facet.displayValue)"></div>
    </div>
  </div>
  <div class="flex flex-col gap-3" *ngIf="type === 'single'">
    <div *ngFor="let facet of options" class="field-radiobutton flex flex-row">
      <p-radioButton
        [value]="facet.key"
        [inputId]="facet.displayValue"
        name="filter"
        [formControl]="selectionControl"
      />
      <label [for]="facet.displayValue" class="ml-2 hover:cursor-pointer">{{ facet.displayValue }}</label>
    </div>
  </div>
</p-panel>
