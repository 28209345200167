import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, computed } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { delay, map } from 'rxjs';
import { useCurrentBreakpoint } from '../../utils/breakpoints';
import { Facet } from '../filter-facet/facet';

@Component({
  selector: 'tc-dropdown-facet',
  standalone: true,
  imports: [CommonModule, PanelModule, ReactiveFormsModule, DropdownModule],
  templateUrl: './dropdown-facet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFacetComponent<T extends string | number> {
  readonly dropdownControl = new FormControl<Facet<T> | null>(null);

  @Input() storageId: string | null = null;
  @Input() options: Facet<T>[];
  @Input() header: string;

  @Input()
  set selected(value: Facet<T> | null | undefined) {
    if (!value) return;
    this.dropdownControl.setValue(value, { emitEvent: false });
  }

  @Output()
  dropdownChange = this.dropdownControl.valueChanges.pipe(
    delay(0),
    map((value) => {
      return value?.key ?? '';
    }),
  );

  readonly breakpoint = useCurrentBreakpoint();

  readonly isSmallScreen = computed(() => {
    const breakpoint = this.breakpoint();
    if (!breakpoint) return false;

    return ['unknown', 'sm'].includes(breakpoint);
  });

  constructor() {
    this.options = [];
    this.header = 'Filter';
  }
}
