import { CollectionAdminTableComponent } from '@/collections/components/collection-admin-table/collection-admin-table.component';
import { CollectionFilterHeaderComponent } from '@/collections/components/collection-filter-header/collection-filter-header.component';
import { filterAdminCollections } from '@/collections/pages/collections-admin/helpers';
import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { collectionKeys, CollectionsService, getFormatsCollection } from '@/shared/services/collections.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { useQuery } from '@/shared/utils/query';
import { TrainingAdminTableComponent } from '@/trainings/components/training-admin-table/training-admin-table.component';
import { TrainingFilterHeaderComponent } from '@/trainings/components/training-filter-header/training-filter-header.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';

type SearchParams = {
  search: string;
  format: string[];
  sort: SortTypesCollectionTable;
  order: string;
};

export type SortTypesCollectionTable = 'title' | 'format';

export type CollectionTableSort = {
  sort: SortTypesCollectionTable;
  order: number;
};

@Component({
  selector: 'tc-collections-admin',
  standalone: true,
  imports: [
    // primeng
    ButtonModule,
    CardModule,
    TableModule,
    DividerModule,
    // common
    CommonModule,
    LoaderComponent,
    SharedModule,
    FormatDisplayPipe,
    CollectionAdminTableComponent,
    FilterFacetComponent,
    TrainingAdminTableComponent,
    TrainingFilterHeaderComponent,
    CollectionFilterHeaderComponent,
  ],
  providers: [CollectionsService, SearchParamsService],
  templateUrl: './collections-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsAdminComponent implements OnInit {
  readonly collections = useQuery(collectionKeys.all, () => {
    return this._collectionService.getTrainingCollectionsAsync();
  });

  readonly sortedCollections = computed(() => {
    return this.collections.data()?.sort((a, b) => a.title.localeCompare(b.title));
  });

  searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchValue = signal(this.searchParams.search);
  readonly formatFilters = signal<string[]>(this.searchParams.format);
  readonly sort = signal<CollectionTableSort>({
    sort: this.searchParams.sort,
    order: parseInt(this.searchParams.order ?? '-1'),
  });

  readonly formats = computed(() => {
    return getFormatsCollection(this.collections.data);
  });

  readonly filteredCollections = computed(() => {
    return filterAdminCollections(this.sortedCollections, this.searchValue, this.formatFilters);
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _collectionService: CollectionsService,
    private _router: Router,
    private _searchParamService: SearchParamsService,
  ) {
    // update the query params when the search value changes
    effect(() => {
      const queryParams = {
        search: this.searchValue() || undefined, // must be undefined to delete the search when not set
        format: this.formatFilters(),
        sort: this.sort().sort,
        order: this.sort().sort ? this.sort().order : undefined,
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Collections admin');
  }

  onSearchCollections($event: string) {
    this.searchValue.set($event);
  }

  onClickAddCollection() {
    this._router.navigate(['/admin/collections/add']);
  }

  onClickAddNewTraining(collectionId: string) {
    this._router.navigate(['/admin/trainings/add'], { queryParams: { collectionId: collectionId } });
  }

  onClickCollection(collectionId: string) {
    this._router.navigate(['/admin/collections/', collectionId]);
  }

  onSortChanges(sort: SortTypesCollectionTable, order: number) {
    this.sort.set({ sort: sort, order: order });
  }
}
