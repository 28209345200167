import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) {}

  closeAllToasts() {
    this.messageService.clear();
  }

  success(message: string, options?: Omit<Message, 'detail' | 'severity' | 'summary'>) {
    this.messageService.add({
      life: 3000,
      ...(options ?? {}),
      summary: 'Success',
      severity: 'success',
      detail: message,
    });
  }
  warning(message: string, options?: Omit<Message, 'detail' | 'severity' | 'summary'>) {
    this.messageService.add({
      life: 3000,
      ...(options ?? {}),
      summary: 'Warning',
      severity: 'warn',
      detail: message,
    });
  }
  error(message: string, options?: Omit<Message, 'detail' | 'severity' | 'summary'>) {
    this.messageService.add({
      ...(options ?? {}),
      summary: 'Error',
      severity: 'error',
      detail: message,
    });
  }
}
