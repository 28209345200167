/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ReviewDto } from '../models/review-dto';
import { ReviewListDto } from '../models/review-list-dto';
import { ReviewUpdateDto } from '../models/review-update-dto';

@Injectable({ providedIn: 'root' })
export class ReviewsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getReview()` */
  static readonly GetReviewPath = '/api/Reviews/{id}';

  /**
   * Retrieves review.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReview$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReviewDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewsService.GetReviewPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReviewDto>;
      })
    );
  }

  /**
   * Retrieves review.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReview(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ReviewDto> {
    return this.getReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewDto>): ReviewDto => r.body)
    );
  }

  /** Path part for operation `deleteReview()` */
  static readonly DeleteReviewPath = '/api/Reviews/{id}';

  /**
   * Delete a review.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReview$Response(
    params: {

    /**
     * The review id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewsService.DeleteReviewPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a review.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReview(
    params: {

    /**
     * The review id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getReviews()` */
  static readonly GetReviewsPath = '/api/Reviews';

  /**
   * Retrieves all reviews.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviews()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviews$Response(
    params?: {

    /**
     * If true, only reviews of the current user are returned.
     */
      myReviews?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ReviewListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewsService.GetReviewsPath, 'get');
    if (params) {
      rb.query('myReviews', params.myReviews, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReviewListDto>>;
      })
    );
  }

  /**
   * Retrieves all reviews.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReviews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviews(
    params?: {

    /**
     * If true, only reviews of the current user are returned.
     */
      myReviews?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<ReviewListDto>> {
    return this.getReviews$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReviewListDto>>): Array<ReviewListDto> => r.body)
    );
  }

  /** Path part for operation `addReview()` */
  static readonly AddReviewPath = '/api/Reviews';

  /**
   * Adds a review.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  addReview$Response(
    params?: {

    /**
     * The id of the training the review is written for.
     */
      id?: string;

    /**
     * The message of the review, the actual review.
     */
      message?: string;

    /**
     * A rating of the training between 0 and 5.
     */
      rating?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReviewDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewsService.AddReviewPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('message', params.message, {});
      rb.query('rating', params.rating, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReviewDto>;
      })
    );
  }

  /**
   * Adds a review.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addReview(
    params?: {

    /**
     * The id of the training the review is written for.
     */
      id?: string;

    /**
     * The message of the review, the actual review.
     */
      message?: string;

    /**
     * A rating of the training between 0 and 5.
     */
      rating?: number;
    },
    context?: HttpContext
  ): Observable<ReviewDto> {
    return this.addReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewDto>): ReviewDto => r.body)
    );
  }

  /** Path part for operation `updateReview()` */
  static readonly UpdateReviewPath = '/api/Reviews/update';

  /**
   * Update a review.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateReview$Response(
    params?: {
  
    /**
     * The review-update data.
     */
    body?: ReviewUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReviewDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewsService.UpdateReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReviewDto>;
      })
    );
  }

  /**
   * Update a review.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateReview(
    params?: {
  
    /**
     * The review-update data.
     */
    body?: ReviewUpdateDto
    },
    context?: HttpContext
  ): Observable<ReviewDto> {
    return this.updateReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewDto>): ReviewDto => r.body)
    );
  }

}
