import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { TrainingsService, getFormats, trainingKeys } from '@/shared/services/training.service';
import { useQuery } from '@/shared/utils/query';
import { TrainingFollowUpFilterHeaderComponent } from '@/trainings/components/training-follow-up-filter-header/training-follow-up-filter-header.component';
import { TrainingFollowUpTableComponent } from '@/trainings/components/training-follow-up-table/training-follow-up-table.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { filterTrainings } from './helpers';

type SearchParams = {
  search: string;
  format: string[];
  sort: SortTypesTrainingFollowUpTable;
  order: string;
};

export type SortTypesTrainingFollowUpTable =
  | 'season.year'
  | 'format'
  | 'name'
  | 'requesterName'
  | 'startDate'
  | 'endDate'
  | 'responsibleName'
  | 'nrOfBookings';

export type TrainingFollowUpTableSort = {
  sort: SortTypesTrainingFollowUpTable;
  order: number;
};

@Component({
  standalone: true,
  selector: 'tc-trainings-follow-up',
  templateUrl: './training-follow-up.component.html',
  providers: [TrainingsService, SearchParamsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    // angular
    CommonModule,
    // primeNG
    CardModule,
    DividerModule,
    InputTextModule,
    // custom
    TrainingFollowUpFilterHeaderComponent,
    TrainingFollowUpTableComponent,
    FilterFacetComponent,
    LoaderComponent,
  ],
})
export class TrainingsFollowUpComponent implements OnInit {
  readonly trainings = useQuery(trainingKeys.followUp, async () => {
    const trainings = await this._trainingService.getTrainingsFollowUpAsync();
    return [...trainings.sort((a, b) => (b.startDate?.getTime() ?? 0) - (a.startDate?.getTime() ?? 0))];
  });

  readonly formats = computed(() => {
    return getFormats(this.trainings.data);
  });

  readonly searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchValue = signal(this.searchParams.search);
  readonly formatFilters = signal(this.searchParams.format);
  readonly sort = signal<TrainingFollowUpTableSort>({
    sort: this.searchParams.sort,
    order: parseInt(this.searchParams.order ?? '-1'),
  });

  readonly filteredTrainings = computed(() => {
    return filterTrainings(this.trainings.data, this.searchValue, this.formatFilters);
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _trainingService: TrainingsService,
    private _searchParamService: SearchParamsService,
    private _router: Router,
  ) {
    // When changing the filters, update the url
    effect(() => {
      const queryParams = {
        search: this.searchValue() || undefined, // must be undefined to delete the search when not set
        sort: this.sort().sort,
        order: this.sort().sort ? this.sort().order : undefined,
        format: this.formatFilters(),
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Trainings follow-up');
  }

  onClickTraining(trainingId: string) {
    this._router.navigate(['/follow-up/trainings/', trainingId]).then();
  }

  onSortChanges(sort: SortTypesTrainingFollowUpTable, order: number) {
    this.sort.set({ sort: sort, order: order });
  }
}
