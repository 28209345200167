import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { UserDto, UserPlanDto, UserProfileDetailDto } from '@euricom/angular-training-catalog-api/models';
import { UsersService as RootUsersService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';
import { Facet } from '../components/filter-facet/facet';

export interface UserProfileDetail {
  creditsGoal?: number;
  creditsUsed?: number;
  email: string;
  imageUrl?: string;
  name: string;
  plan: UserPlanDto[];
}

export function mapUserProfileDetail(dto: UserProfileDetailDto): UserProfileDetail {
  return {
    ...dto,
    creditsGoal: dto.creditsGoal ?? undefined,
    creditsUsed: dto.creditsUsed ?? undefined,
    imageUrl: dto.imageUrl ?? undefined,
  };
}

export const pmKeys = {
  // all pms
  all: ['pms'] as const,

  // specific pm
  byId: (id: string | null) => [...pmKeys.all, id] as const,
};

export const responsiblesKeys = {
  // all responsibles
  all: ['responsibles'] as const,

  // all responsibles facet
  allFacet: ['responsibles-facet'] as const,
};

export const userKeys = {
  // all users
  all: ['users'] as const,

  // specific user
  byEmail: (email: string | null) => [...userKeys.all, email] as const,
};

@Injectable()
export class UsersService extends RootUsersService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  public async getCatalogResponsiblesAsync(): Promise<UserDto[]> {
    return await firstValueFrom(this.getCatalogResponsibles());
  }

  async getPracticeManagersAsync(): Promise<Facet<string>[]> {
    const pms = await firstValueFrom(this.getPracticeManagers());
    const pmsMapped = pms.map((pm) => {
      return {
        displayValue: pm.name,
        key: pm.email,
      };
    });
    return pmsMapped;
  }

  async getUserProfileAsync(email: string): Promise<UserProfileDetail> {
    const data = await firstValueFrom(
      this.getUser({
        email: email,
      }),
    );
    return mapUserProfileDetail(data);
  }

  async getUsersAsync(): Promise<Facet<string>[]> {
    const users = await firstValueFrom(this.getUsers());
    const usersMapped = users.map((user) => {
      return {
        displayValue: user.name,
        key: user.email,
      };
    });
    return [{ displayValue: 'None', key: 'none' }, ...usersMapped];
  }
}
