type Functions<T extends object> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? K : never;
}[keyof T];

type Properties<T extends object> = Omit<T, Functions<T>>;

export class User {
  public readonly email: string;
  public readonly name?: string;

  public readonly isAdmin: boolean;
  public readonly isHumanResources: boolean;
  public readonly isCatalogResponsible: boolean;
  public readonly isPracticeManager: boolean;

  public readonly roles: string[];

  constructor(userInfo: Properties<User>) {
    this.email = userInfo.email;
    this.name = userInfo.name;
    this.isAdmin = userInfo.isAdmin;
    this.isHumanResources = userInfo.isHumanResources;
    this.isCatalogResponsible = userInfo.isCatalogResponsible;
    this.isPracticeManager = userInfo.isPracticeManager;
    this.roles = userInfo.roles;
  }

  public hasRole(role: string | string[]): boolean {
    if (!Array.isArray(role)) {
      role = [role];
    }
    return role.some((item) => this.roles.includes(item));
  }
}
