import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileImageService {
  constructor(private _http: HttpClient) {}

  async getProfileImage() {
    return await firstValueFrom(
      this._http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' }),
    ).catch(() => {
      return null;
    });
  }
}
