import { Format } from '@euricom/angular-training-catalog-api/models';

export function getFormatColor(format: Format): string {
  switch (format) {
    case Format.Certificate:
      return '#B3CCFF';
    case Format.Conference:
      return '#A3D6A3';
    case Format.InstructorLed:
      return '#edd077';
    case Format.SelfPaced:
      return '#C08CC0';
    case Format.Event:
      return '#ff9999';
  }
}

export function getFormatColorByString(format: string): string {
  switch (format) {
    case 'Certificate':
      return '#B3CCFF';
    case 'Conference':
      return '#A3D6A3';
    case 'Instructor led':
      return '#edd077';
    case 'Self-paced':
      return '#C08CC0';
    case 'Event':
      return '#ff9999';
    default:
      return '#fff';
  }
}
