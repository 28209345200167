import { Facet } from '@/shared/components/filter-facet/facet';
import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { map } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    DropdownModule,
    ReactiveFormsModule,
    TooltipModule,
    InfoTooltipComponent,
  ],
  selector: 'tc-add-booking',
  templateUrl: './training-add-booking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAddBookingComponent {
  @Input() passedDeadline = false;
  @Input() alreadyBooked = false;
  @Input() users: Facet<string>[] | undefined;
  @Input() loading: Signal<boolean> = signal(false);

  currentUser = useAuth().user();
  addForUser = new FormControl<Facet<string> | null>(null);

  @Output() readonly add = new EventEmitter();
  @Output()
  selectedChange = this.addForUser.valueChanges.pipe(
    map((user) => {
      return user && user.key !== 'none'
        ? {
            email: user.key,
            name: user.displayValue,
          }
        : null;
    }),
  );

  onCreateBooking() {
    this.add.emit();
  }
}
