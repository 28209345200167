/* eslint-disable @typescript-eslint/no-explicit-any */
import { Signal, signal } from '@angular/core';
import { type MutateFunction, type MutationFunction } from '@tanstack/query-core';

type MutateSyncFunction<TData = unknown, TError = unknown, TVariables = void, TContext = unknown> = (
  ...options: Parameters<MutateFunction<TData, TError, TVariables, TContext>>
) => void;

export type MutationResult<TData, TError, TVariables, TContext> = {
  data: Signal<TData | undefined>;
  isLoading: Signal<boolean>;
  error: Signal<TError | undefined>;
  mutate: MutateSyncFunction<TData, TError, TVariables, TContext>;
  mutateAsync: MutateFunction<TData, TError, TVariables, TContext>;
};

export interface MutationOptions<TData = unknown, TError = unknown> {
  /**
   * This callback will fire any time the query successfully fetches new data.
   */
  onSuccess?: (data: TData) => void;

  /**
   * This callback will fire if the query encounters an error and will be passed the error.
   */
  onError?: (err: TError) => void;

  /**
   * This callback will fire any time the query is either successfully fetched or errors and be passed either the data or error.
   */
  onSettled?: (data: TData | undefined, error: TError | null) => void;
}

export function useMutation<TData = unknown, TError = unknown, TVariables = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: MutationOptions<TData, TError>,
): MutationResult<TData, TError, TVariables, unknown> {
  const data = signal<TData | undefined>(undefined);
  const isLoading = signal<boolean>(false);
  const error = signal<TError | undefined>(undefined);

  const mutate = async (variables: TVariables) => {
    isLoading.set(true);
    mutationFn(variables)
      .then((result) => {
        data.set(result);
        try {
          options?.onSuccess?.(result);
        } catch (e) {
          console.error(e);
        }
      })
      .catch((reason: TError) => {
        error.set(reason);
        try {
          options?.onError?.(reason);
        } catch (e) {
          console.error(e);
        }
      })
      .finally(() => {
        isLoading.set(false);
        try {
          options?.onSettled?.(data(), error() ?? null);
        } catch (e) {
          console.error(e);
        }
      });
  };

  const mutateAsync = (variables: TVariables): Promise<TData> => {
    return mutationFn(variables);
  };

  return {
    mutate,
    mutateAsync,
    data,
    isLoading,
    error,
  };
}
