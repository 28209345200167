import { KnowledgeDomain } from '@/shared/models/knowledge-domain';
import { OptionArrayGroupItem } from '@/shared/models/option-array-group-item';
import { Season } from '@/shared/models/season';
import { SeasonDetail } from '@/shared/models/season-detail';
import { TrainingCollection } from '@/shared/models/training-collection';
import { TrainingOption } from '@/shared/models/training-option';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';
import { DeadlineBeforeStartValidatorDirective } from '@/shared/validators/deadline-before-start.validator';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { NotActiveWhenNoStartEndDirective } from '@/shared/validators/not-active-when-no-start-end.validator';
import { StartBeforeEndDirective } from '@/shared/validators/start-before-end.validator';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Format, UserDto } from '@euricom/angular-training-catalog-api/models';

export function getTrainingForm() {
  return new FormGroup(
    {
      id: new FormControl<string | null>(null),
      season: new FormControl<SeasonDetail | null>(null, { validators: [Validators.required] }),
      collection: new FormControl<TrainingCollection | null>(null, { validators: [Validators.required] }),
      format: new FormControl<Format | null>({ value: null, disabled: true }),
      trainingName: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace(), Validators.maxLength(255)],
      }),
      trainingIsEnabled: new FormControl<{ value: boolean | undefined; display: string }>({
        value: undefined,
        display: 'Draft',
      }),
      autoBook: new FormControl<boolean>(false),
      showOnCalendar: new FormControl<boolean>(false),
      showParticipants: new FormControl<boolean>(true),
      allowMultipleBookings: new FormControl<boolean>(false),
      knowledgeDomains: new FormControl<KnowledgeDomain[]>([], { validators: [Validators.required] }),
      responsible: new FormControl<UserDto | null>(null, { validators: [Validators.required] }),
      website: new FormControl<string>('', {
        validators: [Validators.pattern('https?://.+'), Validators.maxLength(500)],
      }),
      address: new FormControl<string>('', { validators: [Validators.maxLength(500)] }),
      description: new FormControl<string>(''),
      info: new FormControl<string>(''),
      trainingOptions: new FormArray<FormGroup<OptionArrayGroupItem>>([]),
    },
    {
      validators: [NotActiveWhenNoStartEndDirective.NotActiveWhenNoStartEnd()],
    },
  );
}

export function getTrainingOptionForm(defaultValue = true) {
  return new FormGroup({
    id: new FormControl<string | null>(null),
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace(), Validators.maxLength(255)],
    }),
    date: new FormGroup(
      {
        startDate: new FormControl<Date | null>(null),
        endDate: new FormControl<Date | null>(null),
        deadline: new FormControl<Date | null>(null),
      },
      {
        validators: [
          StartBeforeEndDirective.startBeforeEnd(),
          DeadlineBeforeStartValidatorDirective.deadlineBeforeStart(),
        ],
      },
    ),
    default: new FormControl<boolean>(defaultValue, { nonNullable: true, validators: [Validators.required] }),
    description: new FormControl<string>(''),
    comment: new FormControl<string>(''),
    cost: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('[0-9]*')],
    }),
    halfDays: new FormControl<number | null>(null, { validators: [Validators.pattern('[0-9]*')] }),
    credits: new FormControl<number | null>({ value: null, disabled: true }),
  });
}

export function getTrainingOptionFormFilledIn(option: TrainingOption) {
  return new FormGroup({
    id: new FormControl<string | null>(option.id ?? null),
    name: new FormControl<string>(option.name ?? '', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    date: new FormGroup(
      {
        startDate: new FormControl<Date | null>(option.startDate ?? null),
        endDate: new FormControl<Date | null>(option.endDate ?? null),
        deadline: new FormControl<Date | null>(option.deadline ?? null),
      },
      {
        validators: [
          StartBeforeEndDirective.startBeforeEnd(),
          DeadlineBeforeStartValidatorDirective.deadlineBeforeStart(),
        ],
      },
    ),

    default: new FormControl<boolean>(option.defaultSelected ?? false, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    description: new FormControl<string>(option.description ?? ''),
    comment: new FormControl<string>(option.comment ?? ''),
    cost: new FormControl<number | null>(option.cost ?? null, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('[0-9]*')],
    }),
    halfDays: new FormControl<number | null>(option.halfDays, { validators: [Validators.pattern('[0-9]*')] }),
    credits: new FormControl<number | null>(option.credits),
  });
}

export const getSeasonFilters = (seasonsSignal: MaybeSignal<Season[] | undefined>) => {
  const seasons = unsignal(seasonsSignal);
  if (!seasons) return [];
  return (
    seasons
      ?.sort((a, b) => b.year - a.year)
      ?.map((season) => {
        return { displayValue: season.year.toString(), key: season.id };
      }) ?? []
  );
};

export const getNameBasedOnFormat = (format: Format): string => {
  switch (format) {
    case 'InstructorLed':
      return 'training day';
    case 'Certificate':
      return 'Certificate';
    case 'Conference':
      return 'Conference day';
    case 'Event':
      return 'Event';
    default:
      return 'online account';
  }
};
