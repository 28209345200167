import { isSignal } from "@angular/core"

// eslint-disable-next-line @typescript-eslint/ban-types
export function isPlainObject(value: unknown): value is Object {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false
  }

  const prototype = Object.getPrototypeOf(value)
  return prototype === null || prototype === Object.prototype
}

export function cloneDeep<T>(
  value: T,
  customizer?: (val: unknown) => unknown | void,
): T {
  if (customizer) {
    const result = customizer(value)
    if (result !== undefined || isSignal(value)) {
      return result as typeof value
    }
  }

  if (Array.isArray(value)) {
    return value.map((val) => cloneDeep(val, customizer)) as typeof value
  }

  if (typeof value === 'object' && isPlainObject(value)) {
    const entries = Object.entries(value).map(([key, val]) => [
      key,
      cloneDeep(val, customizer),
    ])
    return Object.fromEntries(entries)
  }

  return value
}
