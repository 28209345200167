import { getFormatColor } from '@/shared/models/format';
import { KnowledgeDomain } from '@/shared/models/knowledge-domain';
import { Training } from '@/shared/models/training';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import {
  SortTypesTrainingAdminTable,
  TrainingAdminTableSort,
} from '@/trainings/pages/trainings-admin/trainings-admin.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Format } from '@euricom/angular-training-catalog-api/models';
import { SortEvent } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'tc-training-admin-table',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TableModule,
    CheckboxModule,
    FormsModule,
    FormatDisplayPipe,
    StyleClassModule,
    FormatDatePipe,
  ],
  templateUrl: './training-admin-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAdminTableComponent {
  @ViewChild('trainingAdminTable') trainingAdminTable: Table | undefined;

  @Input() trainings: Training[] = [];
  @Input() selectedTrainings: Training[] = [];
  @Input() tableSort?: TrainingAdminTableSort;

  @Output() sortChanges = new EventEmitter<TrainingAdminTableSort>();
  @Output() clickTraining = new EventEmitter<string>();

  @Output() selectTraining = new EventEmitter<Training[]>();

  readonly breakpoint = useCurrentBreakpoint();
  readonly hideColumnsBreakpoints = ['sm', 'md', 'lg', 'unknown'];

  getDomainsString(domains: KnowledgeDomain[]) {
    const domainNames = domains.map((domain) => domain.name);
    return domainNames.join(' / ');
  }

  onSelect() {
    this.selectTraining.emit(this.selectedTrainings);
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  customSort(event: SortEvent) {
    if (!event.data || !event.field) return;

    event.data.sort((data1, data2) => {
      const value1 = this.getPropertyValue(data1, event.field);
      const value2 = this.getPropertyValue(data2, event.field);

      if (value1 == null && value2 != null) return -1;
      if (value1 != null && value2 == null) return 1;
      if (value1 == null && value2 == null) return 0;

      if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
        if (value1 === value2) return 0;
        return value1 ? -1 : 1;
      }

      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2);
      }

      return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    });

    if (event.order === undefined || event.order === 1) {
      return event.data;
    } else {
      return event.data.reverse();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getPropertyValue(object: any, field = '') {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      value = value[f];
    }
    return value;
  }

  setSort(sortValue: SortTypesTrainingAdminTable) {
    const order = this.trainingAdminTable?.sortOrder ?? -1;
    this.sortChanges.emit({ sort: sortValue, order: order });
  }
  getFormatColor(format: Format) {
    return getFormatColor(format);
  }
}
