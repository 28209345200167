<div class="hidden md:flex justify-between">
  <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input
      type="text"
      id="searchValue"
      class="md:min-w-[20rem] max-w-[8.5rem] md:max-w-xl"
      [formControl]="searchValueControl"
      pInputText
      placeholder="Search"
    />
  </span>
  <div class="flex gap-4">
    <p-button
      pTooltip="Cancel multiple booked bookings all at once"
      tooltipPosition="left"
      size="small"
      [disabled]="bookingsSelected === 0"
      (onClick)="cancelBooking.emit()"
      label="Cancel booking"
    />
    <p-button
      *ngIf="user!.isHumanResources || user!.isAdmin"
      pTooltip="Book multiple approved bookings all at once"
      tooltipPosition="left"
      size="small"
      [disabled]="bookingsSelected === 0"
      (onClick)="bookBooking.emit()"
      label="Book"
    />
    <p-button
      *ngIf="user!.isHumanResources || user!.isAdmin"
      pTooltip="Verify multiple booked bookings all at once"
      tooltipPosition="left"
      size="small"
      [disabled]="bookingsSelected === 0"
      (onClick)="verifyBooking.emit()"
      label="Verify"
    />
    <p-button
      *ngIf="user!.isAdmin"
      pTooltip="Export bookings of the selected season to csv"
      tooltipPosition="left"
      size="small"
      (onClick)="exportBookings.emit()"
      label="Export"
    />
  </div>
</div>
