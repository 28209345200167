<p-table
  #trainingFollowUpTable
  [value]="trainings"
  class="hidden md:block max-w-full min-w-full"
  styleClass="p-datatable-sm"
  [rowHover]="true"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [sortOrder]="tableSort?.order ?? -1"
  [sortField]="tableSort?.sort"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="season.year" (click)="setSort('season.year')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Season</div>
          <p-sortIcon field="season.year" />
        </div>
      </th>
      <th pSortableColumn="name" (click)="setSort('name')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Name</div>
          <p-sortIcon field="name" />
        </div>
      </th>
      <th pSortableColumn="format" (click)="setSort('format')">
        <div class="flex items-center">
          Format
          <p-sortIcon field="format" />
        </div>
      </th>
      <th pSortableColumn="startDate" (click)="setSort('startDate')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Start date</div>
          <p-sortIcon field="startDate" />
        </div>
      </th>
      <th pSortableColumn="endDate" (click)="setSort('endDate')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">End date</div>
          <p-sortIcon field="endDate" />
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="responsibleName"
        (click)="setSort('responsibleName')"
      >
        <div class="flex items-center">
          <div class="max-w-[70%] truncate ...">Responsible</div>
          <p-sortIcon field="responsibleName" />
        </div>
      </th>
      <th pSortableColumn="nrOfBookings" (click)="setSort('nrOfBookings')">
        <div class="flex items-center">
          <div class="max-w-[70%] truncate ...">Nr of bookings</div>
          <p-sortIcon field="nrOfBookings" />
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-training>
    <tr class="cursor-pointer" (click)="clickTraining.emit(training.id)">
      <td>{{ training.season.year }}</td>
      <td>{{ training.name }}</td>
      <td>{{ training.format | fDisplay }}</td>
      <td>
        {{ training.startDate ? (training.startDate | fDate) : '/' }}
      </td>
      <td>
        {{ training.endDate ? (training.endDate | fDate) : '/' }}
      </td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ training.responsibleName }}
      </td>
      <td>
        {{ training.nrOfBookings }}
      </td>
    </tr>
  </ng-template>
</p-table>
<p class="text-center font-semibold mt-3" *ngIf="trainings.length === 0">No trainings found</p>

<p-table
  class="md:hidden"
  [value]="trainings"
  responsiveLayout="stack"
  [breakpoint]="'768px'"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [rowHover]="true"
>
  <ng-template class="md:hidden" pTemplate="body" let-training>
    <tr (click)="clickTraining.emit(training.id)" class="cursor-pointer">
      <td style="padding: 0.3rem; padding-left: 0rem">
        <div class="grid grid-cols-[8rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Season:</span>
          <span class="self-start overflow-x-hidden">{{ training.season.year }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem; padding-left: 0rem">
        <div class="grid grid-cols-[8rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Name:</span>
          <span class="self-start overflow-x-hidden">{{ training.name }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem; padding-left: 0rem">
        <div class="grid grid-cols-[8rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Format:</span>
          <span class="self-start overflow-x-hidden">{{ training.format }}</span>
        </div>
      </td>
      <td style="padding: 0.3rem; padding-left: 0rem">
        <div class="grid grid-cols-[8rem_1fr]">
          <span class="p-column-title font-bold w-1/3">Date:</span>
          <span class="self-start overflow-x-hidden"
            >{{ training.startDate ? (training.startDate | fDate) : '/' }} -
            {{ training.endDate ? (training.endDate | fDate) : '/' }}</span
          >
        </div>
      </td>

      <td style="padding: 0.3rem; padding-left: 0rem">
        <div class="grid grid-cols-[8rem_1fr]">
          <span class="p-column-title font-bold">Nr of bookings:</span>
          <span class="self-start overflow-x-hidden">{{ training.nrOfBookings }}</span>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
