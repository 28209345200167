<div *ngIf="seasonYear" class="h-full overflow-auto flex flex-col">
  <ng-container *ngFor="let month of months; let i = index">
    <div class="h-1/4">
      <h1 class="font-semibold text-xl">{{ month }}</h1>
      <div class="flex p-3 w-fit items-center">
        <ng-container *ngIf="getTrainingsPerQuarter(i).length === 0">
          <div class="m-3 cursor-pointer rounded-lg p-3 w-max">
            <div class="flex flex-col items-center px-1 opacity font-medium">
              <div class="text-lg">Nothing found in this quarter.</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let training of getTrainingsPerQuarter(i); let last = last">
          <div
            (click)="
              isTrainingBooked(training.id) ? navigateBookingDetails(training) : navigateTrainingDetails(training)
            "
            class="m-3 cursor-pointer rounded-lg p-3 shadow-md border border-secondary w-max max-w-[15rem]"
          >
            <div class="flex flex-col items-center px-1 opacity font-medium">
              <div class="text-lg text-center line-clamp-2" [class.text-accent]="isTrainingBooked(training.id)">
                {{ training.name }}
              </div>
              <p>
                {{ training.startDate | date : 'd MMM' }}
                <span *ngIf="(training.startDate | date : 'd MMM') !== (training.endDate | date : 'd MMM')">
                  - {{ training.endDate | date : 'd MMM' }}
                </span>
              </p>
            </div>
          </div>
          <p-divider *ngIf="!last" />
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
