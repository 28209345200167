import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';

@Component({
  standalone: true,
  imports: [DividerModule, ButtonModule, CheckboxModule, NgIf, InfoTooltipComponent],
  selector: 'tc-training-delete',
  templateUrl: './training-delete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingDeleteComponent {
  currentUser = useAuth().user();
  readonly formControl = new FormControl<boolean>(false);

  @Output() public delete = new EventEmitter();

  constructor(private _confirmationService: ConfirmationService) {}

  deleteTraining() {
    this.delete.emit(this.formControl.value);
  }

  onConfirmDelete() {
    this._confirmationService.confirm({
      message: `Are you sure you want to delete this training?`,
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteTraining(),
    });
  }
}
