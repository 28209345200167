import { ToastComponent } from '@/shared/components/toast/toast.component';
import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { RouterModule } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { loadSpace } from '@usersnap/browser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { VERSION } from '../../../../version';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, ConfirmDialogModule, ToastComponent],
  selector: 'tc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'training-catalog';
  user = useAuth().user;

  constructor() {
    const { version, tag, hash } = VERSION.gitInfo;
    console.log(`Application version is: version (from package.json)=${version}, git-tag=${tag}, git-hash=${hash}`);

    // UserSnap is in conflict with Trusted Types, so we only enable it in production
    effect(() => {
      if (process.env['NODE_ENV'] === 'production') {
        const spaceApiKey = '42bec283-2a03-4c88-88cd-440bb62f9d3e';
        loadSpace(spaceApiKey).then((api) => {
          api.init({
            user: {
              userId: this.user()?.name ?? '',
              email: this.user()?.email ?? '',
            },
          });
        });
      }
    });
  }
}
