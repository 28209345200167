import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, fromEvent, map, startWith } from 'rxjs';

export type Breakpoints = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'unknown';

/**
 * Get the active breakpoint name based on the current window width.
 *
 * Usage:
 * ```
 * const breakpoint = getCurrentBreakpoint();
 * const desktopBreakpoints: string[] = ['sm', 'md', 'lg', 'xl'];
 * if (desktopBreakpoints.includes(breakpoint)) {
 *   // ...
 * }
 *
 * // using from event
 * fromEvent(window, 'resize')
 *    .pipe(
 *       debounceTime(200),
 *       mapTo(getCurrentBreakpoint())
 *    )
 *    .subscribe((breakpoint) => console.log('Window resizing: ', breakpoint));
 * ```
 * @returns {Breakpoints} The current breakpoint name (sm, md, lg, xl or 2xl).
 */
const getCurrentBreakpoint = (): Breakpoints => {
  const breakpointUnknown = 'unknown';
  const breakpointSM: string | null = document.getElementById('breakpoint-sm')?.offsetParent === null ? null : 'sm';
  const breakpointMD: string | null = document.getElementById('breakpoint-md')?.offsetParent === null ? null : 'md';
  const breakpointLG: string | null = document.getElementById('breakpoint-lg')?.offsetParent === null ? null : 'lg';
  const breakpointXL: string | null = document.getElementById('breakpoint-xl')?.offsetParent === null ? null : 'xl';
  const breakpoint2XL: string | null = document.getElementById('breakpoint-2xl')?.offsetParent === null ? null : '2xl';
  const breakpoint = breakpointSM ?? breakpointMD ?? breakpointLG ?? breakpointXL ?? breakpoint2XL ?? breakpointUnknown;
  return breakpoint as Breakpoints;
};

export default getCurrentBreakpoint;

export function useCurrentBreakpoint() {
  return toSignal(
    fromEvent(window, 'resize').pipe(
      startWith(null),
      debounceTime(50),
      map(() => getCurrentBreakpoint()),
    ),
  );
}
