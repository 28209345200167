import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { Booking } from '@/shared/models/booking';
import { Holiday } from '@/shared/models/holiday';
import { Season } from '@/shared/models/season';
import { Training } from '@/shared/models/training';
import { TrainingCalendarMonthComponent } from '@/trainings/components/training-calendar-month/training-calendar-month.component';
import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';

@Component({
  selector: 'tc-training-calendar-year',
  standalone: true,
  imports: [CommonModule, TrainingCalendarMonthComponent, LoaderComponent],
  templateUrl: './training-calendar-year.component.html',
})
export class TrainingCalendarYearComponent {
  readonly months = Array.from({ length: 12 }, (e, i) => {
    return { displayValue: new Date(0, i).toLocaleDateString('en', { month: 'long' }), key: i };
  });

  private _trainings = signal<Training[]>([]);
  private _bookings = signal<Booking[]>([]);
  private _publicHolidays = signal<Holiday[]>([]);
  private _schoolHolidays = signal<Holiday[]>([]);
  private _seasonYear = signal<Season | undefined>(undefined);

  @Input() set trainings(value: Training[]) {
    this._trainings.set(value);
  }

  get trainings() {
    return this._trainings();
  }

  @Input() set bookings(value: Booking[]) {
    this._bookings.set(value);
  }

  get bookings() {
    return this._bookings();
  }

  @Input() set publicHolidays(value: Holiday[]) {
    this._publicHolidays.set(value);
  }

  get publicHolidays() {
    return this._publicHolidays();
  }

  @Input() set schoolHolidays(value: Holiday[]) {
    this._schoolHolidays.set(value);
  }

  get schoolHolidays() {
    return this._schoolHolidays();
  }

  @Input() set season(value: Season | undefined) {
    this._seasonYear.set(value);
  }
  get seasonYear() {
    return this._seasonYear();
  }

  @Output() getIcal = new EventEmitter<Booking>();

  readonly firstDayOfEveryMonth = computed(() => {
    const currentSeason = this.seasonYear;
    if (!currentSeason) return [];

    return this.months.map((month) => {
      return new Date(currentSeason.year, month.key, 1);
    });
  });
}
