/* tslint:disable */
/* eslint-disable */
export enum BookingStatus {
  Added = 'Added',
  Requested = 'Requested',
  Approved = 'Approved',
  PendingBooking = 'PendingBooking',
  Booked = 'Booked',
  Verified = 'Verified',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled'
}
