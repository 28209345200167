import { Booking } from '@/shared/models/booking';
import { Season } from '@/shared/models/season';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { format } from 'date-fns';

export const getSeasonFilters = (seasonsSignal: MaybeSignal<Season[] | undefined>) => {
  const seasons = unsignal(seasonsSignal);
  if (!seasons) return [];
  return (
    seasons
      ?.sort((a, b) => b.year - a.year)
      ?.map((season) => {
        return { displayValue: season.year.toString(), key: season.id };
      }) ?? []
  );
};

export const getCancelForm = () => {
  return new FormGroup({
    comment: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace(), Validators.maxLength(255)],
    }),
    refund: new FormControl<boolean>(false, { nonNullable: true }),
  });
};

function filterOnStatus(bookingStatus: string, selectedStatuses: string[]): boolean {
  if (selectedStatuses.length === 0) return true;
  return selectedStatuses.includes(bookingStatus);
}

function filterOnRequester(requester: string, selectedRequester: string): boolean {
  if (selectedRequester === '' || selectedRequester === 'none') return true;
  return selectedRequester === requester;
}

function filterOnPm(approvedBy: string | null | undefined, selectedPms: string[]): boolean {
  if (selectedPms.length === 0) return true;
  return selectedPms.includes(approvedBy ?? '');
}

function filterOnSeason(seasons: string, selectedSeasons: string, activeSeasons: string[] | undefined): boolean {
  if (selectedSeasons === '0') {
    return activeSeasons?.includes(seasons) ?? false;
  }

  return selectedSeasons.length === 0 || selectedSeasons.includes(seasons);
}

function filterOnSearch(booking: Booking, search: string[]) {
  return search.every((searchTerm) => concatBooking(booking).includes(searchTerm.toLowerCase()));
}

function concatBooking(booking: Booking): string {
  const season = booking.training.season.year.toString();
  const status = booking.status;
  const title = booking.training.name;
  const requester = booking.requesterName;
  const startDate = format(booking.training.startDate ?? 0, 'dd/MM/yyyy');
  const pm = booking.practiceManagerName;
  const responsible = booking.training.responsibleName;
  const deadline = format(booking.training.deadline ?? 0, 'dd/MM/yyyy');
  return [season, status, title, requester, startDate, pm, responsible, deadline].join(' ').toLowerCase();
}

export const filterBookings = (
  allBookings: MaybeSignal<Booking[] | undefined>,
  searchExpression: MaybeSignal<string>,
  statuses: MaybeSignal<string[]>,
  pms: MaybeSignal<string[]>,
  requester: MaybeSignal<string>,
  season: MaybeSignal<string>,
  activeSeasons: string[] | undefined,
): Booking[] => {
  const searchValue = unsignal(searchExpression);
  const searchTerms = searchValue ? searchValue.split(' ') : [];
  const selectedStatuses = unsignal(statuses) ?? [];
  const selectedPms = unsignal(pms) ?? [];
  const selectedRequester = unsignal(requester) ?? '';
  const bookings = unsignal(allBookings) ?? [];
  const selectedSeason = unsignal(season) ?? '';
  return (
    bookings
      ?.filter((booking) => {
        return (
          filterOnStatus(booking.status, selectedStatuses) &&
          filterOnPm(booking.practiceManagerEmail, selectedPms) &&
          filterOnSearch(booking, searchTerms) &&
          filterOnRequester(booking.requesterEmail, selectedRequester) &&
          filterOnSeason(booking.training.season.id, selectedSeason, activeSeasons)
        );
      })
      .sort((a, b) => b.training.season.year - a.training.season.year) ?? []
  );
};
