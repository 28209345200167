import { Participant } from '@/shared/models/participant';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tc-participants-list',
  standalone: true,
  imports: [CommonModule, DividerModule, TooltipModule, ButtonModule],
  templateUrl: './participants-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantsListComponent {
  public showAllFlag = false;

  @Input() participants = Array<Participant>();

  onSetShowAllFlag() {
    this.showAllFlag = true;
  }
}
