import { Injectable, Provider } from '@angular/core';
import { ConfigurationService } from './configuration.service';

export type Configurations = Array<{
  provide: unknown;
  key: string;
}>;

export function buildConfigurationproviders(
  confirgurations: Configurations
): Provider[] {
  return confirgurations.map((confirguration) => ({
    provide: confirguration.provide,
    useFactory: (ConfigurationService: ConfigurationService) =>
      ConfigurationService.getSection(confirguration.key),
    deps: [ConfigurationService],
  }));
}

@Injectable()
export class Configuration {
  constructor(private configurationService: ConfigurationService) {}

  public getSection<T>(key: string): T {
    return this.configurationService.getSection<T>(key);
  }
}
