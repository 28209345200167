/* eslint-disable @typescript-eslint/no-explicit-any */
import { Signal, computed, effect, signal } from '@angular/core';
import { type FetchQueryOptions, type QueryFunction, type QueryKey } from '@tanstack/query-core';
import { MaybeSignal, cloneDeepUnsignal, unsignal } from '../signals';
import { useQueryClient } from './useQueryClient';

export type UseQueryReturnType<TResult, TError = Error> = {
  refetch: () => void;
  data: Signal<TResult | undefined>;
  isLoading: Signal<boolean>;
  error: Signal<TError | undefined>;
};

export interface QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> extends FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  /**
   * This option can be used to transform or select a part of the data returned by the query function.
   */
  select?: (data: unknown) => TData;

  /**
   * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
   * To refetch the query, use the `refetch` method returned from the `useQuery` instance.
   * Defaults to `true`.
   */
  enabled?: MaybeSignal<boolean>;

  /**
   * This callback will fire any time the query successfully fetches new data.
   */
  onSuccess?: (data: TData) => void;

  /**
   * This callback will fire if the query encounters an error and will be passed the error.
   */
  onError?: (err: TError) => void;

  /**
   * This callback will fire any time the query is either successfully fetched or errors and be passed either the data or error.
   */
  onSettled?: (data: TData | undefined, error: TError | null) => void;
}

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>>): UseQueryReturnType<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: MaybeSignal<TQueryKey>,
  options?: MaybeSignal<Omit<QueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'>>,
): UseQueryReturnType<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: MaybeSignal<TQueryKey>,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: MaybeSignal<Omit<QueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>>,
): UseQueryReturnType<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  arg1: MaybeSignal<TQueryKey> | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>>,
  arg2:
    | MaybeSignal<QueryFunction<TQueryFnData, TQueryKey>>
    | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
  arg3: MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
): UseQueryReturnType<TData, TError> {
  return useBaseQuery(arg1, arg2, arg3);
}

function useBaseQuery<TQueryFnData, TError, TData, TQueryKey extends QueryKey>(
  arg1: MaybeSignal<TQueryKey> | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>>,
  arg2:
    | MaybeSignal<QueryFunction<TQueryFnData, TQueryKey>>
    | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
  arg3: MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
) {
  const optionsSignal = computed(() => parseQueryArgs(arg1, arg2, arg3));
  const queryClient = useQueryClient();

  const data = signal<TData | undefined>(undefined);
  const isLoading = signal<boolean>(false);
  const error = signal<TError | undefined>(undefined);
  const refetchTrigger = signal(0);

  const refetch = () => {
    refetchTrigger.update((prev) => prev + 1);
  };

  effect(
    async () => {
      refetchTrigger();
      const options = optionsSignal();
      //console.log('effect', options);

      // skip query when not enabled
      if (unsignal(options.enabled) === false) {
        return;
      }

      isLoading.set(true);
      queryClient
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .fetchQuery(options.queryKey!, options.queryFn!, {
          ...options,
        })
        .then((result) => {
          let selectedData = result;
          if (options.select) {
            selectedData = options?.select?.(result);
          }
          data.set(selectedData);
          try {
            options?.onSuccess?.(result);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((reason) => {
          error.set(reason);
          try {
            options.onError?.(reason);
          } catch (e) {
            console.error(e);
          }
        })
        .finally(() => {
          isLoading.set(false);
          try {
            options.onSettled?.(data(), error() ?? null);
          } catch (e) {
            console.error(e);
          }
        });
    },
    { allowSignalWrites: true },
  );

  return {
    refetch,
    data,
    isLoading,
    error,
  };
}

export function isQueryKey(value: unknown): value is QueryKey {
  return Array.isArray(value);
}

export function parseQueryArgs<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  arg1: MaybeSignal<TQueryKey> | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>>,
  arg2:
    | MaybeSignal<QueryFunction<TQueryFnData, TQueryKey>>
    | MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
  arg3: MaybeSignal<QueryOptions<TQueryFnData, TError, TData, TQueryKey>> = {},
) {
  const plainArg1 = unsignal(arg1);
  const plainArg2 = unsignal(arg2);
  const plainArg3 = unsignal(arg3);

  let options = plainArg1;

  if (!isQueryKey(plainArg1)) {
    options = plainArg1;
  } else if (typeof plainArg2 === 'function') {
    options = { ...plainArg3, queryKey: plainArg1, queryFn: plainArg2 };
  } else {
    options = { ...plainArg2, queryKey: plainArg1 };
  }

  return cloneDeepUnsignal(options);
}
