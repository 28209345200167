import { PageTitleService } from '@/shared/services/page-title.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  imports: [ButtonModule],
  selector: 'tc-no-internet-connection',
  templateUrl: './no-internet-connection.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoInternetConnectionComponent implements OnInit {
  constructor(private _pageTitleService: PageTitleService, public _router: Router) {}

  ngOnInit() {
    this._pageTitleService.setPageTitle('No internet connection');
  }
}
