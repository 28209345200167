import { Inject, Injectable } from "@angular/core";
import { FetchQueryOptions, InvalidateOptions, InvalidateQueryFilters, QueryClient, QueryClientConfig, QueryKey } from "@tanstack/query-core";

@Injectable({
  providedIn: 'root',
})
export class QueryClientService {
  private queryClient: QueryClient;

  constructor(@Inject(String) config: QueryClientConfig) {
    this.queryClient = new QueryClient(config);
  }

  /**
   * The invalidateQueries method can be used to invalidate and refetch single or multiple queries in the cache based on their query keys.
   * https://tanstack.com/query/latest/docs/vue/reference/QueryClient#queryclientinvalidatequeries
   * @param queryKey
   * @param filters
   * @param options
   * @returns
   */
  invalidateQueries<TPageData = unknown>(
    queryKey?: QueryKey,
    filters?: InvalidateQueryFilters<TPageData>,
    options?: InvalidateOptions,
  ) {
    return this.queryClient.invalidateQueries(queryKey, filters, options);
  }

  /**
   * The clear method clears all connected caches.
   */
  clear(): void {
    this.queryClient.clear();
  }

  /**
   * prefetchQuery is an asynchronous method that can be used to prefetch a query before it is needed or rendered with useQuery and friends. The method works the same as fetchQuery except that it will not throw or return any data.
   * https://tanstack.com/query/latest/docs/vue/reference/QueryClient#queryclientprefetchquery
   * @param options
   * @returns
   */
  prefetchQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  ) {
    return this.queryClient.prefetchQuery(options);
  }

  /**
   * Get the queryClient for more low level access
   * @returns
   */
  getQueryClient(): QueryClient {
    return this.queryClient;
  }
}

export const provideQueryClientService = (config: QueryClientConfig = {}) => {
  return {
    provide: QueryClientService,
    useFactory: () => {
      return new QueryClientService(config);
    },
  };
};
