<tc-loader *ngIf="trainings.isLoading()" />
<div class="flex flex-col lg:flex-row gap-4 p-4 min-h-full w-full" *ngIf="!trainings.isLoading()">
  <div class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4" *ngIf="formats().length > 0">
    <tc-filter-facet
      type="multiple"
      [selected]="formatFilters()"
      (selectedChange)="formatFilters.set($event)"
      [options]="formats()"
      header="Format"
      class="w-full"
    />
  </div>

  <p-card class="w-full overflow-hidden">
    <tc-training-follow-up-filter-header [searchValue]="searchValue()" (searchValueChange)="searchValue.set($event)" />
    <p-divider />
    <tc-training-follow-up-table
      [trainings]="filteredTrainings()"
      (clickTraining)="onClickTraining($event)"
      (sortChanges)="onSortChanges($event.sort, $event.order)"
      [tableSort]="sort()"
    ></tc-training-follow-up-table>
  </p-card>
</div>
