<div class="p-4">
  <div class="hidden md:block">
    <tc-loader *ngIf="reviews.isLoading() && !reviews.data()"/>
    <p-card class="w-full" *ngIf="reviews.data()">
      <p-table
        [value]="reviews.data()!"
        sortField="year"
        sortMode="single"
        [scrollable]="true"
        [sortOrder]="-1"
        rowGroupMode="subheader"
        groupRowsBy="year"
        scrollHeight="75vh"
        [styleClass]="'p-datatable-sm'"
        [rowHover]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Year</th>
            <th style="width: 10vw">Format</th>
            <th style="width: 10vw">Booking</th>
            <th>Review comment</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-review>
          <tr pRowGroupHeader style="top: 39px">
            <td colspan="9">
              <div class="w-full flex gap-3 items-center py-2 font-bold">
                <p>{{ review.year }}:</p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
          <tr (click)="onClickReview(review.id)" class="cursor-pointer">
            <td>{{ review.year }}</td>
            <td>{{ review.format | fDisplay }}</td>
            <td>{{ review.trainingName }}</td>
            <td>{{ review.message }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="reviews.data()?.length === 0 && !reviews.isLoading()">
        <p class="text-center font-semibold mt-3">You have no reviews at the moment</p>
      </div>
    </p-card>
  </div>
</div>

<div class="p-4 pt-0">
  <div class="md:hidden">
    <tc-loader *ngIf="reviews.isLoading() && !reviews.data()"/>
    <p-card class="w-full" *ngIf="reviews.data()">
      <p-table [value]="reviews.data()!" responsiveLayout="stack" [breakpoint]="'1000px'">
        <ng-template pTemplate="body" let-review>
          <tr (click)="onClickReview(review.id)">
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold w-1/3">Year:</span>
                <span class="self-start">{{ review.year }}</span>
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Training name:</span>
                {{ review.trainingName }}
              </div>
            </td>

            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Review comment:</span>
                {{ review.message }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="reviews.data()!.length === 0">
        <p class="text-center font-semibold mt-3">You have no reviews at the moment</p>
      </div>
    </p-card>
  </div>
</div>
