import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { standInKeys, StandInService } from '@/shared/services/stand-in.service';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, signal, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { Table, TableModule } from 'primeng/table';

type SortTypes = 'absenteeName' | 'replacementName' | 'startDate' | 'endDate';

@Component({
  standalone: true,
  imports: [LoaderComponent, CardModule, ButtonModule, TableModule, FormatDatePipe, RouterLink, CommonModule],
  providers: [StandInService, SearchParamsService],
  selector: 'tc-admin-stand-ins',
  templateUrl: './stand-ins-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminStandInsComponent implements OnInit {
  readonly standIns = useQuery(standInKeys.all, this._standInsService.getStandInsAsync);
  readonly sortedStandIns = computed(() =>
    this.standIns.data()?.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()),
  );
  @ViewChild('standInsTable') standInsTable: Table | undefined;

  readonly searchParams = this._searchParamsService.getSearchParams<{
    sort: SortTypes | undefined;
    order: string | undefined;
  }>();
  readonly sort = signal<SortTypes | undefined>(this.searchParams.sort);
  readonly sortOrder = parseInt(this.searchParams.order ?? '-1');

  constructor(
    private _pageTitleService: PageTitleService,
    private _standInsService: StandInService,
    private _router: Router,
    private _searchParamsService: SearchParamsService,
  ) {
    effect(() => {
      const order = this.standInsTable?.sortOrder;
      const queryParams = {
        sort: this.sort(),
        order: order ?? this.sortOrder.toString(),
      };
      if (this.sort()) this._searchParamsService.setQueryParams(queryParams);
    });
  }

  setSort(sortValue: SortTypes) {
    this.sort.set(undefined);
    this.sort.set(sortValue);
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Stand-ins admin');
  }

  onClickRow(standInId: number) {
    return this._router.navigate(['/follow-up/stand-ins/', standInId]);
  }
}
