import { OptionArrayGroupItem } from '@/shared/models/option-array-group-item';
import { SeasonDetail } from '@/shared/models/season-detail';
import { calculateCredits } from '@/shared/services/credit.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'tc-training-add-option',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    CardModule,
    InputTextareaModule,
    CheckboxModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
  ],
  templateUrl: './training-add-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAddOptionComponent {
  @Input() form = new FormGroup({} as OptionArrayGroupItem);

  //the option needs te be refreshed after submitting, so it can show the errors
  @Input() saved = 0;
  @Input() index = 0;
  @Input() season?: SeasonDetail | null;
  @Input() deleteOptionIsDisabled = false;

  @Output() deleteOption = new EventEmitter<void>();
  @Output() copyOption = new EventEmitter<void>();
  @Output() startDateValueChange = new EventEmitter<Date>();

  onCalculateCredits() {
    const cost = this.form.controls.cost.value ?? 0;
    const halfDays = this.form.controls.halfDays.value ?? 0;
    const season = this.season;

    if (!season) return;

    this.form.controls.credits.setValue(calculateCredits(cost, halfDays, season.dayCost, season.creditMultiplier));
  }

  copyOptionIsDisabled() {
    const controls = { ...this.form.controls, ...this.form.controls.date.controls, date: null, default: null };
    return Object.values(controls).find((control) => !!control?.value) === undefined;
  }

  onDeleteOption() {
    this.deleteOption.emit();
  }

  onCopyOption() {
    this.copyOption.emit();
  }

  onSetSeasonDates() {
    this.form.controls.date.controls.startDate.setValue(this.season?.startDate ?? null);
    this.form.controls.date.controls.endDate.setValue(this.season?.endDate ?? null);
  }
}
