import { TrainingFollowUp } from '@/shared/models/training-follow-up';
import { formatDisplay } from '@/shared/pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';
import { format } from 'date-fns';

function filterOnFormat(format: string, selectedFormats: string[]): boolean {
  if (selectedFormats.length === 0) return true;
  return selectedFormats.includes(format);
}

function filterOnSearch(training: TrainingFollowUp, search: string[]) {
  return search.every((searchTerm) => concatTraining(training).includes(searchTerm.toLowerCase()));
}

export const concatTraining = (training: TrainingFollowUp) => {
  const name = training.name;
  const endDate = format(training.endDate ?? 0, 'dd/MM/yy');
  const startDate = format(training.startDate ?? 0, 'dd/MM/yy');
  const trainingFormat = training.format;
  const trainingFormatDisplay = formatDisplay(training.format);
  return [name, endDate, startDate, trainingFormat, trainingFormatDisplay].join(' ').toLowerCase();
};

export const filterTrainings = (
  allTrainings: MaybeSignal<TrainingFollowUp[] | undefined>,
  searchExpression: MaybeSignal<string>,
  formats: MaybeSignal<string[]>,
): TrainingFollowUp[] => {
  const searchValue = unsignal(searchExpression);
  const searchTerms = searchValue ? searchValue.split(' ') : [];
  const selectedFormats = unsignal(formats) ?? [];
  const trainings = unsignal(allTrainings) ?? [];
  return trainings?.filter((training) => {
    return filterOnFormat(training.format, selectedFormats) && filterOnSearch(training, searchTerms);
  });
};
