import { AppComponent } from '@/app.component';
import { appConfig } from '@/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

if (process.env['NODE_ENV'] === 'development') {
  if (window.trustedTypes && window.trustedTypes.createPolicy) {
    // required for 3rd party libraries that use innerHTML
    // see also index.html meta Content-Security-Policy (only for development)
    // required to handle PrimeNG Toast when Trusted Types are enabled
    window.trustedTypes.createPolicy('default', {
      createHTML(input: string) {
        return DOMPurify.sanitize(input);
      },
    });
  }
}

bootstrapApplication(AppComponent, appConfig)
  .then(() => {
    console.log('Trusted Types enabled, see Content-Security-Policy meta tag in index.html');
  })
  .catch((err) => console.error(err));
