import {
  CollectionTableSort,
  SortTypesCollectionTable,
} from '@/collections/pages/collections-admin/collections-admin.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { TrainingCollection } from '@/shared/models/training-collection';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { Table, TableModule } from 'primeng/table';

@Component({
  selector: 'tc-collection-admin-table',
  standalone: true,
  imports: [CommonModule, ButtonModule, CardModule, FormatDisplayPipe, LoaderComponent, SharedModule, TableModule],
  templateUrl: './collection-admin-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionAdminTableComponent {
  @ViewChild('collectionTable') collectionTable: Table | undefined;

  @Input() collections: TrainingCollection[] = [];
  @Input() tableSort?: CollectionTableSort;

  @Output() sortChanges = new EventEmitter<CollectionTableSort>();
  @Output() clickCollection = new EventEmitter<string>();
  @Output() clickNewTraining = new EventEmitter<string>();

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  setSort(sortValue: SortTypesCollectionTable) {
    const order = this.collectionTable?.sortOrder ?? -1;
    this.sortChanges.emit({ sort: sortValue, order: order });
  }
}
