import { KnowledgeDomain } from '@/shared/models/knowledge-domain';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { KnowledgeDomainDto } from '@euricom/angular-training-catalog-api/models';
import { KnowledgeDomainsService as RootKnowledgeDomainsService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';

export function mapKnowledgeDomain(dto: KnowledgeDomainDto): KnowledgeDomain {
  return {
    ...dto,
  };
}

export const domainKeys = {
  // all domains
  all: ['domains'] as const,

  //specific domain
  byId: (id: string | null) => [...domainKeys.all, id] as const,
};

@Injectable()
export class KnowledgeDomainsService extends RootKnowledgeDomainsService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  async getAllKnowledgeDomainsAsync(): Promise<KnowledgeDomain[]> {
    const data = await firstValueFrom(this.getAllKnowledgeDomains());
    return data.map(mapKnowledgeDomain);
  }
}
