import { StandIn } from '@/shared/models/stand-in';
import { StandInService } from '@/shared/services/stand-in.service';
import { userKeys, UsersService } from '@/shared/services/user.service';
import { useQuery } from '@/shared/utils/query';
import { getStandInForm } from '@/stand-ins/components/stand-in-edit/helpers';
import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StandInAddCommand, StandInUpdateCommand } from '@euricom/angular-training-catalog-api/models';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'tc-stand-in-edit',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
  ],
  providers: [StandInService, UsersService],
  templateUrl: './stand-in-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandInEditComponent {
  @Output() upsert = new EventEmitter<StandInAddCommand | StandInUpdateCommand>();
  @Input() loading = false;

  private readonly _standIn = signal<StandIn | undefined>(undefined);
  @Input() set standIn(standIn: StandIn | undefined) {
    this._standIn.set(standIn);
  }

  readonly form = getStandInForm();

  readonly users = useQuery(userKeys.all, () => {
    return this._usersService.getUsersAsync();
  });
  readonly mappedUsers = computed(
    () =>
      this.users.data()?.map((user) => ({
        name: user.displayValue,
        email: user.key,
      })) ?? [],
  );

  constructor(private location: Location, private _usersService: UsersService) {
    effect(
      () => {
        const standIn = this._standIn();
        if (standIn) {
          this.form.setValue({
            people: {
              absentee: { name: standIn.absenteeName, email: standIn.absenteeEmail },
              replacement: { name: standIn.replacementName, email: standIn.replacementEmail },
            },
            date: {
              startDate: standIn.startDate,
              endDate: standIn.endDate,
            },
          });
        }
      },
      { allowSignalWrites: true },
    );
  }

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => control.markAsDirty());
    if (this.form.invalid) return;

    const extractDateString = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')}`;

    const formValues = this.form.value;
    const startDate = extractDateString(formValues.date!.startDate!);
    const endDate = extractDateString(formValues.date!.endDate!);
    const payload: StandInUpdateCommand = {
      absentee: formValues.people!.absentee!,
      replacement: formValues.people!.replacement!,
      startDate,
      endDate,
    };
    if (this._standIn() != null) payload.id = this._standIn()!.id;
    this.upsert.emit(payload);
  }

  onCancel() {
    this.location.back();
  }
}
