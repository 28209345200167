<div class="flex justify-between">
  <h1 class="text-2xl">Delete stand-in</h1>
</div>
<p-divider />
<div class="w-full mt-4">
  <p-button
    label="Delete"
    (onClick)="onDelete()"
    size="small"
    [outlined]="true"
    severity="danger"
  />
</div>
