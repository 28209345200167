import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcStartBeforeEnd]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotActiveWhenNoStartEndDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class NotActiveWhenNoStartEndDirective {
  static NotActiveWhenNoStartEnd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const options: any | null = control.get('trainingOptions');
      const enabled = control.get('trainingIsEnabled');

      if (!options || !enabled) return null;

      const isTrainingWithoutStartEnd = options.controls.some((option: AbstractControl) => {
        return !option.value.date.startDate || !option.value.date.endDate;
      });

      if (isTrainingWithoutStartEnd && enabled?.value.value !== undefined) {
        return { notActiveWhenNoStartEnd: true };
      }

      return null;
    };
  }
}
