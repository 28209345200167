import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, signal, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';

type SortTypes =
  | 'year'
  | 'startDate'
  | 'endDate'
  | 'creditMultiplier'
  | 'nrOfTrainings'
  | 'nrOfBookings'
  | 'totalBookingCredits';

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    FormatDisplayPipe,
    LoaderComponent,
    TableModule,
    CommonModule,
    StyleClassModule,
    FormatDatePipe,
  ],
  providers: [SeasonsService, SearchParamsService],
  selector: 'tc-admin-seasons',
  templateUrl: './seasons-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSeasonsComponent implements OnInit {
  readonly seasons = useQuery(seasonKeys.allAdmin, () => {
    return this._seasonsService.getSeasonsAdminAsync();
  });
  readonly sortedSeasons = computed(() => this.seasons.data()?.sort((a, b) => b.year - a.year));
  @ViewChild('seasonTable') seasonTable: Table | undefined;

  readonly searchParams = this._searchParamService.getSearchParams<{
    sort: SortTypes | undefined;
    order: string | undefined;
  }>();
  readonly sort = signal<SortTypes | undefined>(this.searchParams.sort);
  readonly sortOrder = parseInt(this.searchParams.order ?? '-1');

  constructor(
    private _pageTitleService: PageTitleService,
    private _seasonsService: SeasonsService,
    private _router: Router,
    private _searchParamService: SearchParamsService,
  ) {
    effect(() => {
      const order = this.seasonTable?.sortOrder;
      const queryParams = {
        sort: this.sort(),
        order: order ?? this.sortOrder.toString(),
      };
      if (this.sort()) this._searchParamService.setQueryParams(queryParams);
    });
  }

  setSort(sortValue: SortTypes) {
    this.sort.set(undefined);
    this.sort.set(sortValue);
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Seasons admin');
  }

  onClickAddSeason() {
    this._router.navigate(['/admin/seasons/add']);
  }

  onClickSeason(seasonId: string) {
    this._router.navigate(['/admin/seasons/', seasonId]);
  }
}
