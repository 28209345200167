import { OptionArrayGroupItem } from '@/shared/models/option-array-group-item';
import { SeasonDetail } from '@/shared/models/season-detail';
import { calculateCredits } from '@/shared/services/credit.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'tc-training-add-option-simple',
  standalone: true,
  imports: [CommonModule, CalendarModule, DividerModule, InputTextModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './training-add-option-simple.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAddOptionSimpleComponent {
  @Input() form = new FormGroup({} as OptionArrayGroupItem);

  @Input() season?: SeasonDetail | null;

  //the option needs te be refreshed after submitting, so it can show the errors
  @Input() saved = 0;

  @Output() startDateValueChange = new EventEmitter<Date>();

  cost: WritableSignal<number | null> = signal(null);
  halfDays: WritableSignal<number | null> = signal(null);

  onCalculateCredits() {
    const cost = this.form.controls.cost.value ?? 0;
    const halfDays = this.form.controls.halfDays.value ?? 0;
    const season = this.season;

    if (!season) return;

    this.form.controls.credits.setValue(calculateCredits(cost, halfDays, season.dayCost, season.creditMultiplier));
  }
}
