import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, catchError } from 'rxjs';
import { ToastService } from '../services/toast.service';

export function loggerInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const toastService = inject(ToastService);
  const router = inject(Router);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const detail = JSON.stringify(error.error);
      let errorObject = error.error;

      if (typeof error.error !== 'object') {
        errorObject = JSON.parse(error.error);
      }

      switch (error.status) {
        case 404:
          throw error;
        case 0:
          toastService.closeAllToasts();
          toastService.error(`No internet connection. (${error.status})`, {
            data: detail,
            sticky: true,
          });
          router.navigate(['/0']);
          break;
        case 409:
          toastService.warning(`Something went wrong: ${errorObject.title}`);
          break;
        default:
          toastService.error(`Unexpected error! Please try again later. (${error.status})`, {
            data: detail,
            sticky: true,
          });
      }
      sendExceptionToSentry(error, { url: req.url, method: req.method });
      return next(req);
    }),
  );
}
export function sendExceptionToSentry(error: Error, context: { url: string; method: string }) {
  Sentry.captureException(error, {
    contexts: { http: { url: context.url, method: context.method } },
  });
}
