import { Season } from '@/shared/models/season';
import { Training } from '@/shared/models/training';
import { formatDisplay } from '@/shared/pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';
import { format } from 'date-fns';

export const filterAdminTrainings = (
  trainings: MaybeSignal<Training[] | undefined>,
  searchExpression: MaybeSignal<string>,
  states: MaybeSignal<(string | number)[]>,
  formats: MaybeSignal<(string | number)[]>,
  responsibles: MaybeSignal<(string | number)[]>,
  domains: MaybeSignal<(string | number)[]>,
  collections: MaybeSignal<(string | number)[]>,
) => {
  return (unsignal(trainings) ?? [])
    .filter((item) => {
      const domainFilter = unsignal(domains) || [];
      return domainFilter.length === 0 || item.domains.some((domain) => domainFilter.includes(domain.id));
    })
    .filter((item) => {
      const formatFilter = unsignal(formats) || [];
      return formatFilter.length === 0 || formatFilter.includes(item.format);
    })
    .filter((item) => {
      const stateFilter = unsignal(states) || [];
      if (item.isEnabled === undefined && stateFilter.includes('2')) return true;
      return (
        stateFilter.length === 0 ||
        stateFilter.includes(Number(item.isEnabled)) ||
        stateFilter.includes(Number(item.isEnabled).toString())
      );
    })
    .filter((item) => {
      const responsibleFilter = unsignal(responsibles) || [];
      return responsibleFilter.length === 0 || responsibleFilter.includes(item.responsibleName ?? '');
    })
    .filter((item) => {
      const collectionFilter = unsignal(collections) || [];
      return collectionFilter.length === 0 || collectionFilter.includes(item.collectionId ?? '');
    })
    .filter((item) => {
      const searchValue = unsignal(searchExpression);
      const searchTerms = searchValue ? searchValue.split(' ') : [];
      const trainingText = concatTraining(item);
      return searchTerms.every((searchTerm) => trainingText.includes(searchTerm.toLowerCase()));
    });
};

export function concatTraining(training: Training): string {
  const credits = training.credits.toString();
  const season = training.season.year.toString();
  const name = training.name;
  const trainingFormat = training.format;
  const trainingFormatDisplay = formatDisplay(training.format);
  const startDate = format(training.startDate ?? 0, 'dd/MM/yy');
  const responsible = training.responsibleName;
  const deadline = format(training.deadline ?? 0, 'dd/MM/yy');

  return [name, credits, season, trainingFormat, trainingFormatDisplay, startDate, responsible, deadline]
    .join(' ')
    .toLowerCase();
}

export const getSeasonFilters = (seasonsSignal: MaybeSignal<Season[] | undefined>) => {
  const seasons = unsignal(seasonsSignal);
  if (!seasons) return [];
  return (
    seasons
      ?.sort((a, b) => b.year - a.year)
      ?.map((season) => {
        return { displayValue: season.year.toString(), key: season.id };
      }) ?? []
  );
};
