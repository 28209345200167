import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationService } from '@euricom/angular-shared';
import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  imports: [ButtonModule],
  selector: 'tc-app-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(private readonly _authenticationService: AuthenticationService) {}

  onLogin() {
    this._authenticationService.login();
  }
}
