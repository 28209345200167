import { SeasonAddComponent } from '@/seasons/components/season-add/season-add.component';
import { SeasonDeleteComponent } from '@/seasons/components/season-delete/season-delete.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { PageTitleService } from '@/shared/services/page-title.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeasonUpsertDto } from '@euricom/angular-training-catalog-api/models';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'tc-season-upsert',
  standalone: true,
  imports: [
    // primeng
    ButtonModule,
    CardModule,
    // common
    CommonModule,
    LoaderComponent,
    SeasonAddComponent,
    SeasonDeleteComponent,
  ],
  providers: [SeasonsService],
  templateUrl: './season-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonUpsertComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  readonly seasonId = this._route.snapshot.paramMap.get('id');

  readonly season = useQuery(
    computed(() => seasonKeys.byId(this.seasonId)),
    () => {
      return this._seasonsService.getSeasonAsync(this.seasonId ?? '');
    },
    { enabled: computed(() => !!this.seasonId) },
  );

  readonly upsertSeasonMutation = useMutation(this._seasonsService.upsertSeasonAsync, {
    onSuccess: () => {
      this._toaster.success('The season was successfully added/updated!');
      this._router.navigate(['/admin/seasons']);
      this._queryClient.invalidateQueries(seasonKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.detailed);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
    },
  });

  readonly deleteSeasonMutation = useMutation(this._seasonsService.deleteSeasonAsync, {
    onSuccess: () => {
      this._toaster.success('The season was successfully deleted!');
      this._router.navigate(['/admin/seasons']);
      this._queryClient.invalidateQueries(seasonKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.detailed);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toaster: ToastService,
    private _seasonsService: SeasonsService,
  ) {}

  onUpsertSeason(seasonUpsertDto: SeasonUpsertDto) {
    if (this.seasonId) {
      seasonUpsertDto.id = this.seasonId;
    }

    this.upsertSeasonMutation.mutate(seasonUpsertDto);
  }

  onDeleteSeason() {
    if (!this.seasonId) {
      this._toaster.error('No season selected');
      return;
    }

    this.deleteSeasonMutation.mutate(this.seasonId);
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Season');
  }
}
