import { getFormatColor } from '@/shared/models/format';
import { TrainingDetail } from '@/shared/models/training-detail';
import { TrainingDetailFollowUp } from '@/shared/models/training-detail-follow-up';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { Format } from '@euricom/angular-training-catalog-api/models';
import { MarkdownModule } from 'ngx-markdown';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
@Component({
  selector: 'tc-training-detail-info',
  standalone: true,
  imports: [CommonModule, TagModule, ButtonModule, DividerModule, FormatDisplayPipe, MarkdownModule],
  templateUrl: './training-detail-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingDetailInfoComponent {
  readonly currentUser = useAuth().user();
  @Input() trainingDetail?: TrainingDetail | TrainingDetailFollowUp | null;
  @Input() nrOfOptionsSelected?: number | null;
  @Input() toFollowUp: boolean = false;

  @Output() getIcal = new EventEmitter();

  constructor(private _router: Router) {}

  getFormatColor(format: Format | undefined) {
    if (!format) return '#FFFFFF';
    return getFormatColor(format);
  }

  canSeeAdminInfo() {
    return (
      this.currentUser?.isAdmin ||
      this.currentUser?.isCatalogResponsible ||
      this.currentUser?.isHumanResources ||
      this.currentUser?.isPracticeManager
    );
  }
  onClickEdit() {
    this._router.navigate(['admin/trainings', this.trainingDetail?.id]);
  }

  showOnCalendar() {
    if (!this.trainingDetail) return false;
    if ('showOnCalendar' in this.trainingDetail) {
      // It's TrainingDetail
      return this.trainingDetail.showOnCalendar;
    } else {
      // It's TrainingDetailFollowUp
      return false;
    }
  }

  seasonYear() {
    if (!this.trainingDetail) return false;
    if ('season' in this.trainingDetail) {
      // It's TrainingDetail
      return this.trainingDetail.season.year;
    }
    return;
  }

  getMarkdownClass() {
    if (this.toFollowUp) return 'prose line-clamp-2';
    return 'prose line-clamp-6 md:line-clamp-none';
  }
}
