import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationBookingService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back() {
    this.history.pop();
    if (this.history.length > 0) {
      // prevent going back to other routes
      if (!this.history[this.history.length - 1].includes('follow-up/bookings')) {
        this.router.navigateByUrl('/follow-up/bookings');
      } else {
        this.location.back();
      }
    } else {
      this.router.navigateByUrl('/follow-up/bookings');
    }
  }
}
