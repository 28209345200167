import { Injectable, InjectionToken, Signal, computed, inject, signal } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Observable, combineLatest, filter, map, mergeMap, shareReplay, tap } from 'rxjs';

import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AuthenticationSettings } from './authentication.settings';
import { User } from './user.model';

export const APP_ADMIN_ROLE = new InjectionToken<string | null>('APP_ADMIN_ROLE');
export const APP_HUMAN_RESOURCE_ROLE = new InjectionToken<string | null>('APP_HUMAN_RESOURCE_ROLE');
export const APP_CATALOG_RESPONSIBLE_ROLE = new InjectionToken<string | null>('APP_CATALOG_RESPONSIBLE_ROLE');
export const APP_PRACTICE_MANAGER_ROLE = new InjectionToken<string | null>('APP_PRACTICE_MANAGER_ROLE');

@Injectable()
export class AuthenticationService {
  private readonly _msalService = inject(MsalService);
  private readonly _broadcastService = inject(MsalBroadcastService);
  private readonly _settings = inject(AuthenticationSettings);
  private readonly _adminRole = inject(APP_ADMIN_ROLE);
  private readonly _humanResourceRole = inject(APP_HUMAN_RESOURCE_ROLE);
  private readonly _catalogResponsibleRole = inject(APP_CATALOG_RESPONSIBLE_ROLE);
  private readonly _practiceManagerRole = inject(APP_PRACTICE_MANAGER_ROLE);

  private readonly _impersonatedRoles = signal<string[] | null>(null);
  private readonly _accountSource = this.setupAccountSource$();

  public readonly user$ = this.setupUser$();
  public readonly user = toSignal(this.user$);
  public readonly canImpersonate = this.setupCanImpersonate();

  public login(): void {
    const scopes = AuthenticationSettings.scopes(this._settings);

    this._msalService.loginRedirect({ scopes });
  }

  public logout(): void {
    this._msalService.logoutRedirect();
  }

  public impersonate(roles: string[]): void {
    if (!this.canImpersonate()) {
      return;
    }

    this._impersonatedRoles.set(roles);
  }

  public clearImpersonations(): void {
    this._impersonatedRoles.set(null);
  }

  private setupAccountSource$(): Observable<AccountInfo | null> {
    const accountInfo$ = this._msalService.initialize().pipe(
      mergeMap(() => this._msalService.handleRedirectObservable()),
      mergeMap(() => this._broadcastService.inProgress$),
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => {
        const { instance } = this._msalService;
        const account = instance.getAllAccounts().find(() => true);
        return account ?? null;
      }),
      tap((account) => this._msalService.instance.setActiveAccount(account)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return accountInfo$;
  }

  private setupUser$(): Observable<User | null> {
    const impersonatedRoles$ = toObservable(this._impersonatedRoles);

    return combineLatest([this._accountSource, impersonatedRoles$]).pipe(
      map(([accountInfo, impersonatedRoles]) => {
        if (!accountInfo) {
          return null;
        }

        const roles = impersonatedRoles ?? accountInfo.idTokenClaims?.roles ?? [];
        const isAdmin = !!(this._adminRole && roles.includes(this._adminRole));
        const isHumanResources = !!(this._humanResourceRole && roles.includes(this._humanResourceRole));
        const isCatalogResponsible = !!(this._catalogResponsibleRole && roles.includes(this._catalogResponsibleRole));
        const isPracticeManager = !!(this._practiceManagerRole && roles.includes(this._practiceManagerRole));

        return new User({
          email: accountInfo.username,
          name: accountInfo.name,
          roles,
          isAdmin,
          isHumanResources,
          isCatalogResponsible,
          isPracticeManager,
        });
      }),
    );
  }

  private setupCanImpersonate(): Signal<boolean> {
    return computed(() => this.user()?.isAdmin ?? false);
  }
}
