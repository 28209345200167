import { EnvironmentProviders, NgModule, importProvidersFrom } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastService } from '../services/toast.service';

@NgModule({
  providers: [MessageService, ToastService],
})
export class ToasterModule {}

export function provideToaster(): EnvironmentProviders {
  return importProvidersFrom(ToasterModule);
}
