import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
@Component({
  selector: 'tc-booking-update-pending-panel',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, InputTextareaModule, ReactiveFormsModule, InfoTooltipComponent],
  templateUrl: './booking-update-pending-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingUpdatePendingPanelComponent {
  readonly form = this._setupForm();

  @Output() readonly update = new EventEmitter<string>();

  @Input() loading: Signal<boolean> = signal(false);

  @Input() optionSelected?: boolean;

  @Input() set noPassedOptionSelected(value: boolean) {
    this.form.patchValue({ noPassedOptionSelected: value });
  }

  constructor() {}

  onUpdate() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const value = this.form.value;
    this.update.emit(value.comment as string);
  }

  private _setupForm() {
    return new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(255), NoWhitespaceValidatorDirective.noWhitespace()],
      }),
      noPassedOptionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
    });
  }
}
