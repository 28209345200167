import { EnvironmentProviders, ErrorHandler, NgModule, importProvidersFrom } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class SentryModule {
  public readonly sentry!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(trace: Sentry.TraceService) {
    let environment = 'local';
    if (location.hostname.includes('delightful-rock')) {
      environment = 'dev';
    } else if (location.hostname.includes('training.euri.com')) {
      environment = 'production';
    }
    console.log('Sentry Environment: ', environment);

    Sentry.init({
      dsn: 'https://4fc97da3f41e2a1f4c01f08cbc1842bf@o4505997166247936.ingest.sentry.io/4505997172342784',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      enabled: environment !== 'local',
      enableTracing: false,
      tracesSampleRate: 2.0,
      environment,
      tracePropagationTargets: [
        'https://dev-euri-training-catalog-api.azurewebsites.net/api',
        'https://prd-euri-training-catalog-api.azurewebsites.net/api',
      ],
    });
  }
}

export function provideSentry(): EnvironmentProviders {
  return importProvidersFrom(SentryModule);
}
