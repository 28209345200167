<tc-loader *ngIf="booking.isLoading() || training.isLoading() || userProfile.isLoading()" />
<div
  class="flex md:flex-row gap-4 p-4 min-h-full flex-col"
  *ngIf="!booking.isLoading() && !training.isLoading() && !userProfile.isLoading()"
>
  <p-card class="md:w-3/4 w-full">
    <div class="flex flex-col gap-6">
      <tc-training-detail-info
        [trainingDetail]="training.data()"
        [nrOfOptionsSelected]="selectedOptions().length"
        (getIcal)="onGetIcal()"
      />
      <div class="lg:grid lg:grid-cols-3">
        <div class="lg:col-span-2">
          <tc-training-options
            [booking]="booking.data"
            [options]="training.data()?.options"
            [selectedOptions]="selectedOptions()"
            (selectedOptionsChange)="selectedOptions.set($event)"
          />
        </div>
        <div class="w-full">
          <tc-participants-list
            *ngIf="booking.data()?.participants && training.data()?.showParticipants"
            [participants]="booking.data()!.participants"
          />
        </div>
      </div>
      <div class="flex flex-col gap-6 mt-4">
        <tc-reviews *ngIf="training.data()?.reviews" [reviews]="training.data()!.reviews" />
      </div>
    </div>
  </p-card>
  <div class="flex flex-col w-full gap-4 md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card class="w-full">
      <tc-booking-info
        [ownBooking]="ownBooking()"
        [userProfile]="userProfile.data()"
        [booking]="booking.data()"
        [training]="training.data()"
        [isUpdated]="isUpdated()"
        (cancel)="onCancel($event)"
        (delete)="onDelete()"
        (update)="onUpdate()"
      />
    </p-card>
    <p-card *ngIf="booking.data()?.status === 'Added' && showRequestPanel()">
      <tc-request-panel
        [loading]="requestBookingMutation.isLoading"
        [optionSelected]="!!selectedOptions().length"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [training]="training.data()"
        (request)="onRequest($event)"
      />
    </p-card>
    <p-card *ngIf="booking.data()?.status === 'Requested' && (user()?.isPracticeManager || user()?.isAdmin)">
      <tc-booking-approve-reject-panel
        [optionSelected]="!!selectedOptions().length"
        [loadingApprove]="approveBookingMutation.isLoading"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [loadingReject]="rejectBookingMutation.isLoading"
        [pmEmail]="booking.data()?.practiceManagerEmail ?? ''"
        (approve)="onApprove($event)"
        (reject)="onReject($event)"
      />
    </p-card>
    <p-card *ngIf="booking.data()?.status === 'Approved' && (user()?.isHumanResources || user()?.isAdmin)">
      <tc-booking-start-pending-panel
        [loading]="bookBookingMutation.isLoading"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [optionSelected]="!!selectedOptions().length"
        (startPending)="onStartPending($event)"
      />
    </p-card>
    <p-card *ngIf="booking.data()?.status === 'PendingBooking' && (user()?.isHumanResources || user()?.isAdmin)">
      <tc-booking-update-pending-panel
        [loading]="bookBookingMutation.isLoading"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [optionSelected]="!!selectedOptions().length"
        (update)="onUpdatePending($event)"
      />
    </p-card>
    <p-card
      *ngIf="
        ['Approved', 'PendingBooking'].includes(booking.data()?.status ?? '') &&
        (user()?.isHumanResources || user()?.isAdmin)
      "
    >
      <tc-booking-book-panel
        [requireComment]="isUpdated()"
        [loading]="bookBookingMutation.isLoading"
        [noPassedOptionSelected]="noPassedOptionSelected()"
        [pending]="booking.data()?.status === 'PendingBooking'"
        [optionSelected]="!!selectedOptions().length"
        (book)="onBook($event)"
        (cancel)="onCancel($event)"
      />
    </p-card>
    <p-card
      *ngIf="
        ['Booked', 'Verified'].includes(booking.data()?.status ?? '') && (user()?.isHumanResources || user()?.isAdmin)
      "
    >
      <tc-booking-verify-panel
        [loading]="bookBookingMutation.isLoading"
        (verify)="onVerify()"
        [status]="booking.data()?.status"
      />
    </p-card>
    <p-card
      *ngIf="
        booking.data()?.status === 'Booked' &&
        (user()?.isPracticeManager || user()?.isHumanResources || user()?.isAdmin)
      "
    >
      <tc-booking-cancel-panel (cancel)="onCancel($event)" />
    </p-card>
    <p-card
      *ngIf="
        isPassed() && ownBooking() && (booking.data()?.status === 'Booked' || booking.data()?.status === 'Verified')
      "
    >
      <tc-booking-review-panel (submitReview)="onReview($event)" />
    </p-card>
    <p-card>
      <tc-booking-history-panel [comments]="booking.data()?.comments" />
    </p-card>
  </div>
</div>
