import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

export type SortOption = {
  key: SortOptionKeys;
  value: string;
};

const SortOptions = {
  None: 'None',
  Date: 'Date',
  Deadline: 'Deadline',
  TitleAsc: 'TitleAsc',
  TitleDesc: 'TitleDesc',
} as const;

export type SortOptionKeys = (typeof SortOptions)[keyof typeof SortOptions];

@Component({
  selector: 'tc-sort',
  standalone: true,
  imports: [CommonModule, DropdownModule, ReactiveFormsModule],
  templateUrl: './sort.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortComponent {
  private _selectedSortOption: SortOptionKeys | null = null;
  readonly form = this._setupForm();
  sortOptions: Array<SortOption> = [];

  @Output() readonly sort = new EventEmitter<SortOption>();

  @Input() set options(value: Array<SortOption>) {
    this.sortOptions = value;
    if (this._selectedSortOption) {
      const option = this.sortOptions.find((option) => option.key === this._selectedSortOption) || {
        key: 'TitleAsc',
        value: 'Title (a-z)',
      };
      this.form.patchValue({
        sort: option,
      });
    }
  }

  @Input() set selected(value: SortOptionKeys | null) {
    if (value) {
      this._selectedSortOption = value;
      const option = this.sortOptions.find((option) => option.key === value) || {
        key: 'TitleAsc',
        value: 'Title (a-z)',
      };
      this.form.patchValue({
        sort: option,
      });
    }
  }

  onChangeDropdown() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const value = this.form.value;

    this.sort.emit(value.sort);
  }

  private _setupForm() {
    return new FormGroup({
      sort: new FormControl<SortOption>({ key: 'TitleAsc', value: 'Title (a-z)' }, { nonNullable: true }),
    });
  }
}
