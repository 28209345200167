import { Comment } from '@/shared/models/comment';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'tc-booking-history-panel',
  standalone: true,
  imports: [CommonModule, DividerModule, FormatDatePipe],
  templateUrl: './booking-history-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHistoryPanelComponent {
  @Input() comments?: Comment[] = [];
}
