<form [formGroup]="form">
  <div class="mt-2 w-full">
    <label for="review">Comment <span class="text-error">*</span></label>
    <textarea
      [formControl]="form.controls.message"
      rows="5"
      id="review"
      name="review"
      pInputTextarea
      class="w-full"
      pAutoFocus
      [autofocus]="true">
    </textarea>
    <p *ngIf="
          form.controls.message.dirty &&
          (form.controls.message.hasError('required') || form.controls.message.hasError('whitespace'))"
       class="text-error">
      Comment is required.
    </p>
  </div>
  <div class="flex flex-col">
    <label for="rating">Rating <span class="text-error">*</span></label>
    <p-rating id="rating" [formControl]="form.controls.rating" [cancel]="false" [stars]="5" class="p-4">
      <ng-template pTemplate="onicon">
        <img src="assets/icons/green_heart.png" width="20px" height="20px" alt="green heart"/>
      </ng-template>
      <ng-template pTemplate="officon">
        <img src="assets/icons/grey_heart.png" width="20px" height="20px" alt="grey heart"/>
      </ng-template>
    </p-rating>
  </div>
  <div class="flex flex-row gap-2 mt-4">
    <p-button label="Save" size="small" (onClick)="onSubmit()" [loading]="loadingUpdate"/>
    <p-button label="Cancel" size="small" [outlined]="true" (onClick)="onCancel()"/>
  </div>
</form>
