<p-table
  #trainingAdminTable
  [value]="trainings"
  class="max-w-full min-w-full"
  styleClass="p-datatable-sm"
  [rowHover]="true"
  [(selection)]="selectedTrainings"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [sortOrder]="tableSort?.order ?? -1"
  [sortField]="tableSort?.sort"
>
  <ng-template pTemplate="header">
    <tr>
      <th>
        <div class="text-center">
          <p-tableHeaderCheckbox (click)="onSelect()" name="selectAll" />
        </div>
      </th>
      <th pSortableColumn="season.year" (click)="setSort('season.year')" style="max-width: 8em">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Season</div>
          <p-sortIcon field="season.year" />
        </div>
      </th>
      <th pSortableColumn="format" (click)="setSort('format')">
        <div class="flex items-center">
          Format
          <p-sortIcon field="format" />
        </div>
      </th>
      <th pSortableColumn="name" (click)="setSort('name')" style="max-width: 8em">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Name</div>
          <p-sortIcon field="name" />
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="startDate"
        (click)="setSort('startDate')"
        style="max-width: 8em"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Start date</div>
          <p-sortIcon field="startDate" />
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="deadline"
        (click)="setSort('deadline')"
        style="max-width: 8em"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Deadline</div>
          <p-sortIcon field="deadline" />
        </div>
      </th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="responsibleName"
        (click)="setSort('responsibleName')"
        style="max-width: 8em"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Responsible</div>
          <p-sortIcon field="responsibleName" />
        </div>
      </th>
      <th style="max-width: 8em">Domain</th>
      <th
        *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')"
        class="hidden xl:table-cell"
        pSortableColumn="isEnabled"
        (click)="setSort('isEnabled')"
        style="max-width: 7em"
      >
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Active</div>
          <p-sortIcon field="isEnabled" />
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-training>
    <tr
      [ngClass]="training.isEnabled === true ? '' : training.isEnabled === false ? 'opacity-60' : 'opacity-20'"
      class="cursor-pointer"
      (click)="clickTraining.emit(training.id)"
    >
      <td (click)="stopPropagation($event)">
        <div class="text-center">
          <p-tableCheckbox (click)="onSelect()" [value]="training" name="select" />
        </div>
      </td>
      <td>{{ training.season.year }}</td>
      <td>
        <p-tag
          [style]="{
            background: getFormatColor(training.format),
            height: '10%',
          }"
          ><p class="w-max">{{ training.format | fDisplay }}</p>
        </p-tag>
      </td>
      <td>{{ training.name }}</td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ training.startDate ? (training.startDate | fDate) : '/' }}
      </td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ training.deadline ? (training.deadline | fDate) : '/' }}
      </td>
      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        {{ training.responsibleName }}
      </td>
      <td style="max-width: 10em">
        <div class="max-w-[90%] truncate ...">
          {{ getDomainsString(training.domains) }}
        </div>
      </td>

      <td *ngIf="!hideColumnsBreakpoints.includes(breakpoint() ?? '')" class="hidden xl:table-cell">
        <div class="items-center flex justify-center">
          <i *ngIf="training.isEnabled === undefined" style="font-size: 1.2rem" class="pi pi-file-edit"></i>
          <i *ngIf="training.isEnabled" style="font-size: 1.2rem" class="pi pi-eye"></i>
          <i *ngIf="training.isEnabled === false" style="font-size: 1.2rem" class="pi pi-eye-slash"></i>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p class="text-center font-semibold mt-3" *ngIf="trainings.length === 0">No trainings found</p>
