<tc-loader *ngIf="training.isLoading()" />
<div *ngIf="!training.isLoading()" class="flex flex-col gap-4 p-4 min-h-full">
  <p-card class="w-full">
    <div class="flex flex-col gap-6">
      <tc-training-detail-info [trainingDetail]="training.data()" [toFollowUp]="true" />
    </div>
  </p-card>

  <p-card class="w-full">
    <div class="gap-5 justify-end hidden md:flex">
      <p-button size="small" (onClick)="onBulk('Start Pending')">Start Pending booking</p-button>
      <p-button size="small" (onClick)="onBulk('Update')">Update Pending booking</p-button>
      <p-button size="small" (onClick)="onBulk('Book')">Book</p-button>
      <p-button size="small" (onClick)="onBulk('Verify')">Verify</p-button>
    </div>
    <p-tabView class="pt-2">
      <p-tabPanel header="Bookings" class="pt-9">
        <div class="flex flex-col gap-6">
          <tc-training-follow-up-filter-header
            [searchValue]="searchValue()"
            (searchValueChange)="searchValue.set($event)"
            [totalAmount]="bookingCount()"
            [optionCounters]="optionCounters()"
            [verifiedAmount]="verifiedBookings()"
          ></tc-training-follow-up-filter-header>
          <tc-training-follow-up-detail-table
            [bookings]="filteredBookings() ?? []"
            [selectedBookings]="selectedBookings()"
            [options]="training.data()?.options ?? []"
            (verify)="onVerify($event)"
            (sortChanges)="onSortChanges($event.sort, $event.order)"
            [tableSort]="sort()"
            (cancel)="onCancel($event)"
            (book)="onBook($event)"
            (startPending)="onStartPending($event)"
            (update)="onUpdate($event)"
            (selectBooking)="onSelectBookings($event)"
          ></tc-training-follow-up-detail-table></div
      ></p-tabPanel>
      <p-tabPanel header="Options">
        <ng-template pTemplate="content">
          <tc-training-follow-up-detail-options-table
            [bookings]="training.data()?.bookings ?? []"
            [selectedBookings]="selectedBookings()"
            [options]="training.data()?.options"
            (cancel)="onCancel($event)"
            (verify)="onVerify($event)"
            (book)="onBook($event)"
            (startPending)="onStartPending($event)"
            (update)="onUpdate($event)"
            (selectBooking)="onSelectBookings($event)"
          ></tc-training-follow-up-detail-options-table>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </p-card>
</div>

<p-confirmDialog
  #cd
  key="dialogForBulkActions"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
  <ng-template pTemplate="message">
    <div class="min-w-[35rem]">
      <div class="flex content-center">
        <p *ngIf="cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">
          Deze comment wordt bij iedereen verstuurd op mail.
        </p>
        <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">
          Deze comment wordt niet op mail verstuurd, maar wel toegevoegd aan iedere booking.
        </p>
      </div>
      <form [formGroup]="form">
        <label class="flex gap-1" for="motivation"
          >Comment
          <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Start')" class="text-error">*</p></label
        >

        <textarea
          rows="3"
          id="motivation"
          name="motivation"
          pInputTextarea
          class="w-full"
          [formControl]="form.controls.comment"
        ></textarea>
        <div class="text-error">
          <p
            *ngIf="
              !cd.confirmation?.acceptLabel?.includes('Start') &&
              form.controls.comment.dirty &&
              (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
            "
          >
            Comment is required.
          </p>
          <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
            Comment is too long.
          </p>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()"></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      [disabled]="
        !cd.confirmation?.acceptLabel?.includes('Start') &&
        (form.controls.comment.hasError('required') ||
          form.controls.comment.hasError('whitespace') ||
          form.controls.comment.hasError('maxlength'))
      "
      [label]="cd.confirmation?.acceptLabel ?? 'Send'"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>
