import { Pipe, PipeTransform } from '@angular/core';

export const FrontendFormat = {
  InstructorLed: 'Instructor led',
  Certificate: 'Certificate',
  Conference: 'Conference',
  SelfPaced: 'Self-paced',
  Event: 'Event',
} as const;

export type frontendFormat = keyof typeof FrontendFormat;

@Pipe({ standalone: true, name: 'fDisplay' })
export class FormatDisplayPipe implements PipeTransform {
  transform(value: frontendFormat | undefined) {
    return formatDisplay(value);
  }
}

export function formatDisplay(value: frontendFormat | undefined | null) {
  return value ? FrontendFormat[value] : '';
}
