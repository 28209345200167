import { BookingHrTableGroupedComponent } from '@/bookings/components/booking-hr-table-grouped/booking-hr-table-grouped.component';
import { BookingHrTableComponent } from '@/bookings/components/booking-hr-table/booking-hr-table.component';
import { DropdownFacetComponent } from '@/shared/components/dropdown-facet/dropdown-facet.component';
import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { Booking } from '@/shared/models/booking';
import { BookingsService, bookingKeys, getRequesters } from '@/shared/services/booking.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { SeasonsService, getActiveSeasonIds, seasonKeys } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { UsersService, pmKeys } from '@/shared/services/user.service';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, computed, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingStatus } from '@euricom/angular-training-catalog-api/models';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BookingFilterHeaderComponent } from '../../components/booking-filter-header/booking-filter-header.component';
import { filterBookings, getCancelForm, getSeasonFilters } from './helpers';

type SearchParams = {
  search: string;
  pm: string[];
  requester: string;
  status: string[];
  season: string;
  groupBy: string;
  sort: SortTypesHRTable;
  order: string;
};

export type SortTypesHRTable =
  | 'training.format'
  | 'status'
  | 'training.name'
  | 'requesterName'
  | 'training.startDate'
  | 'practiceManagerName'
  | 'training.responsibleName'
  | 'deadline';

export type HRTableSort = {
  sort: SortTypesHRTable;
  order: number;
};

@Component({
  standalone: true,
  selector: 'tc-admin-trainings',
  templateUrl: './bookings-hr.component.html',
  providers: [BookingsService, SeasonsService, UsersService, SearchParamsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    // angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // primeNG
    CardModule,
    DividerModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextareaModule,
    CheckboxModule,
    // custom
    BookingFilterHeaderComponent,
    BookingHrTableComponent,
    BookingHrTableGroupedComponent,
    FilterFacetComponent,
    LoaderComponent,
    DropdownFacetComponent,
  ],
})
export class BookingsHrComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  private readonly _breakpoint = useCurrentBreakpoint();

  readonly defaultSeason = { displayValue: 'Active Seasons', key: '0' };
  readonly groupByPossibilities = [
    { displayValue: 'User', key: 'requesterName' },
    { displayValue: 'Month', key: 'month' },
    { displayValue: 'Training', key: 'training.name' },
  ];

  readonly form = getCancelForm();

  readonly selectedBookings: WritableSignal<Booking[]> = signal([]);

  readonly pms = useQuery(pmKeys.all, () => {
    return this._userService.getPracticeManagersAsync();
  });
  readonly seasons = useQuery(seasonKeys.all, () => {
    return this._seasonsService.getSeasonsAsync();
  });

  readonly seasonsToQuery = computed(() => {
    if (this.seasonFilters() === '0') {
      return this.activeSeasons() ?? [];
    }
    return [this.seasonFilters()];
  });

  readonly bookings = useQuery(
    bookingKeys.listBySeasons(this.seasonsToQuery),
    async () => {
      const bookings = await this._bookingService.getBookingsAsync({ seasons: this.seasonsToQuery() });
      return [...bookings].reverse();
    },
    { enabled: computed(() => !!this.seasonsToQuery()) },
  );

  readonly activeSeasons = computed(() => getActiveSeasonIds(this.seasons.data));
  readonly requesters = computed(() => getRequesters(this.bookings.data));

  readonly selectedRequester = computed(() => {
    return this.requesters()?.find((user) => user.key === this.requesterFilters());
  });
  readonly selectedGroupBy = computed(() => {
    return this.groupByPossibilities.find((group) => group.key === this.groupByFilters());
  });

  readonly selectedSeason = computed(() => {
    if (this.seasonFilters() === '0') {
      return this.seasonOptions()
        .filter((s) => this.activeSeasons()?.includes(s.key))
        .map((season) => parseInt(season.displayValue));
    }
    return this.seasonOptions()
      .filter((s) => s.key === this.seasonFilters())
      .map((season) => parseInt(season.displayValue));
  });

  readonly bookingsForMobile = computed(() => {
    const allBookings = this.filteredBookings();
    return allBookings.filter(
      (booking) =>
        booking.status === 'Approved' || booking.status === 'Requested' || booking.status === 'PendingBooking',
    );
  });
  readonly seasonOptions = computed(() => {
    if (this.seasons.data() && this.seasons.data()?.length === 0) return [this.defaultSeason];
    return [this.defaultSeason, ...getSeasonFilters(this.seasons.data)];
  });

  readonly statusOptions = Object.keys(BookingStatus).map((key) => {
    if (key === 'PendingBooking') {
      return {
        displayValue: 'Pending booking',
        key: key,
      };
    }
    return {
      displayValue: key,
      key: key,
    };
  });

  readonly searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchValue = signal(this.searchParams.search);
  readonly pmFilters = signal(this.searchParams.pm);
  readonly requesterFilters = signal(this.searchParams.requester);
  readonly statusFilters = signal(this.searchParams.status);
  readonly seasonFilters = signal(this.searchParams.season ?? '0');
  readonly groupByFilters = signal(this.searchParams.groupBy);
  readonly sort = signal<HRTableSort>({
    sort: this.searchParams.sort,
    order: parseInt(this.searchParams.order ?? '-1'),
  });

  readonly filteredBookings = computed(() => {
    return filterBookings(
      this.bookings.data,
      this.searchValue,
      this.statusFilters,
      this.pmFilters,
      this.requesterFilters,
      this.seasonFilters,
      this.activeSeasons(),
    );
  });

  readonly cancelBookingsMutation = useMutation(this._bookingService.cancelBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this.bookings.refetch();
      if (unchangedIds.length > 0)
        this._toaster.warning(`Unable to cancel ${unchangedIds.length} booking(s): Booking status is not "Booked"`);
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully cancelled ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });

  readonly bookBookingsMutation = useMutation(this._bookingService.bookBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this.bookings.refetch();

      if (unchangedIds.length > 0)
        this._toaster.warning(`Unable to book ${unchangedIds.length} booking(s): Booking status is not "Approved"`);
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully booked ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });

  readonly verifyBookingsMutation = useMutation(this._bookingService.verifyBookingBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(bookingKeys.all);
      this.bookings.refetch();

      if (unchangedIds.length > 0)
        this._toaster.warning(`Unable to verify ${unchangedIds.length} booking(s): Booking status is not "Booked"`);
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully verified ${changedIds.length} booking(s)`);
      }
      this.selectedBookings.set(this.selectedBookings().filter((booking) => unchangedIds.includes(booking.id)));
    },
  });

  readonly exportBookingsMutation = useMutation(this._bookingService.exportBookingsOfSeasonsAsync, {
    onSuccess: (data) => {
      this._toaster.success('Successfully exported bookings');
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'bookings.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    onError: () => {
      this._toaster.error('Unable to export bookings');
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _bookingService: BookingsService,
    private _seasonsService: SeasonsService,
    private _userService: UsersService,
    private _searchParamService: SearchParamsService,
    private _confirmationService: ConfirmationService,
    private _toaster: ToastService,
  ) {
    // when screen goes small and the status and season filters disappear, empty the filters.
    effect(
      () => {
        if (['sm', 'unknown'].includes(this._breakpoint() ?? 'undefined')) {
          this.statusFilters.set([]);
          this.groupByFilters.set('none');

          // set seasons to active seasons in mobile version
          const activeSeasons = this.activeSeasons();
          if (!activeSeasons) return;
          this.seasonFilters.set('0');
        }
      },
      { allowSignalWrites: true },
    );

    // When changing season, deselect every booking
    effect(
      () => {
        this.seasonFilters();
        this.selectedBookings.set([]);
      },
      { allowSignalWrites: true },
    );

    // When changing the filters, update the url
    effect(() => {
      const queryParams = {
        search: this.searchValue() || undefined, // must be undefined to delete the search when not set
        pm: this.pmFilters(),
        requester: this.requesterFilters() === 'none' ? undefined : this.requesterFilters(),
        status: this.statusFilters(),
        season: this.seasonFilters(),
        groupBy: this.groupByFilters() === 'none' ? undefined : this.groupByFilters(),
        sort: this.sort().sort,
        order: this.sort().sort ? this.sort().order : undefined,
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Bookings follow-up');
  }

  onSelectBooking(t: Booking[]) {
    this.selectedBookings.set(t);
  }

  onBook() {
    const length = this.selectedBookings().length;
    this._confirmationService.confirm({
      message: `Are you sure you want to book ${length} booking(s)?`,
      header: 'Confirmation book',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
        this.bookBookingsMutation.mutate({
          body: { bookingIds: this.selectedBookings().map((booking) => booking.id) },
        }),
    });
  }

  onCancel() {
    this._confirmationService.confirm({
      header: 'Confirmation cancel',
      key: 'dialogWithInputForApprove',
    });
  }

  onVerify() {
    const length = this.selectedBookings().length;
    this._confirmationService.confirm({
      message: `Are you sure you want to verify ${length} booking(s)?`,
      header: 'Confirmation verify',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
        this.verifyBookingsMutation.mutate({
          body: { bookingIds: this.selectedBookings().map((booking) => booking.id) },
        }),
    });
  }

  onExportBookings() {
    const seasons = this.seasons.data();

    if (!seasons) return;

    if (this.selectedSeason()[0] === 0) {
      const activeSeasonIds = this.activeSeasons();
      if (!activeSeasonIds) return;
      this.exportBookingsMutation.mutate(activeSeasonIds);
    } else {
      const selectedSeasons = this.selectedSeason();
      const selectedSeasonIds = selectedSeasons.map((season) => {
        return seasons.find((s) => s.year === season)?.id ?? '';
      });

      this.exportBookingsMutation.mutate(selectedSeasonIds);
    }
  }

  onAccept() {
    this.form.controls.comment.markAsDirty();
    if (this.form.invalid) return;

    this.cancelBookingsMutation.mutate({
      refund: this.form.controls.refund.value,
      body: {
        bookingIds: this.selectedBookings().map((booking) => booking.id),
        comment: this.form.controls.comment.value,
      },
    });
    this._confirmationService.close();
  }

  onSetSeasons(season: string[]) {
    this.seasonFilters.set(season[0]);
  }
  onSortChanges(sort: SortTypesHRTable, order: number) {
    this.sort.set({ sort: sort, order: order });
  }
}
