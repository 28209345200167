<div class="flex justify-between">
  <h1 class="text-2xl">Start Pending</h1>
  <tc-info-tooltip text="Start pending when the booking process is started. " />
</div>
<p-divider />
<form [formGroup]="form">
  <label for="comment">Comment</label>
  <textarea
    rows="5"
    id="comment"
    name="comment"
    [formControl]="form.controls.comment"
    pInputTextarea
    class="w-full"
  ></textarea>
  <div class="flex flex-wrap text-error">
    <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
      Comment is too long.
    </p>
  </div>
  <div class="mt-3 flex flex-col gap-3">
    <div class="flex flex-wrap text-error">
      <p *ngIf="!optionSelected">Please select at least one option.</p>
    </div>
    <p *ngIf="form.controls.noPassedOptionSelected.dirty && form.controls.noPassedOptionSelected.hasError('required')">
      A selected option is not available anymore.
    </p>
    <div>
      <p-button
        class="mr-1"
        label="Start pending"
        type="submit"
        [loading]="loading()"
        [disabled]="!optionSelected"
        size="small"
        (onClick)="onStartPending()"
      />
    </div>
  </div>
</form>
