/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TrainingCatalogApiConfiguration, TrainingCatalogApiConfigurationParams } from './training-catalog-api-configuration';

import { BookingsService } from './services/bookings.service';
import { EmailService } from './services/email.service';
import { HealthService } from './services/health.service';
import { HolidaysService } from './services/holidays.service';
import { KnowledgeDomainsService } from './services/knowledge-domains.service';
import { ReviewsService } from './services/reviews.service';
import { SeasonsService } from './services/seasons.service';
import { StandInsService } from './services/stand-ins.service';
import { TrainingCollectionsService } from './services/training-collections.service';
import { TrainingsService } from './services/trainings.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BookingsService,
    EmailService,
    HealthService,
    HolidaysService,
    KnowledgeDomainsService,
    ReviewsService,
    SeasonsService,
    StandInsService,
    TrainingCollectionsService,
    TrainingsService,
    UsersService,
    TrainingCatalogApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: TrainingCatalogApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: TrainingCatalogApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
