<div class="flex justify-between">
  <h1 class="text-2xl">Approve</h1>
  <tc-info-tooltip text="Evaluate the training for the user and approve when applicable." />
</div>
<p-divider />
<form [formGroup]="form">
  <label for="motivation">Comment</label>
  <textarea
    rows="5"
    id="motivation"
    name="motivation"
    [formControl]="form.controls.comment"
    pInputTextarea
    class="w-full"
  ></textarea>
  <div class="text-error">
    <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('rejectComment')">
      Comment is required when rejecting.
    </p>
    <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('approveComment')">
      Comment is required when you are not the default PM.
    </p>
    <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
      Comment is too long.
    </p>
    <p *ngIf="form.controls.optionSelected.dirty && form.controls.optionSelected.hasError('required')">
      Please select at least one option.
    </p>
    <p *ngIf="form.controls.noPassedOptionSelected.dirty && form.controls.noPassedOptionSelected.hasError('required')">
      A selected option is not available anymore.
    </p>
  </div>
  <div class="mt-3 flex flex-col gap-3">
    <div>
      <p-button
        class="mr-1"
        label="Approve"
        [loading]="loadingApprove()"
        type="submit"
        size="small"
        (onClick)="onAccept()"
      />
      <p-button
        label="Reject"
        type="submit"
        [loading]="loadingReject()"
        size="small"
        [outlined]="true"
        severity="danger"
        (onClick)="onReject()"
      />
    </div>
  </div>
</form>
