import { BookingDetail } from '@/shared/models/bookingDetail';
import { TrainingOption } from '@/shared/models/training-option';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { startOfToday } from 'date-fns';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'tc-training-option',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule, FormatDatePipe],
  templateUrl: './training-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingOptionComponent {
  readonly user = useAuth().user();
  @Input() option: TrainingOption;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() booking?: BookingDetail;
  @Input() showDates?: boolean;
  @Output() selectEvent = new EventEmitter<TrainingOption>();

  constructor() {
    this.option = {} as TrainingOption;
    this.selected = false;
    this.disabled = false;
  }

  statusIsRequestedAndIsUser() {
    if (!this.booking) return false;

    return this.booking.status !== 'Added' && !this.user?.isAdmin;
  }

  isPassed(deadline: Date | undefined, endDate: Date | undefined) {
    const today = startOfToday();
    if (this.user?.isPracticeManager || this.user?.isHumanResources || this.user?.isAdmin) return false;

    if ((deadline && deadline < today) || (endDate && endDate < today)) {
      this.selected = false;
      return true;
    }

    return false;
  }

  isDisabled() {
    return (
      this.disabled || this.isPassed(this.option.deadline, this.option.endDate) || this.statusIsRequestedAndIsUser()
    );
  }
}
