<tc-loader *ngIf="standIn.isLoading()" />

<div *ngIf="!standIn.isLoading()" class="flex md:flex-row gap-4 p-4 h-full flex-col">
  <div class="w-full">
    <div class="flex flex-col gap-4 pb-4">
      <p-card class="w-full">
        <tc-stand-in-edit
          [standIn]="standIn.data()"
          (upsert)="onUpsertStandIn($event)"
          [loading]="upsertStandInMutation.isLoading()"
        />
      </p-card>
    </div>
  </div>
  <div class="flex flex-col gap-4 pb-4 w-full md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card *ngIf="standIn.data()" class="w-full">
      <tc-stand-in-delete (deleteStandIn)="onDeleteStandIn()" />
    </p-card>
  </div>
</div>
