<p-dataView class="hover:cursor-pointer" [value]="sortedTrainings()">
  <ng-template pTemplate="list" let-trainings>
    <div
      *ngFor="let training of trainings; let first = first"
      class="p-3 hover:bg-base-300 border-b border-secondary border-opacity-25 last:border-0"
      [ngClass]="{
        'opacity-50 text-[#9b9b9b] border-opacity-50': !training.isEnabled
      }"
      (click)="onClickRow(training.id)"
    >
      <div class="gap-1 flex flex-col md:flex-row justify-between md:flex-nowrap">
        <div class="flex flex-col pl-2">
          <h1 class="text-lg text-clip font-bold">{{ training.name }} - {{ training.season.year }}</h1>

          <div class="pl-1 hidden lg:block">
            <span class="flex items-center gap-1">
              <p-tag [style]="{ background: getFormatColor(training.format), height: '10%' }"
                >{{ training.format | fDisplay }}
              </p-tag>
              <p>-</p>
              <i class="pi pi-credit-card"></i>
              <p>{{ training.credits }} credits</p>
              <ng-container *ngIf="training.startDate && training.showOnCalendar">
                <p>-</p>
                <p>{{ training.startDate | fDate : 'short' }}</p>
                <span *ngIf="training.halfDays / 2 > 1"> ({{ training.halfDays / 2 }} days)</span>
              </ng-container>
              <ng-container *ngIf="training.deadline">
                <p>-</p>
                <p>deadline: {{ training.deadline | fDate : 'short' }}</p>
              </ng-container>
            </span>
          </div>

          <div class="pl-1 block lg:hidden">
            <span class="flex items-center flex-wrap gap-1">
              <p-tag [style]="{ background: getFormatColor(training.format), height: '10%' }">
                {{ training.format | fDisplay }}
              </p-tag>
              <p>-</p>
              <i class="pi pi-credit-card"></i>
              <div class="flex gap-1">
                <p>{{ training.credits }} credits</p>
                <ng-container *ngIf="training.startDate">
                  <p>-</p>
                  <p>{{ training.startDate | fDate : 'short' }}</p>
                  <span *ngIf="training.halfDays / 2 > 1"> ({{ training.halfDays / 2 }} days)</span>
                </ng-container>
                <ng-container *ngIf="training.deadline && !(training.halfDays / 2 > 1)">
                  <p>-</p>
                  <p>deadline: {{ training.deadline | fDate : 'short' }}</p>
                </ng-container>
              </div>
            </span>

            <span class="p-1" *ngIf="training.deadline && training.halfDays / 2 > 1"
              >deadline: {{ training.deadline | fDate : 'short' }}</span
            >
          </div>
        </div>
        <div
          class="flex flex-row flex-wrap pl-2 md:pl-0 md:justify-end indent-1 md:indent-0 gap-1 whitespace-nowrap md:max-w-[50%]"
        >
          <ng-container *ngFor="let domain of training.domains">
            <p-tag>{{ domain.name }}</p-tag>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
