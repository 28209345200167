import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import {
  SeasonDetailAdminDto,
  SeasonDetailDto,
  SeasonDto,
  SeasonUpsertDto,
} from '@euricom/angular-training-catalog-api/models';
import { SeasonsService as RootSeasonsService } from '@euricom/angular-training-catalog-api/services';

import { SeasonDetail } from '@/shared/models/season-detail';
import { SeasonDetailAdmin } from '@/shared/models/season-detail-admin';
import { firstValueFrom } from 'rxjs';
import { Season } from '../models/season';
import { MaybeSignal, unsignal } from '../utils/signals';

export function mapSeason(dto: SeasonDto): Season {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
  };
}

export function mapSeasonDetail(dto: SeasonDetailDto): SeasonDetail {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
  };
}

export function mapSeasonDetailAdmin(dto: SeasonDetailAdminDto): SeasonDetailAdmin {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
  };
}

export function getActiveSeasonIds(seasonsSignal: MaybeSignal<Season[] | undefined>) {
  const seasons = unsignal(seasonsSignal);

  if (!Array.isArray(seasons)) {
    return;
  }

  return seasons
    ?.filter((season) => {
      return new Date() < new Date(season.endDate);
    })
    .map((season) => season.id);
}

export const seasonKeys = {
  // all seasons
  all: ['seasons'] as const,

  //specific season
  byId: (id: string | null) => [...seasonKeys.all, id] as const,

  // all detailed seasons
  detailed: ['seasons-detailed'] as const,

  // all seasons for admin
  allAdmin: ['seasons-admin'] as const,
};

@Injectable()
export class SeasonsService extends RootSeasonsService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  async getSeasonsAsync(): Promise<Season[]> {
    const data = await firstValueFrom(this.getSeasons());
    return data.map(mapSeason);
  }

  async getDetailedSeasonsAsync(): Promise<SeasonDetail[]> {
    const data = await firstValueFrom(this.getDetailedSeasons());
    return data.map(mapSeasonDetail);
  }

  async getSeasonsAdminAsync(): Promise<SeasonDetailAdmin[]> {
    const data = await firstValueFrom(this.getSeasonsAdmin());
    return data.map(mapSeasonDetailAdmin);
  }

  async getSeasonAsync(seasonId: string): Promise<SeasonDetail> {
    const data = await firstValueFrom(this.getSeason({ id: seasonId }));
    return mapSeasonDetail(data);
  }

  upsertSeasonAsync = async (variables: SeasonUpsertDto): Promise<Season> => {
    const result = await firstValueFrom(this.upsertSeason({ body: variables }));
    return mapSeason(result);
  };

  deleteSeasonAsync = async (seasonId: string): Promise<string> => {
    await firstValueFrom(this.deleteSeason({ id: seasonId }));
    return seasonId;
  };
}
