import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private key = 'training-catalog';

  private _trainingCatalogMap: Record<string, unknown> | undefined;
  private get trainingCatalogMap(): Record<string, unknown> {
    return this._trainingCatalogMap ?? (this._trainingCatalogMap = this._getApplicationData());
  }

  saveData<T>(key: string, value: T) {
    this.trainingCatalogMap[key] = value;
    this._storeApplicationData(this.trainingCatalogMap);
  }

  getData<T>(key: string): T | null {
    return (this.trainingCatalogMap[key] as T) ?? null;
  }

  private _getApplicationData(): Record<string, unknown> {
    const result = localStorage.getItem(this.key);
    if (result == null) return {};
    return JSON.parse(result);
  }

  private _storeApplicationData(value: Record<string, unknown>) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }
}
