import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  importProvidersFrom,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  Configuration,
  Configurations,
  buildConfigurationproviders,
} from './configuration';
import {
  APP_CONFIGURATION_LOCATION,
  ConfigurationService,
} from './configuration.service';

export const APP_CONFIGURATION = new InjectionToken<Configuration>(
  'APP_CONFIGURATION'
);

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationService.factory,
      deps: [ConfigurationService],
      multi: true,
    },
    { provide: ConfigurationService, useClass: ConfigurationService },
    { provide: APP_CONFIGURATION, useClass: Configuration },
    { provide: Configuration, useClass: Configuration },
  ],
})
export class ConfigurationModule {
  public static forRoot(
    location: string,
    configurations: Configurations = []
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        { provide: APP_CONFIGURATION_LOCATION, useValue: location },
        provideCustomConfigurations(configurations),
      ],
    };
  }

  constructor(configurationService: ConfigurationService) {
    if (!configurationService) {
      throw new Error('Missing configurationService');
    }
  }
}

export function provideConfiguration(location: string): EnvironmentProviders {
  return importProvidersFrom(ConfigurationModule.forRoot(location));
}

export function provideCustomConfigurations(
  configurations: Configurations = []
) {
  const customConfigurationsProviders =
    buildConfigurationproviders(configurations);

  return makeEnvironmentProviders(customConfigurationsProviders);
}
