<div class="h-full flex flex-col justify-center items-center">
  <h1 class="text-accent font-black text-8xl">404</h1>
  <h2 class="font-bold text-2xl">Oh no, looks like this page does not exist :(</h2>
  <p-button
    (onClick)="_router.navigate(['/'])"
    icon="pi pi-home"
    styleClass="p-button-secondary"
    class="mt-8"
    label="Go back to the home page"
  />
</div>
