import { TrainingFollowUp } from '@/shared/models/training-follow-up';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import {
  SortTypesTrainingFollowUpTable,
  TrainingFollowUpTableSort,
} from '@/trainings/pages/training-follow-up/training-follow-up.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SortEvent } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';

@Component({
  selector: 'tc-training-follow-up-table',
  standalone: true,
  imports: [CommonModule, TableModule, FormsModule, FormatDisplayPipe, StyleClassModule, FormatDatePipe],
  templateUrl: './training-follow-up-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFollowUpTableComponent {
  @ViewChild('trainingFollowUpTable') trainingFollowUpTable: Table | undefined;

  @Input() trainings: TrainingFollowUp[] = [];
  @Input() tableSort?: TrainingFollowUpTableSort;

  @Output() sortChanges = new EventEmitter<TrainingFollowUpTableSort>();
  @Output() clickTraining = new EventEmitter<string>();

  readonly breakpoint = useCurrentBreakpoint();
  readonly hideColumnsBreakpoints = ['sm', 'md', 'lg', 'unknown'];

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  customSort(event: SortEvent) {
    if (!event.data || !event.field) return;

    event.data.sort((data1, data2) => {
      const value1 = this.getPropertyValue(data1, event.field);
      const value2 = this.getPropertyValue(data2, event.field);

      if (value1 == null && value2 != null) return -1;
      if (value1 != null && value2 == null) return 1;
      if (value1 == null && value2 == null) return 0;

      if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
        if (value1 === value2) return 0;
        return value1 ? -1 : 1;
      }

      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2);
      }

      return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    });

    if (event.order === undefined || event.order === 1) {
      return event.data;
    } else {
      return event.data.reverse();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getPropertyValue(object: any, field = '') {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      value = value[f];
    }
    return value;
  }

  setSort(sortValue: SortTypesTrainingFollowUpTable) {
    const order = this.trainingFollowUpTable?.sortOrder ?? -1;
    this.sortChanges.emit({ sort: sortValue, order: order });
  }
}
