import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { Training } from '@/shared/models/training';
import { CollectionsService } from '@/shared/services/collections.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { getDomains, getFormats, trainingKeys, TrainingsService } from '@/shared/services/training.service';
import { responsiblesKeys, UsersService } from '@/shared/services/user.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TrainingAdminTableComponent } from '../../components/training-admin-table/training-admin-table.component';
import { TrainingFilterHeaderComponent } from '../../components/training-filter-header/training-filter-header.component';
import { filterAdminTrainings, getSeasonFilters } from './helpers';

type SearchParams = {
  search: string;
  state: string[];
  format: string[];
  season: string[];
  responsible: string[];
  domain: string[];
  collection: string[];
  sort: SortTypesTrainingAdminTable;
  order: string;
};

export type SortTypesTrainingAdminTable =
  | 'season.year'
  | 'name'
  | 'format'
  | 'startDate'
  | 'deadline'
  | 'responsibleName'
  | 'isEnabled';

export type TrainingAdminTableSort = {
  sort: SortTypesTrainingAdminTable;
  order: number;
};

@Component({
  standalone: true,
  selector: 'tc-admin-trainings',
  templateUrl: './trainings-admin.component.html',
  imports: [
    CommonModule,
    CardModule,
    DividerModule,
    ButtonModule,
    //custom
    TrainingAdminTableComponent,
    TrainingFilterHeaderComponent,
    FilterFacetComponent,
    LoaderComponent,
    CheckboxModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  providers: [SeasonsService, TrainingsService, UsersService, CollectionsService, ToastService, SearchParamsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminTrainingsComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  private toEnable: boolean | null = null;

  readonly selectedSeason = new FormControl<{ key: string; displayValue: string } | null>(null, {
    nonNullable: true,
    validators: [Validators.required],
  });

  readonly selectedTrainings: WritableSignal<Training[]> = signal([]);

  readonly seasons = useQuery(seasonKeys.all, () => {
    return this._seasonsService.getSeasonsAsync();
  });

  readonly seasonOptions = computed(() => {
    if (this.seasons.data() && this.seasons.data()?.length === 0) return [];
    return getSeasonFilters(this.seasons.data);
  });

  readonly filteredSeasonOptions = computed(() => {
    return this.seasonOptions().filter(
      (season) =>
        parseInt(season.displayValue) >= new Date().getFullYear() && !this.seasonFilters().includes(season.key),
    );
  });

  readonly seasonsToQuery = computed(() => {
    if (!this.seasonFilters() && this.seasonOptions().length !== 0) return [this.seasonOptions()[0].key];
    return this.seasonFilters();
  });

  readonly trainings = useQuery(
    trainingKeys.listBySeasons(this.seasonsToQuery),
    (): Promise<Training[]> => {
      return this._trainingsService.getTrainingsAsync({ seasons: this.seasonsToQuery() });
    },
    {
      enabled: computed(() => this.seasonOptions().length !== 0),
    },
  );

  readonly sortedTrainings = computed(() => {
    return this.trainings.data()?.sort((a, b) => a.name.localeCompare(b.name));
  });

  readonly responsibles = useQuery(responsiblesKeys.allFacet, async () => {
    const responsibles = await this._userService.getCatalogResponsiblesAsync();
    return (
      responsibles
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((user) => ({ key: user.name, displayValue: user.name })) ?? []
    );
  });

  readonly domains = computed(() => {
    return getDomains(this.trainings.data);
  });

  readonly formats = computed(() => {
    return getFormats(this.trainings.data);
  });

  readonly filteredTrainings = computed(() => {
    return filterAdminTrainings(
      this.sortedTrainings,
      this.searchValue,
      this.stateFilters,
      this.formatFilters,
      this.responsibleFilters,
      this.domainFilters,
      this.collectionFilters,
    );
  });

  readonly deleteTrainingsMutation = useMutation(this._trainingsService.deleteTrainingsAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.all);
      this.trainings.refetch();
      if (unchangedIds.length > 0)
        this._toaster.warning(
          `Unable to delete ${unchangedIds.length} training(s) because training had already bookings`,
        );
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully deleted ${changedIds.length} training(s)`);
      }
      this.selectedTrainings.set(this.selectedTrainings().filter((training) => unchangedIds.includes(training.id)));
    },
  });

  readonly toggleActiveBulkMutation = useMutation(this._trainingsService.updateTrainingIsEnabledBulkAsync, {
    onSuccess: ({ changedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.all);
      this.trainings.refetch();

      // replace selected trainings with updated trainings
      changedIds.forEach((id) => {
        const training = this.selectedTrainings().find((training) => training.id === id);
        if (training) training.isEnabled = this.toEnable ?? false;
      });

      this.toEnable = null;
    },
  });

  searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchValue = signal(this.searchParams.search);
  readonly stateFilters = signal<string[]>(this.searchParams.state);
  readonly formatFilters = signal<string[]>(this.searchParams.format);
  readonly seasonFilters = signal<string[]>(this.searchParams.season);
  readonly responsibleFilters = signal<string[]>(this.searchParams.responsible);
  readonly domainFilters = signal<string[]>(this.searchParams.domain);
  readonly collectionFilters = signal<string[]>(this.searchParams.collection ?? []);
  readonly sort = signal<TrainingAdminTableSort>({
    sort: this.searchParams.sort,
    order: parseInt(this.searchParams.order ?? '-1'),
  });

  readonly states = [
    { key: '1', displayValue: 'Active' },
    { key: '0', displayValue: 'Not Active' },
    { key: '2', displayValue: 'Draft' },
  ];

  readonly copyTrainingsToSeason = useMutation(this._trainingsService.copyTrainingToSeasonBulkAsync, {
    onSuccess: ({ changedIds, unchangedIds }: { changedIds: string[]; unchangedIds: string[] }) => {
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this.trainings.refetch();
      if (unchangedIds.length > 0)
        this._toaster.warning(
          `Unable to copy ${unchangedIds.length} training(s) because training already exists in season`,
        );
      if (changedIds.length > 0) {
        this._toaster.success(`Successfully copied ${changedIds.length} training(s)`);
      }
      this.selectedTrainings.set(this.selectedTrainings().filter((training) => unchangedIds.includes(training.id)));
    },
    onError: () => {
      this._toaster.error('The training(s) could not be copied to the season!');
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _trainingsService: TrainingsService,
    private _seasonsService: SeasonsService,
    private _userService: UsersService,
    private _toaster: ToastService,
    private _confirmationService: ConfirmationService,
    private _searchParamService: SearchParamsService,
    private _router: Router,
  ) {
    // when changing season, deselect every training
    effect(
      () => {
        this.seasonFilters();
        this.selectedTrainings.set([]);
      },
      { allowSignalWrites: true },
    );

    // when changing search params, update the url
    effect(() => {
      const queryParams = {
        search: this.searchValue() || undefined, // must be undefined to delete the search when not set
        state: this.stateFilters(),
        format: this.formatFilters(),
        season: this.seasonFilters(),
        responsible: this.responsibleFilters(),
        domain: this.domainFilters(),
        collection: this.collectionFilters(),
        sort: this.sort().sort,
        order: this.sort().sort ? this.sort().order : undefined,
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Trainings admin');
  }

  onClickAddTraining() {
    this._router.navigate(['/admin/trainings/add'], { queryParams: { season: this.seasonFilters() } }).then();
  }

  onClickTraining(trainingId: string) {
    this._router.navigate(['/admin/trainings/', trainingId]).then();
  }

  onSelectTraining(trainings: Training[]) {
    if (this.toEnable === null) this.toEnable = !trainings.at(0)?.isEnabled;
    if (trainings.length === 0) this.toEnable = null;
    this.selectedTrainings.set(trainings);
  }

  onSearchTrainings(searchValue: string) {
    this.searchValue.set(searchValue);
  }

  onDelete() {
    const length = this.selectedTrainings().length;
    this._confirmationService.confirm({
      message: `Are you sure you want to delete ${length} training(s)?`,
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteTrainingsMutation.mutate(this.selectedTrainings().map((training) => training.id)),
    });
  }

  onCopyToSeason() {
    this._confirmationService.confirm({
      header: 'Confirmation copy',
      key: 'dialogWithInputForCopyToSeason',
    });
  }

  onToggleActive() {
    if (this.toEnable === null) this.toEnable = !this.selectedTrainings().at(0)?.isEnabled;
    this.toggleActiveBulkMutation.mutate({
      isEnabled: this.toEnable,
      trainingIds: this.selectedTrainings().map((training) => training.id),
    });
  }

  onSubmitCopyToSeason() {
    this.selectedSeason.markAsDirty();

    if (this.selectedSeason.invalid) return;

    this.copyTrainingsToSeason.mutate({
      trainingIds: this.selectedTrainings().map((training) => training.id),
      seasonId: this.selectedSeason.value?.key ?? '',
    });
    this._confirmationService.close();
  }

  onSortChanges(sort: SortTypesTrainingAdminTable, order: number) {
    this.sort.set({ sort: sort, order: order });
  }
}
