import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Format } from '@euricom/angular-training-catalog-api/models';

export function getCollectionForm() {
  return new FormGroup({
    title: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, NoWhitespaceValidatorDirective.noWhitespace(), Validators.maxLength(255)],
    }),
    format: new FormControl<Format | null>(null, {
      validators: [Validators.required],
    }),
  });
}
