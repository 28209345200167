import { HRTableSort, SortTypesHRTable } from '@/bookings/pages/bookings-hr/bookings-hr.component';
import { Booking } from '@/shared/models/booking';
import { getFormatColor } from '@/shared/models/format';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { useCurrentBreakpoint } from '@/shared/utils/breakpoints';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Format } from '@euricom/angular-training-catalog-api/models';
import { SortEvent } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'tc-booking-hr-table',
  standalone: true,
  imports: [CommonModule, TagModule, TableModule, CheckboxModule, FormsModule, FormatDatePipe, FormatDisplayPipe, StatusDisplayPipe],
  templateUrl: './booking-hr-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHrTableComponent {
  @Input() bookings: Booking[] = [];
  @Input() selectedBookings: Booking[] = [];
  @Input() tableSort?: HRTableSort;

  @Output() selectBookings = new EventEmitter<Booking[]>();
  @Output() sortChanges = new EventEmitter<HRTableSort>();

  readonly breakpoint = useCurrentBreakpoint();
  readonly hideColumnsBreakpoints = ['sm', 'md', 'lg', 'unknown'];

  @ViewChild('bookingsHRTable') bookingsHRTable: Table | undefined;

  constructor(private router: Router) {}

  onClickBooking(id: number) {
    this.router.navigate(['/follow-up/bookings', id]);
  }

  onSelect() {
    this.selectBookings.emit(this.selectedBookings);
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  customSort(event: SortEvent) {
    if (!event.data || !event.field) return;

    event.data.sort((data1, data2) => {
      const value1 = this.getPropertyValue(data1, event.field);
      const value2 = this.getPropertyValue(data2, event.field);
      let result;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      if (event.order === undefined || event.order === 1) return result;

      return event.order * result;
    });
  }
  setSort(sortValue: SortTypesHRTable) {
    const order = this.bookingsHRTable?.sortOrder ?? -1;
    this.sortChanges.emit({ sort: sortValue, order: order });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getPropertyValue(object: any, field = '') {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      value = value[f];
    }
    return value;
  }

  getFormatColor(format: Format) {
    return getFormatColor(format);
  }
}
