import { TrainingCollection } from '@/shared/models/training-collection';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import {
  Format,
  TrainingCollectionDto,
  TrainingCollectionUpsertDto,
} from '@euricom/angular-training-catalog-api/models';
import { TrainingCollectionsService as RootCollectionService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';
import { formatDisplay } from '../pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '../utils/signals';

export function mapTrainingCollectionDetail(dto: TrainingCollectionDto): TrainingCollection {
  return {
    ...dto,
    id: dto.id,
    title: dto.title,
    format: dto.format as Format,
  };
}

export function mapTrainingCollection(dto: TrainingCollectionDto): TrainingCollection {
  return {
    id: dto.id,
    title: dto.title,
    format: dto.format as Format,
  };
}

export const getFormatsCollection = (collections: MaybeSignal<TrainingCollection[] | undefined>) => {
  const collectionData = unsignal(collections) ?? [];
  return collectionData
    .reduce((acc, item) => {
      if (!acc.some((accItem) => accItem.id === item.format)) {
        acc.push({ id: item.format, name: formatDisplay(item.format) });
      }
      return acc;
    }, [] as { id: string; name: string }[])
    .map((item) => {
      return {
        key: item.id,
        displayValue: item.name,
      };
    })
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
};

export const collectionKeys = {
  // all collections
  all: ['collections'] as const,

  //specific collection
  byId: (id: string | null) => [...collectionKeys.all, id] as const,
};

@Injectable()
export class CollectionsService extends RootCollectionService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  async getTrainingCollectionAsync(trainingCollectionId: string): Promise<TrainingCollection> {
    const data = await firstValueFrom(
      this.getTrainingCollection({
        id: trainingCollectionId,
      }),
    );
    return mapTrainingCollectionDetail(data);
  }

  async getTrainingCollectionsAsync(): Promise<TrainingCollection[]> {
    const data = await firstValueFrom(this.getTrainingCollections());
    return data.map(mapTrainingCollection);
  }

  upsertTrainingCollectionAsync = (variables: TrainingCollectionUpsertDto): Promise<TrainingCollectionDto> => {
    return firstValueFrom(this.upsertTrainingCollection({ body: variables }));
  };

  deleteTrainingCollectionAsync = async (body: { collectionId: string; withForce: boolean }): Promise<string> => {
    await firstValueFrom(this.deleteTrainingCollection({ id: body.collectionId, force: body.withForce }));
    return body.collectionId;
  };
}
