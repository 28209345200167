<div class="p-4">
  <div class="hidden md:block">
    <tc-loader *ngIf="reviews.isLoading() && !reviews.data()" />
    <p-card class="w-full" *ngIf="reviews.data()">
      <p-table
        #reviewFollowUpTable
        [value]="reviews.data()!"
        sortMode="single"
        [scrollable]="true"
        [sortField]="sort()"
        [sortOrder]="sortOrder"
        rowGroupMode="subheader"
        groupRowsBy="year"
        [groupRowsByOrder]="-1"
        scrollHeight="75vh"
        [styleClass]="'p-datatable-sm'"
        [rowHover]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="year" (click)="setSort('year')" style="width: 2vw">
              <div class="flex items-center">
                Year
                <p-sortIcon field="year" />
              </div>
            </th>
            <th pSortableColumn="reviewer" (click)="setSort('reviewer')" style="width: 7vw">
              <div class="flex items-center">Reviewer <p-sortIcon field="reviewer" /></div>
            </th>
            <th pSortableColumn="trainingName" (click)="setSort('trainingName')" style="width: 10vw">
              <div class="flex items-center">
                Training
                <p-sortIcon field="trainingName" />
              </div>
            </th>
            <th pSortableColumn="message" (click)="setSort('message')" style="width: 10vw">
              <div class="flex items-center">
                Comment
                <p-sortIcon field="message" />
              </div>
            </th>
            <th pSortableColumn="rating" (click)="setSort('rating')" style="width: 7vw">
              <div class="flex items-center">
                Rating
                <p-sortIcon field="rating" />
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-review>
          <tr pRowGroupHeader style="top: 39px">
            <td colspan="9">
              <div class="w-full flex gap-3 items-center py-2 font-bold">
                <p>{{ review.year }}:</p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
          <tr class="cursor-pointer">
            <td>{{ review.year }}</td>
            <td>{{ review.reviewer }}</td>
            <td>{{ review.trainingName }}</td>
            <td>{{ review.message }}</td>
            <td>
              <div class="flex gap-1">
                <img
                  *ngFor="let n of [].constructor(review.rating)"
                  src="assets/icons/green_heart.png"
                  width="20px"
                  height="20px"
                  alt="green heart"
                />
                <img
                  *ngFor="let n of [].constructor(5 - review.rating)"
                  src="assets/icons/grey_heart.png"
                  width="20px"
                  height="20px"
                  alt="grey heart"
                />
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="reviews.data()?.length === 0 && !reviews.isLoading()">
        <p class="text-center font-semibold mt-3">You have no reviews at the moment</p>
      </div>
    </p-card>
  </div>
</div>

<div class="p-4 pt-0">
  <div class="md:hidden">
    <tc-loader *ngIf="reviews.isLoading() && !reviews.data()" />
    <p-card class="w-full" *ngIf="reviews.data()">
      <p-table [value]="reviews.data()!" responsiveLayout="stack" [breakpoint]="'1000px'">
        <ng-template pTemplate="body" let-review>
          <tr>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold w-1/3">Year:</span>
                <span class="self-start">{{ review.year }}</span>
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold w-1/3">Reviewer:</span>
                <span>{{ review.reviewer }}</span>
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Training name:</span>
                {{ review.trainingName }}
              </div>
            </td>

            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Review comment:</span>
                {{ review.message }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Rating:</span>
                <div class="flex gap-1 h-fit w-fit">
                  <img
                    *ngFor="let n of [].constructor(review.rating)"
                    src="assets/icons/green_heart.png"
                    width="20px"
                    height="15px"
                    alt="green heart"
                  />
                  <img
                    *ngFor="let n of [].constructor(5 - review.rating)"
                    src="assets/icons/grey_heart.png"
                    width="20px"
                    height="15px"
                    alt="grey heart"
                  />
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="reviews.data()!.length === 0">
        <p class="text-center font-semibold mt-3">You have no reviews at the moment</p>
      </div>
    </p-card>
  </div>
</div>
