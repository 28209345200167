/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BulkActionDto } from '../models/bulk-action-dto';
import { TrainingCopyBulkDto } from '../models/training-copy-bulk-dto';
import { TrainingDetailDto } from '../models/training-detail-dto';
import { TrainingDetailFollowUpDto } from '../models/training-detail-follow-up-dto';
import { TrainingDto } from '../models/training-dto';
import { TrainingFollowUpDto } from '../models/training-follow-up-dto';
import { TrainingUpdateIsEnabledBulkDto } from '../models/training-update-is-enabled-bulk-dto';
import { TrainingUpdateIsEnabledDto } from '../models/training-update-is-enabled-dto';
import { TrainingUpsertDto } from '../models/training-upsert-dto';

@Injectable({ providedIn: 'root' })
export class TrainingsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTrainings()` */
  static readonly GetTrainingsPath = '/api/Trainings';

  /**
   * Retrieves a list of trainings based on optional filters.
   * Credits are calculated based on the default options credits.
   * StartDate is the first earliest date of the training options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainings$Response(
    params?: {

    /**
     * Optional start date filter.
     */
      startDate?: string;

    /**
     * Optional end date filter.
     */
      endDate?: string;

    /**
     * Optional after date filter. Filters the trainings to return the trainings where the endDate is after the given date.
     */
      afterDate?: string;

    /**
     * Optional seasons filter.
     */
      seasons?: Array<string>;

    /**
     * Optional collectionId filter.
     */
      collectionId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TrainingDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.GetTrainingsPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('afterDate', params.afterDate, {});
      rb.query('seasons', params.seasons, {});
      rb.query('collectionId', params.collectionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrainingDto>>;
      })
    );
  }

  /**
   * Retrieves a list of trainings based on optional filters.
   * Credits are calculated based on the default options credits.
   * StartDate is the first earliest date of the training options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainings(
    params?: {

    /**
     * Optional start date filter.
     */
      startDate?: string;

    /**
     * Optional end date filter.
     */
      endDate?: string;

    /**
     * Optional after date filter. Filters the trainings to return the trainings where the endDate is after the given date.
     */
      afterDate?: string;

    /**
     * Optional seasons filter.
     */
      seasons?: Array<string>;

    /**
     * Optional collectionId filter.
     */
      collectionId?: string;
    },
    context?: HttpContext
  ): Observable<Array<TrainingDto>> {
    return this.getTrainings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingDto>>): Array<TrainingDto> => r.body)
    );
  }

  /** Path part for operation `upsertTraining()` */
  static readonly UpsertTrainingPath = '/api/Trainings';

  /**
   * Add or update a training.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertTraining()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTraining$Response(
    params?: {
  
    /**
     * The training upsert data.
     */
    body?: TrainingUpsertDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.UpsertTrainingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingDetailDto>;
      })
    );
  }

  /**
   * Add or update a training.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertTraining$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTraining(
    params?: {
  
    /**
     * The training upsert data.
     */
    body?: TrainingUpsertDto
    },
    context?: HttpContext
  ): Observable<TrainingDetailDto> {
    return this.upsertTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingDetailDto>): TrainingDetailDto => r.body)
    );
  }

  /** Path part for operation `getTrainingsFollowUp()` */
  static readonly GetTrainingsFollowUpPath = '/api/Trainings/follow-up';

  /**
   * Retrieves a list of trainings to follow up.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingsFollowUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingsFollowUp$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TrainingFollowUpDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.GetTrainingsFollowUpPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrainingFollowUpDto>>;
      })
    );
  }

  /**
   * Retrieves a list of trainings to follow up.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingsFollowUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingsFollowUp(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<TrainingFollowUpDto>> {
    return this.getTrainingsFollowUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingFollowUpDto>>): Array<TrainingFollowUpDto> => r.body)
    );
  }

  /** Path part for operation `getTraining()` */
  static readonly GetTrainingPath = '/api/Trainings/{id}';

  /**
   * Retrieves a training by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraining$Response(
    params: {

    /**
     * The training id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.GetTrainingPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingDetailDto>;
      })
    );
  }

  /**
   * Retrieves a training by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraining(
    params: {

    /**
     * The training id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<TrainingDetailDto> {
    return this.getTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingDetailDto>): TrainingDetailDto => r.body)
    );
  }

  /** Path part for operation `deleteTraining()` */
  static readonly DeleteTrainingPath = '/api/Trainings/{id}';

  /**
   * Delete a training.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTraining$Response(
    params: {

    /**
     * The training id.
     */
      id: string;

    /**
     * If true, bookings will be deleted.
     */
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.DeleteTrainingPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a training.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTraining(
    params: {

    /**
     * The training id.
     */
      id: string;

    /**
     * If true, bookings will be deleted.
     */
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTrainingFollowUp()` */
  static readonly GetTrainingFollowUpPath = '/api/Trainings/follow-up/{id}';

  /**
   * Retrieves a training to follow up by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingFollowUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingFollowUp$Response(
    params: {

    /**
     * The training id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingDetailFollowUpDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.GetTrainingFollowUpPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingDetailFollowUpDto>;
      })
    );
  }

  /**
   * Retrieves a training to follow up by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingFollowUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingFollowUp(
    params: {

    /**
     * The training id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<TrainingDetailFollowUpDto> {
    return this.getTrainingFollowUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingDetailFollowUpDto>): TrainingDetailFollowUpDto => r.body)
    );
  }

  /** Path part for operation `updateTrainingIsEnabled()` */
  static readonly UpdateTrainingIsEnabledPath = '/api/Trainings/update/IsEnabled';

  /**
   * Update the IsEnabled property of a training.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTrainingIsEnabled()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrainingIsEnabled$Response(
    params?: {
  
    /**
     * The training update data.
     */
    body?: TrainingUpdateIsEnabledDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.UpdateTrainingIsEnabledPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update the IsEnabled property of a training.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTrainingIsEnabled$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrainingIsEnabled(
    params?: {
  
    /**
     * The training update data.
     */
    body?: TrainingUpdateIsEnabledDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateTrainingIsEnabled$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateTrainingIsEnabledBulk()` */
  static readonly UpdateTrainingIsEnabledBulkPath = '/api/Trainings/update/IsEnabled/bulk';

  /**
   * Update the IsEnabled property of a range of trainings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTrainingIsEnabledBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrainingIsEnabledBulk$Response(
    params?: {
  
    /**
     * An object that contains the ids of the trainings that should be updated and the value to set.
     */
    body?: TrainingUpdateIsEnabledBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.UpdateTrainingIsEnabledBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Update the IsEnabled property of a range of trainings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTrainingIsEnabledBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrainingIsEnabledBulk(
    params?: {
  
    /**
     * An object that contains the ids of the trainings that should be updated and the value to set.
     */
    body?: TrainingUpdateIsEnabledBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.updateTrainingIsEnabledBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `deleteTrainingBulk()` */
  static readonly DeleteTrainingBulkPath = '/api/Trainings/delete';

  /**
   * Delete a range of trainings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTrainingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTrainingBulk$Response(
    params?: {
  
    /**
     * The training ids.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.DeleteTrainingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Delete a range of trainings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTrainingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTrainingBulk(
    params?: {
  
    /**
     * The training ids.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.deleteTrainingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `copyTrainingToSeason()` */
  static readonly CopyTrainingToSeasonPath = '/api/Trainings/copy';

  /**
   * Copy a training and its options to another season.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyTrainingToSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyTrainingToSeason$Response(
    params?: {

    /**
     * The id of the training we want to copy.
     */
      trainingId?: string;

    /**
     * The id of the season we want to copy the training to.
     */
      seasonId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.CopyTrainingToSeasonPath, 'post');
    if (params) {
      rb.query('trainingId', params.trainingId, {});
      rb.query('seasonId', params.seasonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingDto>;
      })
    );
  }

  /**
   * Copy a training and its options to another season.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyTrainingToSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyTrainingToSeason(
    params?: {

    /**
     * The id of the training we want to copy.
     */
      trainingId?: string;

    /**
     * The id of the season we want to copy the training to.
     */
      seasonId?: string;
    },
    context?: HttpContext
  ): Observable<TrainingDto> {
    return this.copyTrainingToSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingDto>): TrainingDto => r.body)
    );
  }

  /** Path part for operation `copyTrainingToSeasonBulk()` */
  static readonly CopyTrainingToSeasonBulkPath = '/api/Trainings/copy/bulk';

  /**
   * Copy a range of trainings and its options to another season.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyTrainingToSeasonBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyTrainingToSeasonBulk$Response(
    params?: {
  
    /**
     * An object that contains the ids of the trainings that should be copied and the id of the season to use.
     */
    body?: TrainingCopyBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.CopyTrainingToSeasonBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Copy a range of trainings and its options to another season.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyTrainingToSeasonBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyTrainingToSeasonBulk(
    params?: {
  
    /**
     * An object that contains the ids of the trainings that should be copied and the id of the season to use.
     */
    body?: TrainingCopyBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.copyTrainingToSeasonBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `getIcalOfTraining()` */
  static readonly GetIcalOfTrainingPath = '/api/Trainings/{id}/ical';

  /**
   * Export training as ical.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIcalOfTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIcalOfTraining$Response(
    params: {

    /**
     * The training id.
     */
      id: string;

    /**
     * The option ids.
     */
      selectedOptions?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingsService.GetIcalOfTrainingPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('selectedOptions', params.selectedOptions, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export training as ical.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIcalOfTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIcalOfTraining(
    params: {

    /**
     * The training id.
     */
      id: string;

    /**
     * The option ids.
     */
      selectedOptions?: Array<string>;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.getIcalOfTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
