import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcStandInPeople]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: StandInPeopleDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class StandInPeopleDirective {
  static absenteeReplacementNotSame(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const absentee = control.get('absentee');
      const replacement = control.get('replacement');
      if (absentee?.value && replacement?.value && absentee.value.email == replacement.value.email) {
        return { sameEmail: true };
      }
      return null;
    };
  }
}
