<div class="flex justify-between">
  <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input
      id="search"
      type="text"
      placeholder="Search"
      class="md:min-w-[20rem] max-w-[8.5rem] md:max-w-xl"
      pInputText
      [formControl]="searchControl"
    />
  </span>
</div>
