import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '@euricom/angular-shared';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const redirect = this._router.lastSuccessfulNavigation?.finalUrl ?? this._router.createUrlTree(['/trainings']);
    const allowedRoles = next.data['roles'] as Array<string>;
    const userRoles = this._authenticationService.user()?.roles;

    if (userRoles?.some((role) => allowedRoles.includes(role))) {
      return true;
    }
    return redirect;
  }
}
