import { provideSentry } from '@/shared/modules/sentry.module';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeBE from '@angular/common/locales/nl-BE';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAuthentication, provideConfiguration, provideCustomConfigurations } from '@euricom/angular-shared';
import { provideTrainingCatalogApi } from '@euricom/angular-training-catalog-api';
import { MarkdownModule } from 'ngx-markdown';
import { appRoutes } from './app.routes';
import { appSettings } from './app.settings';
import { loggerInterceptor } from './shared/interceptors/http-error-response.interceptor';
import { provideConfirmation } from './shared/modules/confirmation.module';
import { provideToaster } from './shared/modules/toaster.module';
import { provideQueryClientService } from './shared/utils/query';
import { AppRoles } from './shared/utils/roles';
registerLocaleData(localeBE, 'nl-BE');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([BrowserModule, BrowserAnimationsModule, MarkdownModule.forRoot()]),
    provideHttpClient(withInterceptors([loggerInterceptor])),
    provideConfiguration('assets/app-settings.json'),
    provideCustomConfigurations(appSettings),
    provideAuthentication({
      adminRole: AppRoles.Administrator,
      humanResourcesRole: AppRoles.HumanResources,
      catalogResponsibleRole: AppRoles.CatalogResponsible,
      practiceManagerRole: AppRoles.PracticeManager,
    }),
    provideTrainingCatalogApi(),
    provideSentry(),
    provideRouter(appRoutes),
    provideToaster(),
    provideConfirmation(),
    provideQueryClientService({
      // @tanstack/query queryClient config
      // https://tanstack.com/query/latest/docs/vue/reference/QueryClient
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 5, // 5 min
        },
      },
    }),
  ],
};
