<div class="flex justify-between">
  <span class="p-input-icon-left">
    <i class="pi pi-search"></i>
    <input
      id="search"
      type="text"
      placeholder="Search"
      class="md:min-w-[20rem] max-w-[8.5rem] md:max-w-xl"
      pInputText
      [formControl]="searchControl"
    />
  </span>
  <div *ngIf="admin" class="flex">
    <p-button
      pTooltip="Copy multiple trainings to another season"
      tooltipPosition="left"
      size="small"
      [disabled]="trainingsSelected === 0"
      (onClick)="copyToSeason.emit()"
      *ngIf="copyVisible"
      label="Copy to season"
    />
    <p-button
      pTooltip="Delete multiple trainings all at once"
      tooltipPosition="left"
      size="small"
      class="ml-2"
      [disabled]="trainingsSelected === 0"
      (onClick)="delete.emit()"
      label="Delete"
    />
    <p-button
      pTooltip="Toggle the active state of multiple trainings all at once"
      tooltipPosition="left"
      size="small"
      class="ml-2"
      [disabled]="trainingsSelected === 0"
      (onClick)="toggleActive.emit()"
      label="Toggle active state"
    />
  </div>
</div>
