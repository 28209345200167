import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tc-info-tooltip',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './info-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTooltipComponent {
  @ViewChild(Tooltip) tooltip: Tooltip | undefined;

  @Input() text = '';

  onClick() {
    if (this.tooltip?.active) {
      this.tooltip?.deactivate();
      return;
    }
    this.tooltip?.activate();
  }
}
