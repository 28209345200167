import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'tc-training-filter-header',
  standalone: true,
  imports: [CommonModule, FormsModule, ChipsModule, ReactiveFormsModule, CardModule, ButtonModule, TooltipModule],
  templateUrl: './training-filter-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFilterHeaderComponent {
  readonly searchControl = new FormControl('', { nonNullable: true });

  @Input()
  set searchValue(value: string) {
    this.searchControl.setValue(value);
  }
  @Input() trainingsSelected?: number;
  @Input() admin = false;
  @Input() copyVisible = true;

  @Output() delete = new EventEmitter();
  @Output() copyToSeason = new EventEmitter();
  @Output() toggleActive = new EventEmitter();

  @Output()
  readonly search = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
}
