<p-panel [header]="header" [toggleable]="true" [collapsed]="isSmallScreen()">
  <p-dropdown
    [options]="options"
    [inputId]="header"
    optionLabel="displayValue"
    [filter]="true"
    filterBy="displayValue"
    [showClear]="true"
    [formControl]="dropdownControl"
    [placeholder]="'None'"
  />
</p-panel>
