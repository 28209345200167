import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { PageTitleService } from '@/shared/services/page-title.service';
import { reviewKeys, ReviewService } from '@/shared/services/review.service';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'tc-my-reviews',
  standalone: true,
  imports: [CommonModule, ButtonModule, CardModule, FormatDisplayPipe, LoaderComponent, SharedModule, TableModule],
  providers: [ReviewService],
  templateUrl: './my-reviews.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyReviewsComponent implements OnInit {
  readonly reviews = useQuery(reviewKeys.mine(), () => {
    return this._reviewsService.getReviewsAsync(true);
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _reviewsService: ReviewService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._pageTitleService.setPageTitle('My reviews');
  }

  onClickReview(reviewId: string) {
    this._router.navigate(['my-reviews', reviewId]);
  }
}
