import { Holiday } from '@/shared/models/holiday';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { HolidayDto } from '@euricom/angular-training-catalog-api/models';
import { HolidaysService as RootHolidayService } from '@euricom/angular-training-catalog-api/services';
import { formatISO } from 'date-fns';
import { firstValueFrom } from 'rxjs';

function mapHoliday(dto: HolidayDto): Holiday {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
  };
}

export const holidayKeys = {
  // all public holidays
  allPublic: ['holidaysPublic'] as const,

  publicById: (id: string | null) => [...holidayKeys.allPublic, id] as const,

  // all school holidays
  allSchool: ['holidaysSchool'] as const,

  schoolById: (id: string | null) => [...holidayKeys.allSchool, id] as const,
};

@Injectable()
export class HolidaysService extends RootHolidayService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  getHolidaysPublicAsync = async (date: Date): Promise<Holiday[]> => {
    const data = await firstValueFrom(this.getHolidaysPublic({ date: formatISO(date) }));
    return data.map(mapHoliday);
  };

  getHolidaysSchoolAsync = async (date: Date): Promise<Holiday[]> => {
    const data = await firstValueFrom(this.getHolidaysSchool({ date: formatISO(date) }));
    return data.map(mapHoliday);
  };
}
