<tc-loader *ngIf="collection.isLoading()" />
<div *ngIf="!collection.isLoading()" class="flex md:flex-row gap-4 p-4 flex-col">
  <div class="w-full">
    <div class="flex flex-col gap-4 pb-4">
      <p-card class="w-full">
        <tc-collection-add
          [collection]="collection.data()"
          (newCollections)="onUpsertCollection($event)"
          [loadingUpsert]="upsertCollectionMutation.isLoading()"
        />
      </p-card>
    </div>
  </div>
  <div *ngIf="collectionId" class="flex flex-col gap-4 pb-4 md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card class="w-full">
      <tc-collection-delete (delete)="onDeleteCollection($event)" />
    </p-card>
  </div>
</div>
<div class="w-full p-4" *ngIf="trainings.data()">
  <p-card>
    <tc-training-admin-table [trainings]="trainings.data() ?? []" (clickTraining)="onClickTraining($event)" />
  </p-card>
</div>
