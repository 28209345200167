<div class="flex justify-between">
  <h1 class="text-2xl">Delete training</h1>
  <tc-info-tooltip
    text="The training can only be deleted when there are no related bookings."/>
</div>
<p-divider/>
<p *ngIf="currentUser && currentUser.isAdmin">Force delete will also delete bookings</p>
<div class="w-full mt-4 flex flex-row">
  <p-button label="Delete" (onClick)="onConfirmDelete()" size="small" [outlined]="true" severity="danger"/>
  <div class="flex justify-center items-center w-full" *ngIf="currentUser && currentUser.isAdmin">
    <p-checkbox [formControl]="formControl" [value]="true" inputId="force"/>
    <label class="ml-2 hover:cursor-pointer" for="force">Force Delete</label>
  </div>
</div>
