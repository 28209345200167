import { Signal, isSignal } from '@angular/core';
import { cloneDeep } from './object';

export type MaybeSignal<T> = T | Signal<T>;

export type UnwrapSignal<T> = T extends Signal<infer V> ? V : T

export function unsignal<T>(signal: MaybeSignal<T>): T {
  return isSignal(signal) ? signal() : signal;
}

export function cloneDeepUnsignal<T>(obj: T): UnwrapSignal<T> {
  return cloneDeep(obj, (val) => {
    if (isSignal(val)) {
      return cloneDeepUnsignal(unsignal(val))
    }
    return undefined;
  }) as UnwrapSignal<typeof obj>
}
