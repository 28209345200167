import { Injectable } from '@angular/core';
import { Configurations } from '../configuration/configuration';

@Injectable()
export class AuthenticationSettings {
  public static readonly key = 'authentication';
  public readonly clientId!: string;
  public readonly authority!: string;
  public readonly redirectUri!: string;
  public readonly postLogoutRedirectUri!: string;
  public readonly loginFailedRoute!: string;
  public readonly defaultScopes!: string[];
  public readonly apis!: [
    {
      readonly uri: string;
      readonly scopes: string[];
    },
  ];

  public static protectedResourceMap(settings: AuthenticationSettings): Map<string, string[]> {
    return settings.apis.reduce((acc, next) => {
      acc.set(next.uri, next.scopes);
      return acc;
    }, new Map<string, string[]>());
  }

  public static scopes(settings: AuthenticationSettings): string[] {
    return [settings.defaultScopes, ...this.protectedResourceMap(settings).values()].reduce(
      (acc, next) => [...acc, ...next],
      new Array<string>(),
    );
  }
}

export const authenticationSettings: Configurations = [
  { provide: AuthenticationSettings, key: AuthenticationSettings.key },
];
