import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { Season } from '@/shared/models/season';
import { SeasonsService } from '@/shared/services/season.service';
import { getSeasonFilters } from '@/trainings/pages/training-upsert/helpers';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  standalone: true,
  imports: [
    DividerModule,
    ButtonModule,
    CheckboxModule,
    NgIf,
    DropdownModule,
    ReactiveFormsModule,
    InfoTooltipComponent,
  ],
  providers: [SeasonsService],
  selector: 'tc-training-copy-to-season',
  templateUrl: './training-copy-to-season.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingCopyToSeasonComponent {
  readonly formControl = new FormControl<{ key: string; displayValue: string } | undefined>(undefined);

  @Input() seasons: Season[] = [];

  readonly seasonOptions = computed(() => {
    return [
      ...getSeasonFilters(this.seasons).filter(
        (x) => x.key !== this.currentSeason?.id && parseInt(x.displayValue) >= new Date().getFullYear(),
      ),
    ];
  });

  @Input() currentSeason: Season | undefined;

  @Output() copyToSeason = new EventEmitter<string>();

  constructor(private _confirmationService: ConfirmationService) {}

  onCopyToSeason() {
    this.copyToSeason.emit(this.formControl.value?.key ?? '');
  }

  onConfirmCopyToSeason() {
    this._confirmationService.confirm({
      message: `Are you sure you want to copy this training to season ${this.formControl.value?.displayValue ?? ''}?`,
      header: 'Confirmation copy to season',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.onCopyToSeason(),
    });
  }
}
