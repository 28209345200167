import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { Booking } from '@/shared/models/booking';
import { Season } from '@/shared/models/season';
import { Training } from '@/shared/models/training';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'tc-training-calendar-timeline',
  standalone: true,
  imports: [CommonModule, LoaderComponent, CardModule, DividerModule, DatePipe],
  templateUrl: './training-calendar-timeline.component.html',
})
export class TrainingCalendarTimelineComponent {
  readonly months = ['Jan - Feb - Mars', 'April - May - Jun', 'Jul - Aug - Sept', 'Oct - Nov - Dec'];

  private _trainings = signal<Training[]>([]);
  private _bookings = signal<Booking[]>([]);
  private _seasonYear = signal<Season | undefined>(undefined);

  @Input() set trainings(value: Training[]) {
    this._trainings.set(value);
  }

  get trainings() {
    return this._trainings();
  }

  @Input() set bookings(value: Booking[]) {
    this._bookings.set(value);
  }
  get bookings() {
    return this._bookings();
  }

  @Input() set season(value: Season | undefined) {
    this._seasonYear.set(value);
  }
  get seasonYear() {
    return this._seasonYear();
  }

  constructor(private _router: Router) {}

  navigateTrainingDetails(training: Training) {
    this._router.navigate(['/trainings', training.id]);
  }

  navigateBookingDetails(training: Training) {
    const booking = this.bookings.find((b) => b.training.id === training.id);
    if (!booking) {
      this._router.navigate(['/trainings', training.id]);
      return;
    }
    this._router.navigate(['/bookings', booking.id]);
  }

  getTrainingsPerQuarter(index: number) {
    return this._trainings().filter(
      (t) => (t.startDate?.getMonth() ?? 0) >= index * 3 && (t.startDate?.getMonth() ?? 0) < index * 3 + 3,
    );
  }

  isTrainingBooked(trainingId: string) {
    return this._bookings()
      .map((b) => b.training.id)
      .includes(trainingId);
  }
}
