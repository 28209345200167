import { BookingDetail } from '@/shared/models/bookingDetail';
import { TrainingOption } from '@/shared/models/training-option';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { useAuth } from '@euricom/angular-shared';
import { DividerModule } from 'primeng/divider';
import { TrainingOptionComponent } from '../training-option/training-option.component';

type SelectedTrainingOption = {
  option: TrainingOption;
  isSelected: boolean;
};

@Component({
  selector: 'tc-training-options',
  standalone: true,
  imports: [CommonModule, TrainingOptionComponent, DividerModule],
  templateUrl: './training-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingOptionsComponent {
  totalCredits = 0;
  viewOptions: SelectedTrainingOption[] = [];
  disabled = this.isDisabled();

  readonly user = useAuth().user();
  private _selectedOptions: string[] = [];
  private _options: TrainingOption[] = [];

  @Input()
  set options(val: TrainingOption[] | null | undefined) {
    const undefinedValue = Number.POSITIVE_INFINITY;
    this._options =
      val?.sort((a, b) => {
        const deadlineComparison =
          (a.deadline?.valueOf() ?? undefinedValue) - (b.deadline?.valueOf() ?? undefinedValue);
        if (deadlineComparison !== 0) {
          return deadlineComparison;
        }
        const startDateComparison =
          (a.startDate?.valueOf() ?? undefinedValue) - (b.startDate?.valueOf() ?? undefinedValue);
        if (startDateComparison !== 0) {
          return startDateComparison;
        }
        return (a.name ?? '').localeCompare(b.name ?? '');
      }) ?? [];

    this.viewOptions = this._toSelectedTrainingOptions(this._options ?? [], this._selectedOptions ?? []);
    this.totalCredits = this._calculateTotalCredits();

    if (val?.length === 1) {
      this.selectedOptionsChange.emit([val[0].id]);
    }
  }

  @Input() booking: Signal<BookingDetail | undefined> = signal(undefined);

  @Input()
  set selectedOptions(value: string[]) {
    this._selectedOptions = value ?? [];
    this.viewOptions = this._toSelectedTrainingOptions(this._options ?? [], value ?? []);
    this.totalCredits = this._calculateTotalCredits();
  }

  @Output()
  selectedOptionsChange = new EventEmitter<string[]>();

  onOptionSelected(option: TrainingOption) {
    if (this._selectedOptions.includes(option.id)) {
      this._selectedOptions = this._selectedOptions.filter((id) => id !== option.id);
    } else {
      this._selectedOptions = [...this._selectedOptions, option.id];
    }

    this.selectedOptionsChange.emit(this._selectedOptions);
    this.viewOptions = this._toSelectedTrainingOptions(this._options ?? [], this._selectedOptions);
    this.totalCredits = this._calculateTotalCredits();
  }

  private _toSelectedTrainingOptions(options: TrainingOption[], selectedOptions: string[]): SelectedTrainingOption[] {
    return options.map((option) => {
      return {
        option,
        isSelected: selectedOptions.includes(option.id),
      };
    });
  }

  private _calculateTotalCredits() {
    return this._selectedOptions.reduce((total, optionId) => {
      const option = this._options?.find((option) => option.id === optionId);
      return total + (option?.credits ?? 0);
    }, 0);
  }

  isDisabled() {
    return computed(() => {
      const status = this.booking()?.status;

      if (!status) return false;
      if (['Booked', 'Cancelled', 'Rejected', 'Verified'].includes(status)) {
        return true;
      }
      return (
        !(this.user?.isPracticeManager || this.user?.isHumanResources || this.user?.isAdmin) &&
        ['Approved', 'Requested', 'PendingBooking'].includes(status)
      );
    });
  }

  showDates(startDate?: Date, endDate?: Date) {
    const seasonStartDate = this.booking()?.training.season.startDate;
    const seasonEndDate = this.booking()?.training.season.endDate;
    return !(
      startDate?.toDateString() == seasonStartDate?.toDateString() &&
      endDate?.toDateString() == seasonEndDate?.toDateString()
    );
  }
}
