import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { User } from '@/shared/models/user';
import { bookingKeys, BookingsService } from '@/shared/services/booking.service';
import { NavigationTrainingAdminService } from '@/shared/services/navigation-training-admin.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { trainingKeys, TrainingsService } from '@/shared/services/training.service';
import { userKeys, UsersService } from '@/shared/services/user.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { TrainingAddForceBookingComponent } from '@/trainings/components/training-add-force-booking/training-add-force-booking.component';
import { TrainingAddComponent } from '@/trainings/components/training-add/training-add.component';
import { TrainingCopyToSeasonComponent } from '@/trainings/components/training-copy-to-season/training-copy-to-season';
import { TrainingDeleteComponent } from '@/trainings/components/training-delete/training-delete.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { TrainingUpsertDto } from '@euricom/angular-training-catalog-api/models';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'tc-training-upsert',
  standalone: true,
  imports: [
    //angular
    CommonModule,
    //custom
    TrainingAddComponent,
    TrainingDeleteComponent,
    LoaderComponent,
    //primeNG
    CardModule,
    TrainingCopyToSeasonComponent,
    TrainingAddForceBookingComponent,
  ],
  providers: [TrainingsService, SeasonsService, BookingsService, UsersService],
  templateUrl: './training-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingUpsertComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  private readonly _trainingId = this._route.snapshot.paramMap.get('id');

  readonly user = useAuth().user;
  readonly forUser: WritableSignal<User | null> = signal(null);

  readonly training = useQuery(
    computed(() => trainingKeys.byId(this._trainingId)),
    () => {
      return this._trainingsService.getTrainingAsync(this._trainingId ?? '');
    },
    {
      onError: () => this._router.navigate(['/404']),
      enabled: computed(() => !!this._trainingId),
    },
  );

  readonly seasons = useQuery(seasonKeys.detailed, () => {
    return this._seasonsService.getDetailedSeasonsAsync();
  });

  readonly users = useQuery(
    userKeys.all,
    () => {
      return this._usersService.getUsersAsync();
    },
    { enabled: !!this.user()?.isAdmin },
  );

  readonly deleteTrainingMutation = useMutation(this._trainingsService.deleteTrainingAsync, {
    onSuccess: () => {
      this._toaster.success('Deleted.');
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this._navigation.back();
    },
    onError: () => {
      this._toaster.error('The training could not be deleted!');
    },
  });

  readonly upsertTraining = useMutation(this._trainingsService.upsertTrainingAsync, {
    onSuccess: () => {
      this._toaster.success('Saved.');
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this._navigation.back();
    },
  });

  readonly copyTrainingToSeason = useMutation(this._trainingsService.copyTrainingToSeasonAsync, {
    onSuccess: (training) => {
      this._toaster.success('Copy successful');
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this._router.navigate(['/admin/trainings', training.id]);
    },
    onError: () => {
      this._toaster.error('Copy not successful');
    },
  });

  readonly addBookingForceMutation = useMutation(this._bookingsService.addBookingAsync, {
    onSuccess: (booking) => {
      this._toaster.success(`The booking has been added to ${booking.requesterName}'s plan!`);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.byId(this._trainingId));
      this._navigation.back();
    },
  });

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _trainingsService: TrainingsService,
    private _toaster: ToastService,
    private _pageTitleService: PageTitleService,
    private _seasonsService: SeasonsService,
    private _bookingsService: BookingsService,
    private _usersService: UsersService,
    private _navigation: NavigationTrainingAdminService,
  ) {}

  onDeleteTraining(withForce = false) {
    const id = this._trainingId;
    if (!id) return;
    this.deleteTrainingMutation.mutate({ trainingId: id, withForce: withForce });
  }

  onCopyTrainingToSeason(seasonId: string) {
    this.copyTrainingToSeason.mutate({ trainingId: this._trainingId ?? '', seasonId: seasonId });
  }

  onUpsertTraining(training: TrainingUpsertDto) {
    this.upsertTraining.mutate(training);
  }

  onAddBookingForce() {
    this.addBookingForceMutation.mutate({
      trainingId: this.training.data()?.id ?? '',
      addForUser: this.forUser() ?? undefined,
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle(`Training`);
  }
}
