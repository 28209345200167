import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'tc-collection-filter-header',
  standalone: true,
  imports: [CommonModule, ButtonModule, InputTextModule, ReactiveFormsModule],
  templateUrl: './collection-filter-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionFilterHeaderComponent {
  readonly searchControl = new FormControl('', { nonNullable: true });

  @Input()
  set searchValue(value: string) {
    this.searchControl.setValue(value);
  }

  @Output() readonly search = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
}
