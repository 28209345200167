<div class="col-end-1 mr-2">
  <ng-container *ngIf="isDisabled()">
    <p-checkbox [disabled]="true" [binary]="true" [ngModel]="selected"></p-checkbox>
  </ng-container>

  <ng-container *ngIf="!isDisabled()">
    <p-checkbox [(ngModel)]="selected" (click)="selectEvent.emit(option)" [binary]="true" [inputId]="option.id" />
  </ng-container>
</div>

<div
  class="flex justify-between gap-4 flex-grow-0 col-span-3 max-w-full"
  [ngClass]="{ 'opacity-40 italic': isDisabled() && !selected }"
>
  <div class="col-span-2 flex-grow-0">
    <label [for]="option.id" [ngClass]="{ 'cursor-pointer': !isDisabled() }">{{ option.name }}</label>
    <p class="text-secondary text-sm">{{ option.description }}</p>
    <div class="text-sm">
      <p *ngIf="option.startDate && option.endDate && showDates">
        <i class="pi pi-calendar mr-1"></i>{{ option.startDate | fDate : 'longDate' }} -
        {{ option.endDate | fDate : 'longDate' }}
      </p>

      <p *ngIf="option.deadline">
        Deadline: <i class="pi pi-calendar mr-1"></i>{{ option.deadline | fDate : 'longDate' }}
      </p>
      <div *ngIf="user?.isAdmin || user?.isHumanResources" class="flex text-secondary">
        <p>Cost: €{{ option.cost }}</p>
        <p *ngIf="option.halfDays">, Days: {{ option.halfDays / 2 }}</p>
      </div>
    </div>
  </div>

  <div class="flex flex-col text-xs gap-1 mt-1"></div>

  <div class="flex justify-start w-14 min-w-[3.5rem]">
    <p><i class="pi pi-credit-card mr-1"></i>{{ option.credits }}</p>
  </div>
</div>
