import { EnvironmentProviders, Injectable, NgModule, importProvidersFrom } from '@angular/core';
import { ConfigurationModule, provideCustomConfigurations } from '@euricom/angular-shared';
import { ApiModule, TrainingCatalogApiConfiguration } from './lib';

@Injectable()
export class TrainingCatalogApiSettings {
  public static readonly key = 'trainingCatalogApi';

  public readonly baseUrl!: string;
}

@NgModule({
  imports: [ConfigurationModule],
  exports: [],
  declarations: [],
  providers: [
    ApiModule,
    provideCustomConfigurations([
      {
        key: TrainingCatalogApiSettings.key,
        provide: TrainingCatalogApiSettings,
      },
    ]),
    {
      provide: TrainingCatalogApiConfiguration,
      useFactory: (settings: TrainingCatalogApiSettings) => {
        const configuration = new TrainingCatalogApiConfiguration();
        configuration.rootUrl = settings.baseUrl;
        return configuration;
      },
      deps: [TrainingCatalogApiSettings],
    },
  ],
})
export class TrainingCatalogApiModule {}

export function provideTrainingCatalogApi(): EnvironmentProviders {
  return importProvidersFrom(TrainingCatalogApiModule);
}
