<tc-loader *ngIf="seasons.isLoading() || trainings.isLoading() || responsibles.isLoading()" />
<div
  class="flex flex-col lg:flex-row gap-4 p-4 min-h-full w-full max-w-full"
  *ngIf="!seasons.isLoading() && !trainings.isLoading() && !responsibles.isLoading()"
>
  <div
    *ngIf="seasonOptions().length > 0 || formats().length > 0"
    class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4"
  >
    <tc-filter-facet
      type="single"
      header="Season"
      [options]="seasonOptions()"
      (selectedChange)="seasonFilters.set($event)"
      [selected]="seasonFilters()"
      [default]="[seasonOptions()[0].key]"
      class="w-full"
      *ngIf="seasonOptions().length > 1"
    />
    <tc-filter-facet
      type="multiple"
      header="State"
      [options]="states"
      (selectedChange)="stateFilters.set($event)"
      [selected]="stateFilters()"
      class="w-full"
      *ngIf="trainings.data()!.length > 0"
    />
    <tc-filter-facet
      type="multiple"
      header="Domain"
      [options]="domains()"
      (selectedChange)="domainFilters.set($event)"
      [selected]="domainFilters()"
      class="w-full"
      *ngIf="domains().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      header="Format"
      [options]="formats()"
      (selectedChange)="formatFilters.set($event)"
      [selected]="formatFilters()"
      class="w-full"
      *ngIf="formats().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      storageId="training-admin-responsibles"
      header="Responsible"
      [options]="responsibles.data() ?? []"
      (selectedChange)="responsibleFilters.set($event)"
      [selected]="responsibleFilters()"
      class="w-full"
      *ngIf="responsibles.data()!.length > 0 && trainings.data()!.length > 0"
    />
  </div>
  <p-card class="w-full">
    <div class="flex justify-between">
      <p-button label="Add" size="small" (click)="onClickAddTraining()" />
    </div>
    <p-divider />
    <tc-training-filter-header
      *ngIf="seasons.data()"
      (search)="onSearchTrainings($event)"
      (delete)="onDelete()"
      (copyToSeason)="onCopyToSeason()"
      (toggleActive)="onToggleActive()"
      [admin]="true"
      [searchValue]="searchValue()"
      [trainingsSelected]="selectedTrainings().length"
      [copyVisible]="seasons.data()!.length > 1"
    />
    <p-divider />
    <tc-training-admin-table
      [trainings]="filteredTrainings()"
      [tableSort]="sort()"
      [selectedTrainings]="selectedTrainings()"
      (selectTraining)="onSelectTraining($event)"
      (clickTraining)="onClickTraining($event)"
      (sortChanges)="onSortChanges($event.sort, $event.order)"
    />
  </p-card>
</div>

<p-confirmDialog
  #cd
  key="dialogWithInputForCopyToSeason"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
  #dialog
>
  <ng-template pTemplate="message">
    <div>
      <div class="flex content-center">
        <i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem"></i>
        <p class="mb-4">All selected trainings will be copied to the season.</p>
      </div>
      <label for="seasons">Season<span class="text-error">*</span></label>
      <p-dropdown
        inputId="seasons"
        [options]="filteredSeasonOptions()"
        [formControl]="selectedSeason"
        optionLabel="displayValue"
        [appendTo]="dialog"
        placeholder="Select a season"
      />
      <p class="text-error" *ngIf="selectedSeason.dirty && selectedSeason.hasError('required')">Season is required.</p>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button label="Cancel" size="small" [outlined]="true" (onClick)="cd.reject()" />
    <p-button label="Copy" size="small" (onClick)="onSubmitCopyToSeason()" />
  </ng-template>
</p-confirmDialog>
