import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'tc-season-delete',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, InfoTooltipComponent],
  templateUrl: './season-delete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonDeleteComponent {
  @Output() deleteSeason = new EventEmitter<void>();

  constructor(private _confirmationService: ConfirmationService) {}

  onDelete() {
    this._confirmationService.confirm({
      message: `Are you sure you want to delete this season?`,
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteSeason.emit(),
    });
  }
}
