<p-toast position="bottom-right" [breakpoints]="{ '480px': { width: '100%', right: '0', left: '0' } }">
  <ng-template let-message pTemplate="message">
    <div class="grid grid-cols-5 gap-1 w-full">
      <div class="col-span-1 flex justify-center items-center">
                <span style="font-size: 1.5rem" class="pi"
                      [ngClass]="{'pi-check':message.severity === 'success','pi-times-circle':message.severity === 'error','pi-info-circle':message.severity === 'info','pi-exclamation-triangle':message.severity === 'warn'}"></span>

      </div>
      <div class="col-span-4 p-toast-message-text">
        <div class="p-toast-summary">{{message.summary}}</div>
        <div class="p-toast-detail">{{message.detail}}</div>
        <p *ngIf="message.severity === 'error' && message.data" (click)="onCopyToClipboard(message.data)"
           class="font-medium underline hover:no-underline hover:cursor-pointer">Copy details</p>
      </div>
    </div>
  </ng-template>
</p-toast>
