/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { TrainingCollectionDto } from '../models/training-collection-dto';
import { TrainingCollectionUpsertDto } from '../models/training-collection-upsert-dto';

@Injectable({ providedIn: 'root' })
export class TrainingCollectionsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTrainingCollections()` */
  static readonly GetTrainingCollectionsPath = '/api/TrainingCollections';

  /**
   * Retrieves a list of training collections.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingCollections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCollections$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TrainingCollectionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingCollectionsService.GetTrainingCollectionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrainingCollectionDto>>;
      })
    );
  }

  /**
   * Retrieves a list of training collections.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingCollections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCollections(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<TrainingCollectionDto>> {
    return this.getTrainingCollections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingCollectionDto>>): Array<TrainingCollectionDto> => r.body)
    );
  }

  /** Path part for operation `upsertTrainingCollection()` */
  static readonly UpsertTrainingCollectionPath = '/api/TrainingCollections';

  /**
   * Add or update a training collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertTrainingCollection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTrainingCollection$Response(
    params?: {
  
    /**
     * The training collection upsert data.
     */
    body?: TrainingCollectionUpsertDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingCollectionDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingCollectionsService.UpsertTrainingCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingCollectionDto>;
      })
    );
  }

  /**
   * Add or update a training collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertTrainingCollection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTrainingCollection(
    params?: {
  
    /**
     * The training collection upsert data.
     */
    body?: TrainingCollectionUpsertDto
    },
    context?: HttpContext
  ): Observable<TrainingCollectionDto> {
    return this.upsertTrainingCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingCollectionDto>): TrainingCollectionDto => r.body)
    );
  }

  /** Path part for operation `getTrainingCollection()` */
  static readonly GetTrainingCollectionPath = '/api/TrainingCollections/{id}';

  /**
   * Retrieves a training collection by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCollection$Response(
    params: {

    /**
     * The training collection id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TrainingCollectionDto>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingCollectionsService.GetTrainingCollectionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainingCollectionDto>;
      })
    );
  }

  /**
   * Retrieves a training collection by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCollection(
    params: {

    /**
     * The training collection id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<TrainingCollectionDto> {
    return this.getTrainingCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingCollectionDto>): TrainingCollectionDto => r.body)
    );
  }

  /** Path part for operation `deleteTrainingCollection()` */
  static readonly DeleteTrainingCollectionPath = '/api/TrainingCollections/{id}';

  /**
   * Deletes a training collection and all trainings in it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTrainingCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrainingCollection$Response(
    params: {

    /**
     * The training collection id.
     */
      id: string;

    /**
     * If true, bookings will be deleted.
     */
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TrainingCollectionsService.DeleteTrainingCollectionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a training collection and all trainings in it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTrainingCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrainingCollection(
    params: {

    /**
     * The training collection id.
     */
      id: string;

    /**
     * If true, bookings will be deleted.
     */
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteTrainingCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
