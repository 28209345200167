<p-table
  #collectionTable
  [value]="collections"
  styleClass="p-datatable-sm hidden md:block"
  [rowHover]="true"
  [sortOrder]="tableSort?.order ?? -1"
  [sortField]="tableSort?.sort"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="title" (click)="setSort('title')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Name</div>
          <p-sortIcon field="title" />
        </div>
      </th>
      <th pSortableColumn="format" (click)="setSort('format')">
        <div class="flex items-center">
          <div class="max-w-[90%] truncate ...">Format</div>
          <p-sortIcon field="format" />
        </div>
      </th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-collection>
    <tr class="w-fit overflow-x-auto cursor-pointer" (click)="clickCollection.emit(collection.id)">
      <td>{{ collection.title }}</td>
      <td>{{ collection.format | fDisplay }}</td>
      <td (click)="stopPropagation($event)">
        <p class="text-info hover:underline" (click)="clickNewTraining.emit(collection.id)">Add</p>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="collections.length === 0">
  <p class="text-center font-semibold mt-3">No training collections found</p>
</div>

<p-card class="w-full md:hidden">
  <p-table responsiveLayout="stack" [value]="collections" [breakpoint]="'1000px'">
    <ng-template pTemplate="body" let-collection>
      <tr (click)="clickCollection.emit(collection.id)">
        <td style="padding: 0.3rem">
          <div class="grid grid-cols-[8.5rem_1fr]">
            <span class="p-column-title font-bold w-1/3">Title:</span>
            <span class="self-start">{{ collection.title }}</span>
          </div>
        </td>
        <td style="padding: 0.3rem">
          <div class="grid grid-cols-[8.5rem_1fr]">
            <span class="p-column-title font-bold">Format:</span>
            {{ collection.format }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="collections.length === 0">
    <p class="text-center font-semibold mt-3">No training collections found</p>
  </div>
</p-card>
