import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { SortComponent } from '@/shared/components/sort/sort.component';
import { Booking } from '@/shared/models/booking';
import { bookingKeys, BookingsService } from '@/shared/services/booking.service';
import { holidayKeys, HolidaysService } from '@/shared/services/holidays.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { getDomains, getFormats, trainingKeys, TrainingsService } from '@/shared/services/training.service';
import { useMutation, useQuery } from '@/shared/utils/query';
import { TrainingCalendarTimelineComponent } from '@/trainings/components/training-calendar-timeline/training-calendar-timeline.component';
import { TrainingCalendarYearComponent } from '@/trainings/components/training-calendar-year/training-calendar-year.component';
import { TrainingFilterHeaderComponent } from '@/trainings/components/training-filter-header/training-filter-header.component';
import { TrainingsDataViewComponent } from '@/trainings/components/trainings-data-view/trainings-data-view.component';
import {
  filterAndSortTrainings,
  filterBookings,
  filterHolidays,
  getSeasonFilters,
} from '@/trainings/pages/trainings-calendar/helpers';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, OnInit, signal } from '@angular/core';
import { useAuth } from '@euricom/angular-shared';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';

type SearchParams = {
  season: string;
  other: string[];
  domain: string[];
  format: string[];
  display: string;
};

@Component({
  selector: 'tc-trainings-calendar',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    DividerModule,
    FilterFacetComponent,
    TrainingCalendarTimelineComponent,
    ButtonModule,
    LoaderComponent,
    SortComponent,
    TrainingFilterHeaderComponent,
    TrainingsDataViewComponent,
    TrainingCalendarYearComponent,
  ],
  providers: [TrainingsService, SeasonsService, BookingsService, HolidaysService, SearchParamsService],
  templateUrl: './trainings-calendar.component.html',
})
export class TrainingsCalendarComponent implements OnInit {
  filterOptionsOther = computed(() => {
    const user = this.user;
    const display = this.display();
    const options = [{ displayValue: 'My Bookings', key: '0' }];
    if (display === 'Calendar') {
      options.push({ displayValue: 'Belgian Holidays', key: '1' });
      options.push({ displayValue: 'Belgian School Holidays', key: '2' });
    }
    if (user?.isAdmin || user?.isCatalogResponsible || user?.isHumanResources || user?.isPracticeManager)
      options.push({ displayValue: 'Draft', key: '3' });
    return options;
  });

  readonly user = useAuth().user();
  searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly seasonFilters = signal(this.searchParams.season);
  readonly otherFilters = signal<string[]>(this.searchParams.other);
  readonly domainFilters = signal<string[]>(this.searchParams.domain);
  readonly formatFilters = signal<string[]>(this.searchParams.format);
  readonly display = signal(this.searchParams.display ?? 'Calendar');

  readonly trainings = useQuery(trainingKeys.availableCalendar(this.seasonFilters), async () => {
    const seasons = this.seasonFilters() ? [this.seasonFilters()] : undefined;
    const trainings = await this._trainingService.getTrainingsAsync({
      seasons: seasons,
    });
    return trainings
      .filter((item) => item.showOnCalendar)
      .sort((a, b) =>
        (a.startDate?.getTime() ?? 0) - (b.startDate?.getTime() ?? 0) === 0
          ? (a.endDate?.getTime() ?? 0) - (b.endDate?.getTime() ?? 0)
          : (a.startDate?.getTime() ?? 0) - (b.startDate?.getTime() ?? 0),
      );
  });

  readonly bookings = useQuery(bookingKeys.listMyCalendar(), async () => {
    const bookings = await this._bookingsService.getBookingsAsync({ myBookings: true });
    return bookings.filter((item) => item.training.showOnCalendar && !['Cancelled', 'Rejected'].includes(item.status));
  });

  readonly seasons = useQuery(seasonKeys.all, () => {
    return this._seasonsService.getSeasonsAsync();
  });

  readonly filteredTrainings = computed(() => {
    return filterAndSortTrainings(
      this.trainings.data,
      this.seasonFilters,
      this.domainFilters,
      this.formatFilters,
      this.otherFilters,
    );
  });

  readonly filteredBookings = computed(() => {
    return filterBookings(this.bookings.data, this.seasonFilters, this.otherFilters);
  });

  readonly filteredPublicHolidays = computed(() => {
    return filterHolidays(this.publicHolidays.data, this.otherFilters, '1');
  });

  readonly filteredSchoolHolidays = computed(() => {
    return filterHolidays(this.schoolHolidays.data, this.otherFilters, '2');
  });

  readonly seasonOptions = computed(() => {
    return getSeasonFilters(this.seasons.data);
  });

  readonly domains = computed(() => {
    return getDomains(this.trainings.data);
  });

  readonly formats = computed(() => {
    return getFormats(this.trainings.data);
  });

  currentSeason = computed(() => {
    return this.seasons.data()?.find((season) => season.id === this.seasonFilters());
  });

  readonly publicHolidays = useQuery(
    holidayKeys.publicById(this.currentSeason()?.id ?? ''),
    async () => {
      return this._holidaysService.getHolidaysPublicAsync(new Date(`${this.currentSeason()?.year}-01-01`));
    },
    { enabled: computed(() => !!this.currentSeason()), cacheTime: 0 },
  );

  readonly schoolHolidays = useQuery(
    holidayKeys.schoolById(this.currentSeason()?.id ?? ''),
    async () => {
      return this._holidaysService.getHolidaysSchoolAsync(new Date(`${this.currentSeason()?.year}-01-01`));
    },
    { enabled: computed(() => !!this.currentSeason()), cacheTime: 0 },
  );

  readonly getIcalOfBookingMutation = useMutation(this._bookingsService.getIcalOfBookingAsync, {
    onSuccess: (data) => {
      this._toaster.success('Successfully downloaded ical file');
      const blob = new Blob([data], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'calendar.ics';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    onError: () => {
      this._toaster.error('Unable to download ical file');
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _trainingService: TrainingsService,
    private _seasonsService: SeasonsService,
    private _bookingsService: BookingsService,
    private _holidaysService: HolidaysService,
    private _searchParamService: SearchParamsService,
    private _toaster: ToastService,
  ) {
    // update the query params when the search params change
    effect(() => {
      const queryParams = {
        season: this.seasonFilters(),
        other: this.otherFilters(),
        domain: this.domainFilters(),
        format: this.formatFilters(),
        display: this.display(),
      };
      _searchParamService.setQueryParams(queryParams);
    });
    effect(
      () => {
        this.currentSeason();
        this.publicHolidays.refetch();
        this.schoolHolidays.refetch();
      },
      { allowSignalWrites: true },
    );
  }
  ngOnInit() {
    this._pageTitleService.setPageTitle('Calendar');
  }

  onSetSeasons(season: string[]) {
    this.seasonFilters.set(season[0]);
  }

  onGetIcal(booking: Booking) {
    this.getIcalOfBookingMutation.mutate(booking.id);
  }
}
