import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommentRequiredApproveRejectValidatorDirective } from '@/shared/validators/comment-required-approve-reject.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'tc-booking-approve-reject-panel',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, InputTextareaModule, ReactiveFormsModule, InfoTooltipComponent],
  templateUrl: './booking-approve-reject-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingApproveRejectPanelComponent {
  readonly form = this._setupForm();
  readonly user = useAuth().user;
  @Output() readonly approve = new EventEmitter<string>();
  @Output() readonly reject = new EventEmitter<string>();

  @Input() loadingApprove: Signal<boolean> = signal(false);
  @Input() loadingReject: Signal<boolean> = signal(false);
  @Input() pmEmail?: string;

  @Input() set optionSelected(value: boolean) {
    this.form.patchValue({ optionSelected: value });
  }

  @Input() set noPassedOptionSelected(value: boolean) {
    this.form.patchValue({ noPassedOptionSelected: value });
  }
  constructor(private _confirmationService: ConfirmationService) {}

  onAccept() {
    this.form.controls.comment.clearValidators();
    this.form.controls.comment.addValidators([
      Validators.maxLength(255),
      CommentRequiredApproveRejectValidatorDirective.commentRequiredApproveReject(
        'Approve',
        this.pmEmail ?? '',
        this.user()?.email ?? '',
      ),
    ]);
    this.form.controls.comment.updateValueAndValidity();
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;
    const value = this.form.value;

    this.approve.emit(value.comment as string);
  }

  onReject() {
    this.form.controls.comment.clearValidators();
    this.form.controls.comment.addValidators([
      Validators.maxLength(255),
      CommentRequiredApproveRejectValidatorDirective.commentRequiredApproveReject(
        'Reject',
        this.pmEmail ?? '',
        this.user()?.email ?? '',
      ),
    ]);
    this.form.controls.comment.updateValueAndValidity();

    this.form.controls.comment.markAsDirty();
    if (this.form.controls.comment.invalid) return;
    const value = this.form.value;

    this._confirmationService.confirm({
      message: 'Are you sure you want to reject this request?',
      header: 'Confirmation reject',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.reject.emit(value.comment as string),
    });
  }

  private _setupForm() {
    return new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: false,
        validators: [Validators.maxLength(255)],
      }),
      optionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
      noPassedOptionSelected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
    });
  }
}
