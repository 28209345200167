import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { catchError, firstValueFrom, of } from 'rxjs';

export const APP_CONFIGURATION_LOCATION = new InjectionToken<string>(
  'APP_CONFIGURATION_LOCATION'
);

@Injectable()
export class ConfigurationService {
  private readonly http: HttpClient;

  private _configurations: object | null;
  private get configurations(): object {
    if (!this._configurations) {
      throw new Error(`Configuration ${this._location} could not be loaded`);
    }
    return this._configurations;
  }

  constructor(
    httpHandler: HttpBackend,
    @Inject(APP_CONFIGURATION_LOCATION) private _location: string
  ) {
    this.http = new HttpClient(httpHandler);
    this._configurations = null;
  }

  public static factory(
    configurationService: ConfigurationService
  ): () => Promise<void> {
    return () => configurationService.init();
  }

  public getSection<T = unknown>(section: string): T {
    if (!section || section === '') {
      return this.configurations as T;
    }

    const sectionParts = section.split(':');
    let sectionValue = this.configurations;
    for (const sectionKey of sectionParts) {
      const entry = Object.entries(sectionValue).find(
        ([key]) => key === sectionKey
      );
      if (entry == null) {
        throw new Error(`Section ${section} not found`);
      }

      const [_key, value] = entry;
      sectionValue = value;
    }

    return sectionValue as T;
  }

  private async init(): Promise<void> {
    const httpCall = this.http
      .get<object>(this._location)
      .pipe(catchError(() => of(null)));

    const configurations = await firstValueFrom(httpCall);

    if (!configurations) {
      throw new Error(`Configuration ${this._location} could not be loaded`);
    }

    this._configurations = configurations;
  }
}
