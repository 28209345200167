<div class="md:grid grid-cols-2 grid-rows-1 h-screen">
  <div class="relative hidden md:flex justify-center items-center bg-login bg-cover">
    <div class="text-center bg-accent bg-opacity-40 w-5/6 h-fit p-3">
      <p class="text-3xl text-center text-primary-content">Training Catalog</p>
    </div>
  </div>
  <div class="w-screen h-screen flex flex-col justify-center items-center md:w-auto">
    <img class="order-last w-60 p-5 object-cover md:order-first" src="assets/icons/icon-text.png" alt=""/>
    <p class="p-5 text-3xl font-semibold md:hidden">Training Catalog</p>
    <p-button
      icon="pi pi-microsoft"
      styleClass="p-button-secondary"
      label="Sign in with Azure Active Directory"
      (onClick)="onLogin()"
    />
  </div>
</div>
