import { Review } from '@/shared/models/review';
import { ReviewList } from '@/shared/models/review-list';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { Format, ReviewDto, ReviewListDto, ReviewUpdateDto } from '@euricom/angular-training-catalog-api/models';
import { ReviewsService as RootReviewService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';

export type reviewTrainingVariables = {
  id: string;
  message: string;
  rating: number;
};

export function mapReview(dto: ReviewDto): Review {
  return {
    id: dto.id ?? '',
    message: dto.message ?? '',
    rating: dto.rating ?? 0,
    reviewerName: dto.reviewerName ?? '',
    reviewerEmail: dto.reviewerEmail ?? '',
    createdOn: dto.createdOn ? new Date(dto.createdOn) : new Date(),
  };
}

export function mapReviewList(dto: ReviewListDto): ReviewList {
  return {
    id: dto.id ?? '',
    format: dto.format as Format,
    rating: dto.rating ?? 0,
    reviewer: dto.reviewer ?? '',
    message: dto.message ?? '',
    trainingName: dto.trainingName ?? '',
    year: dto.year ?? '',
  };
}

export const reviewKeys = {
  // all reviews
  all: ['reviews'] as const,
  mine: () => [...reviewKeys.all, 'mine'] as const,

  //specific review
  byId: (id: string | null) => [...reviewKeys.all, id] as const,
};

@Injectable()
export class ReviewService extends RootReviewService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  reviewTrainingAsync = async ({ id, message, rating }: reviewTrainingVariables): Promise<string> => {
    await firstValueFrom(this.addReview({ id, message, rating }));
    return id;
  };

  getReviewAsync = async (id: string): Promise<Review> => {
    const data = await firstValueFrom(this.getReview({ id: id }));
    return mapReview(data);
  };

  getReviewsAsync = async (myReviews: boolean): Promise<ReviewList[]> => {
    const data = await firstValueFrom(this.getReviews({ myReviews: myReviews }));
    return data.map(mapReviewList);
  };

  updateReviewAsync = async (variables: ReviewUpdateDto): Promise<Review> => {
    const data = await firstValueFrom(this.updateReview({ body: variables }));
    return mapReview(data);
  };

  deleteReviewAsync = async (id: string): Promise<void> => {
    return await firstValueFrom(this.deleteReview({ id: id }));
  };
}
