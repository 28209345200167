import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import getCurrentBreakpoint from '../utils/breakpoints';

@Injectable({
  providedIn: 'root',
})
export class ScreenGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(): boolean | UrlTree {
    const redirect = this._router.lastSuccessfulNavigation?.finalUrl ?? this._router.createUrlTree(['/trainings']);
    const notAllowedBreakPoints = ['sm', 'unknown', undefined];
    const breakPoint = getCurrentBreakpoint();

    if (!notAllowedBreakPoints.includes(breakPoint)) {
      return true;
    }
    return redirect;
  }
}
