import { StartBeforeEndDirective } from '@/shared/validators/start-before-end.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export function getSeasonForm() {
  const currentYear = new Date().getFullYear();

  return new FormGroup({
    year: new FormControl<number>(currentYear + 1, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(2015), Validators.max(2040), Validators.pattern('^[0-9]{4}$')],
    }),
    date: new FormGroup(
      {
        startDate: new FormControl<Date>(new Date(currentYear + 1, 0, 1), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        endDate: new FormControl<Date>(new Date(currentYear + 1, 11, 16), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      },
      { validators: [StartBeforeEndDirective.startBeforeEnd()] },
    ),
    multiplier: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.01)],
    }),
    goal: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.01), Validators.pattern('[0-9]*')],
    }),
    dayCost: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.01), Validators.pattern('[0-9]*')],
    }),
    daysBeforeDeadlineToGetNotified: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern('[0-9]*')],
    }),
  });
}
