import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { BookingStatus } from '@/shared/models/BookingStatus';
import { BookingForTrainingFollowUp } from '@/shared/models/booking-for-training-follow-up';
import { Comment } from '@/shared/models/comment';
import { TrainingOption } from '@/shared/models/training-option';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';
import { TrainingOptionsComponent } from '../training-options/training-options.component';

@Component({
  selector: 'tc-training-follow-up-detail-options-table',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    InputTextareaModule,
    FormatDisplayPipe,
    StyleClassModule,
    FormatDatePipe,
    LoaderComponent,
    TrainingOptionsComponent,
    StatusDisplayPipe,
  ],
  templateUrl: './training-follow-up-detail-table-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFollowUpDetailOptionsTableComponent {
  @ViewChild('trainingFollowUpDetailOptionsTable') trainingFollowUpDetailOptionsTable: Table | undefined;
  @Input() bookings: BookingForTrainingFollowUp[] = [];
  @Input() options?: TrainingOption[] = [];
  @Input() selectedBookings: BookingForTrainingFollowUp[] = [];

  @Output() verify = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();
  @Output() book = new EventEmitter<{ id: string; comment: string }>();
  @Output() startPending = new EventEmitter<{ id: string; comment?: string }>();
  @Output() update = new EventEmitter<{ id: string; comment: string }>();
  @Output() selectBooking = new EventEmitter<BookingForTrainingFollowUp[]>();

  form = new FormGroup({
    comment: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.maxLength(255)],
    }),
  });

  comments = signal<Comment[]>([]);

  constructor(private _confirmationService: ConfirmationService, private _router: Router) {}

  initializeForm() {
    this.form = new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.maxLength(255)],
      }),
    });
  }
  getSelectedOptions(ids: string[]) {
    return this.options?.filter((o) => ids.includes(o.id));
  }

  onSelect() {
    this.selectBooking.emit(this.selectedBookings);
  }

  onVerify(id: string) {
    const bookingToVerify = this.bookings.find((b) => b.id === id);

    if (bookingToVerify) {
      bookingToVerify.status === 'Verified'
        ? (bookingToVerify.status = 'Booked')
        : (bookingToVerify!.status = 'Verified');
    }
    this.verify.emit(id);
  }

  onBook(id: string) {
    const bookingToBook = this.bookings.find((b) => b.id === id);

    this.comments.set(bookingToBook?.comments ?? []);

    this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

    this._confirmationService.confirm({
      header: 'Book booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Book booking',
      message: bookingToBook?.comments.toString(),
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        if (bookingToBook) {
          bookingToBook.status = 'Booked';
        }
        this.book.emit({ id, comment: this.form.controls.comment.value });
        this.initializeForm();
      },
    });
  }

  onStartPending(id: string) {
    const bookingToStartPending = this.bookings.find((b) => b.id === id);

    this._confirmationService.confirm({
      header: 'Start Pending booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Start Pending booking',
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        if (bookingToStartPending) {
          bookingToStartPending.status = 'PendingBooking';
        }

        this.startPending.emit({ id, comment: this.form.controls.comment.value ?? undefined });
        this.initializeForm();
      },
    });
  }

  onUpdate(id: string) {
    this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

    this._confirmationService.confirm({
      header: 'Update Pending booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Update pending booking',
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        this.update.emit({ id, comment: this.form.controls.comment.value });
        this.initializeForm();
      },
    });
  }

  onCancel(id: string) {
    this._confirmationService.confirm({
      message: 'Are you sure you want to cancel this booking?',
      header: 'Confirmation cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.canceling(id),
    });
  }

  canceling(id: string) {
    this.bookings = this.bookings.filter((b) => b.id !== id);
    this.cancel.emit(id);
  }

  calculateBookingCount(optionId: string, status?: BookingStatus) {
    return this.bookings.reduce((acc, cur) => {
      if (cur.selectedOptions.includes(optionId) && cur.status == status) return acc + 1;
      return acc;
    }, 0);
  }

  getBookingsWithOption(optionId: string) {
    return this.bookings.filter((b) => b.selectedOptions.includes(optionId));
  }
  onClickBooking(id: string) {
    this._router.navigate(['/follow-up/bookings', id]);
  }
  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }
}
