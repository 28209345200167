<div class="grid grid-cols-7 gap-1 place-items-center">
  <p class="col-span-7 gap-3">{{ startDay! | date : 'MMMM' }}</p>
  <ng-container *ngFor="let day of days">
    <div class="font-semibold">
      {{ day }}
    </div>
  </ng-container>
</div>
<div class="grid grid-cols-7 gap-1 mt-8 place-items-center">
  <ng-container *ngFor="let day of daysInMonth()">
    <div [ngClass]="colStartClasses[getDay(day) - 1]" [ngStyle]="getDayStyle(day)" class="rounded-t bg-opacity-25">
      <p
        (click)="updateTrainingsOnSelectedDay(day); op.toggle($event)"
        class="cursor-pointer select-none flex items-center justify-center h-8 w-8 rounded-full"
        [ngClass]="{
          'opacity-10 pointer-events-none': !isSameMonth(day, startDay!),
          'hover:bg-secondary hover:bg-opacity-50': !isToday(day),
          'bg-accent': isToday(day) && isSameMonth(day, startDay!)
        }"
      >
        {{ day | date : 'd' }}
      </p>
    </div>
  </ng-container>
</div>
<p-overlayPanel #op [focusOnShow]="true">
  <ng-template pTemplate="content">
    <p
      class="text-secondary text-sm"
      *ngIf="
        trainingsOnSelectedDay().length === 0 &&
        bookingsOnSelectedDay().length === 0 &&
        holidaysOnSelectedDay().length === 0
      "
    >
      Nothing found on this day.
    </p>
    <ul *ngIf="trainingsOnSelectedDay().length > 0">
      <li
        *ngFor="let training of trainingsOnSelectedDay()"
        class="border-b border-secondary border-opacity-25 py-2 last:border-0 text-sm"
      >
        <div class="flex flex-col">
          <a class="text-info hover:underline cursor-pointer pr-1" (click)="navigateTrainingDetails(training)">{{
            training.name
          }}</a>
          <div><span class="pi pi-map-marker pr-1"></span>{{ training.address ? training.address : '/' }}</div>
          <div>
            <span class="pi pi-calendar pr-1"></span>{{ training.startDate! | fDate : 'short' }} -
            {{ training.endDate! | fDate : 'short' }}
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="bookingsOnSelectedDay().length > 0" class="text-sm">
      <p class="text-secondary pt-2">My booking(s):</p>
      <ul>
        <li *ngFor="let booking of bookingsOnSelectedDay()">
          <div class="flex flex-col">
            <div class="flex">
              <i class="pi pi-info-circle text-info my-auto pr-1" [pTooltip]="(booking.status | fStatus) ?? ''"></i>
              <a class="text-info hover:underline cursor-pointer pr-1" (click)="navigateBookingDetails(booking)">{{
                booking.training.name
              }}</a>
              ({{ booking.startDate! | fDate : 'short' }} - {{ booking.endDate! | fDate : 'short' }})
              <span
                class="pi pi-calendar-plus text-info cursor-pointer my-auto pl-1"
                (click)="onGetIcal(booking)"
              ></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="holidaysOnSelectedDay().length > 0" class="text-sm">
      <p class="text-secondary pt-2">Holiday(s):</p>
      <ul>
        <li *ngFor="let holiday of holidaysOnSelectedDay()">
          <div class="flex flex-col">
            <div class="flex">
              <div>
                <span class="pr-1">{{ holiday.name }}</span>
                <ng-container *ngIf="!isSameDay(holiday.startDate, holiday.endDate)">
                  ({{ holiday.startDate! | fDate : 'short' }} - {{ holiday.endDate! | fDate : 'short' }})
                </ng-container>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</p-overlayPanel>
