/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BookingAddDto } from '../models/booking-add-dto';
import { BookingBulkDto } from '../models/booking-bulk-dto';
import { BookingCancelDto } from '../models/booking-cancel-dto';
import { BookingDetailDto } from '../models/booking-detail-dto';
import { BookingDto } from '../models/booking-dto';
import { BookingRequestDto } from '../models/booking-request-dto';
import { BookingUpdateDto } from '../models/booking-update-dto';
import { BulkActionDto } from '../models/bulk-action-dto';

@Injectable({ providedIn: 'root' })
export class BookingsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBookings()` */
  static readonly GetBookingsPath = '/api/Bookings';

  /**
   * Retrieves a list of bookings. Using the query parameters, you can filter the bookings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookings$Response(
    params?: {

    /**
     * If true, only bookings of the current user are returned.
     */
      myBookings?: boolean;

    /**
     * If specified, only bookings of the specified seasons are returned.
     */
      seasons?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BookingDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.GetBookingsPath, 'get');
    if (params) {
      rb.query('myBookings', params.myBookings, {});
      rb.query('seasons', params.seasons, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BookingDto>>;
      })
    );
  }

  /**
   * Retrieves a list of bookings. Using the query parameters, you can filter the bookings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBookings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookings(
    params?: {

    /**
     * If true, only bookings of the current user are returned.
     */
      myBookings?: boolean;

    /**
     * If specified, only bookings of the specified seasons are returned.
     */
      seasons?: Array<string>;
    },
    context?: HttpContext
  ): Observable<Array<BookingDto>> {
    return this.getBookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BookingDto>>): Array<BookingDto> => r.body)
    );
  }

  /** Path part for operation `addBooking()` */
  static readonly AddBookingPath = '/api/Bookings';

  /**
   * Adds a booking. The dto has the required 'Id' property.
   * The 'SelectedOptions' property (optional) is a list of training option ids.
   * The 'AddForUser' property (optional) is a user profile for the person you want to add the booking for.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBooking$Response(
    params?: {
  
    /**
     * The booking-add data.
     */
    body?: BookingAddDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.AddBookingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Adds a booking. The dto has the required 'Id' property.
   * The 'SelectedOptions' property (optional) is a list of training option ids.
   * The 'AddForUser' property (optional) is a user profile for the person you want to add the booking for.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBooking(
    params?: {
  
    /**
     * The booking-add data.
     */
    body?: BookingAddDto
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.addBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `getBooking()` */
  static readonly GetBookingPath = '/api/Bookings/{id}';

  /**
   * Retrieves a booking by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.GetBookingPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Retrieves a booking by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.getBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `deleteBooking()` */
  static readonly DeleteBookingPath = '/api/Bookings/{id}';

  /**
   * Delete a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.DeleteBookingPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateBooking()` */
  static readonly UpdateBookingPath = '/api/Bookings/{id}/update';

  /**
   * Updates a booking.
   * The 'SelectedOptions' property (required) is a list of training option ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-update data.
     */
    body?: BookingUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.UpdateBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Updates a booking.
   * The 'SelectedOptions' property (required) is a list of training option ids.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-update data.
     */
    body?: BookingUpdateDto
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.updateBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `requestBooking()` */
  static readonly RequestBookingPath = '/api/Bookings/{id}/request';

  /**
   * Request a booking.
   * The dto has the required 'Motivation' property.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-request data.
     */
    body?: BookingRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.RequestBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Request a booking.
   * The dto has the required 'Motivation' property.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-request data.
     */
    body?: BookingRequestDto
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.requestBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `approveBooking()` */
  static readonly ApproveBookingPath = '/api/Bookings/{id}/approve';

  /**
   * Approve a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.ApproveBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('comment', params.comment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Approve a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.approveBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `rejectBooking()` */
  static readonly RejectBookingPath = '/api/Bookings/{id}/reject';

  /**
   * Reject a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (required) comment.
     */
      comment: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.RejectBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('comment', params.comment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Reject a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (required) comment.
     */
      comment: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.rejectBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `bookBooking()` */
  static readonly BookBookingPath = '/api/Bookings/{id}/book';

  /**
   * Book a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.BookBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('comment', params.comment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Book a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.bookBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `startPendingBooking()` */
  static readonly StartPendingBookingPath = '/api/Bookings/{id}/startPending';

  /**
   * Start pending of a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startPendingBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  startPendingBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.StartPendingBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('comment', params.comment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Start pending of a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startPendingBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startPendingBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The (optional) comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.startPendingBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `startPendingBookingBulk()` */
  static readonly StartPendingBookingBulkPath = '/api/Bookings/startPending';

  /**
   * Start pending of a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startPendingBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startPendingBookingBulk$Response(
    params?: {
  
    /**
     * The bulk booking-startPending data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.StartPendingBookingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Start pending of a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startPendingBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startPendingBookingBulk(
    params?: {
  
    /**
     * The bulk booking-startPending data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.startPendingBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `updatePendingBooking()` */
  static readonly UpdatePendingBookingPath = '/api/Bookings/{id}/updatePending';

  /**
   * Book a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePendingBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePendingBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.UpdatePendingBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('comment', params.comment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Book a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePendingBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePendingBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;

    /**
     * The comment.
     */
      comment?: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.updatePendingBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `updatePendingBookingBulk()` */
  static readonly UpdatePendingBookingBulkPath = '/api/Bookings/updatePending';

  /**
   * Update pending of a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (required) is the reason for booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePendingBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePendingBookingBulk$Response(
    params?: {

    /**
     * The 'Comment' property (required) is the reason for booking.
     */
      comment?: string;
  
    /**
     * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.UpdatePendingBookingBulkPath, 'post');
    if (params) {
      rb.query('comment', params.comment, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Update pending of a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (required) is the reason for booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePendingBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePendingBookingBulk(
    params?: {

    /**
     * The 'Comment' property (required) is the reason for booking.
     */
      comment?: string;
  
    /**
     * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.updatePendingBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `bookBookingBulk()` */
  static readonly BookBookingBulkPath = '/api/Bookings/book';

  /**
   * Book a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookBookingBulk$Response(
    params?: {
  
    /**
     * The bulk booking-book data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.BookBookingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Book a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookBookingBulk(
    params?: {
  
    /**
     * The bulk booking-book data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.bookBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `cancelBooking()` */
  static readonly CancelBookingPath = '/api/Bookings/{id}/cancel';

  /**
   * Cancel a booking.
   * The 'Comment' property (optional) is the reason for cancelling.
   * The 'RefundCredits' property (optional) is a boolean indicating if the credits should be refunded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-cancel data.
     */
    body?: BookingCancelDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.CancelBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Cancel a booking.
   * The 'Comment' property (optional) is the reason for cancelling.
   * The 'RefundCredits' property (optional) is a boolean indicating if the credits should be refunded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
  
    /**
     * The booking-cancel data.
     */
    body?: BookingCancelDto
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.cancelBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `cancelBookingBulk()` */
  static readonly CancelBookingBulkPath = '/api/Bookings/cancel';

  /**
   * Cancel a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelBookingBulk$Response(
    params?: {

    /**
     * A boolean indicating if the credits should be refunded.
     */
      refundCredits?: boolean;
  
    /**
     * The bulk booking-cancel data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.CancelBookingBulkPath, 'post');
    if (params) {
      rb.query('refundCredits', params.refundCredits, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Cancel a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelBookingBulk(
    params?: {

    /**
     * A boolean indicating if the credits should be refunded.
     */
      refundCredits?: boolean;
  
    /**
     * The bulk booking-cancel data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.cancelBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `approveBookingBulk()` */
  static readonly ApproveBookingBulkPath = '/api/Bookings/approve';

  /**
   * Approve a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveBookingBulk$Response(
    params?: {
  
    /**
     * The bulk booking-approve data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.ApproveBookingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Approve a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   * The 'Comment' property (optional) is the reason for booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveBookingBulk(
    params?: {
  
    /**
     * The bulk booking-approve data.
     */
    body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.approveBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `deleteBookingBulk()` */
  static readonly DeleteBookingBulkPath = '/api/Bookings/delete';

  /**
   * Delete a range of bookings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteBookingBulk$Response(
    params?: {
  
    /**
     * A list of booking ids to delete.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.DeleteBookingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Delete a range of bookings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteBookingBulk(
    params?: {
  
    /**
     * A list of booking ids to delete.
     */
    body?: Array<string>
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.deleteBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `verifyBooking()` */
  static readonly VerifyBookingPath = '/api/Bookings/{id}/verify';

  /**
   * Verify a booking.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BookingDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.VerifyBookingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingDetailDto>;
      })
    );
  }

  /**
   * Verify a booking.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<BookingDetailDto> {
    return this.verifyBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<BookingDetailDto>): BookingDetailDto => r.body)
    );
  }

  /** Path part for operation `verifyBookingBulk()` */
  static readonly VerifyBookingBulkPath = '/api/Bookings/verify';

  /**
   * Verify a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyBookingBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  verifyBookingBulk$Response(
    params?: {
      body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BulkActionDto>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.VerifyBookingBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkActionDto>;
      })
    );
  }

  /**
   * Verify a range of bookings.
   * The 'BookingIds' property (required) is a list of booking ids to include in the bulk action.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyBookingBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  verifyBookingBulk(
    params?: {
      body?: BookingBulkDto
    },
    context?: HttpContext
  ): Observable<BulkActionDto> {
    return this.verifyBookingBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkActionDto>): BulkActionDto => r.body)
    );
  }

  /** Path part for operation `exportBookingsOfSeasons()` */
  static readonly ExportBookingsOfSeasonsPath = '/api/Bookings/export';

  /**
   * Export bookings of seasons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportBookingsOfSeasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportBookingsOfSeasons$Response(
    params?: {

    /**
     * The season ids.
     */
      seasonIdsIn?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.ExportBookingsOfSeasonsPath, 'get');
    if (params) {
      rb.query('seasonIdsIn', params.seasonIdsIn, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export bookings of seasons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportBookingsOfSeasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportBookingsOfSeasons(
    params?: {

    /**
     * The season ids.
     */
      seasonIdsIn?: Array<string>;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.exportBookingsOfSeasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getIcalOfBooking()` */
  static readonly GetIcalOfBookingPath = '/api/Bookings/{id}/ical';

  /**
   * Export booking as ical.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIcalOfBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIcalOfBooking$Response(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, BookingsService.GetIcalOfBookingPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export booking as ical.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIcalOfBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIcalOfBooking(
    params: {

    /**
     * The booking id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.getIcalOfBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
