<ng-container [ngSwitch]="viewOptions.length">
  <ng-container *ngSwitchCase="1">
    <p-divider class="w-full" align="left">
      <b class="text-lg">Options</b>
    </p-divider>
    <div class="flex gap-4 ml-2 items-center">
      <div class="flex-1">
        <p>{{ viewOptions.at(0)?.option?.name }}</p>
        <p class="text-secondary text-sm">{{ viewOptions.at(0)?.option?.description }}</p>
        <div class="text-sm">
          <p
            *ngIf="
              viewOptions.at(0)?.option?.startDate &&
              viewOptions.at(0)?.option?.endDate &&
              showDates(viewOptions.at(0)?.option?.startDate, viewOptions.at(0)?.option?.endDate)
            "
          >
            <i class="pi pi-calendar mr-1"></i>{{ viewOptions.at(0)?.option?.startDate | date : 'd MMM' }} -
            {{ viewOptions.at(0)?.option?.endDate | date : 'd MMM' }}
          </p>

          <p *ngIf="viewOptions.at(0)?.option?.deadline">
            Deadline: <i class="pi pi-calendar mr-1"></i>{{ viewOptions.at(0)?.option?.deadline | date : 'd MMM' }}
          </p>
          <div *ngIf="user?.isAdmin || user?.isHumanResources" class="flex text-secondary">
            <p>Cost: €{{ viewOptions.at(0)?.option?.cost }}</p>
            <p *ngIf="viewOptions.at(0)?.option?.halfDays">
              , Days: {{ (viewOptions.at(0)?.option?.halfDays ?? 0) / 2 }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-start w-14 min-w-[3.5rem]">
        <p><i class="pi pi-credit-card mr-1"></i>{{ totalCredits }}</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="0">
    <p-divider class="w-full" align="left">
      <b class="text-lg">Options</b>
    </p-divider>
    <p class="text-center">No options available</p>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="flex justify-between">
      <p-divider class="w-full" align="left">
        <b class="text-lg">Options</b>
      </p-divider>
    </div>
    <ng-container *ngIf="viewOptions">
      <ng-container *ngFor="let option of viewOptions">
        <tc-training-option
          class="grid grid-cols-3 mt-4"
          [disabled]="disabled()"
          [option]="option.option"
          [booking]="booking()"
          [selected]="option.isSelected"
          (selectEvent)="onOptionSelected($event)"
          [showDates]="showDates(option.option.startDate, option.option.endDate)"
        />
      </ng-container>
    </ng-container>
    <div class="w-full flex justify-end gap-2 pt-2">
      Total:
      <div class="flex justify-start items-center w-14">
        <p><i class="pi pi-credit-card mr-1"></i>{{ totalCredits }}</p>
      </div>
    </div>
  </ng-container>
</ng-container>
