import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  private _title = signal('Training catalogus');

  getPageTitle() {
    return this._title;
  }

  setPageTitle(newTitle: string) {
    document.title = newTitle + ' - training-catalog';
    this._title.set(newTitle);
  }
}
