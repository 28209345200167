<tc-loader *ngIf="trainings.isLoading() || seasons.isLoading()" />
<div
  class="flex flex-col lg:flex-row gap-4 p-4 min-h-full max-w-full w-full"
  *ngIf="!trainings.isLoading() && !seasons.isLoading()"
>
  <div
    class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4"
    *ngIf="(trainings.data() && trainings.data()!.length > 0) || (seasons.data() && seasons.data()!.length > 0)"
  >
    <tc-filter-facet
      type="single"
      [selected]="seasonFilters()"
      (selectedChange)="onSetSeasons($event)"
      [options]="seasonOptions()"
      [default]="[seasonOptions()[0].key]"
      header="Season"
      class="w-full"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="otherFilters()"
      (selectedChange)="otherFilters.set($event)"
      [options]="filterOptionsOther()"
      [default]="[filterOptionsOther()[0].key]"
      header="Other"
      class="w-full"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="formatFilters()"
      (selectedChange)="formatFilters.set($event)"
      [options]="formats()"
      [showFormatLegenda]="true"
      header="Format"
      class="w-full"
      *ngIf="formats().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="domainFilters()"
      (selectedChange)="domainFilters.set($event)"
      [options]="domains()"
      header="Domain"
      class="w-full"
      *ngIf="domains().length > 0"
    />
  </div>
  <div [class]="display() === 'Timeline' ? 'lg:w-4/5 lg:pr-4' : 'lg:w-4/5'">
    <p-card>
      <div class="w-full flex justify-center lg:justify-end p-2" [style]="{ height: '100%' }">
        <p-button
          size="small"
          label="Calendar"
          class="border-2 border-r-0 border-accent-content"
          [text]="display() !== 'Calendar'"
          (onClick)="display.set('Calendar')"
          [style]="{ 'border-radius': '0px', padding: '3px 10px' }"
        />
        <p-button
          size="small"
          label="Time Line"
          class="border-2"
          [text]="display() !== 'Timeline'"
          (onClick)="display.set('Timeline')"
          [style]="{
          'border-radius': '0px',
          padding: '3px 10px',
        }"
        />
      </div>

      <p *ngIf="seasons.data()?.length === 0">No seasons found</p>
      <tc-training-calendar-year
        *ngIf="display() === 'Calendar'"
        [trainings]="filteredTrainings()"
        [bookings]="filteredBookings()"
        [publicHolidays]="filteredPublicHolidays()"
        [schoolHolidays]="filteredSchoolHolidays()"
        [season]="currentSeason()"
        (getIcal)="onGetIcal($event)"
      />
      <tc-training-calendar-timeline
        *ngIf="display() === 'Timeline'"
        [bookings]="filteredBookings()"
        [trainings]="filteredTrainings()"
        [season]="currentSeason()"
      />
    </p-card>
  </div>
</div>
