import { Review } from '@/shared/models/review';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { RatingModule } from 'primeng/rating';

@Component({
  selector: 'tc-reviews',
  standalone: true,
  imports: [CommonModule, DividerModule, RatingModule, SharedModule, FormsModule, FormatDatePipe],
  templateUrl: './reviews.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsComponent {
  private readonly _reviews = signal<Review[]>([]);

  @Input() set reviews(value: Review[]) {
    this._reviews.set(value);
  }

  readonly sortedReviews = computed(() => {
    return this._reviews().sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
  });
}
