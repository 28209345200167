import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcDeadlineBeforeStart]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DeadlineBeforeStartValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class DeadlineBeforeStartValidatorDirective {
  static deadlineBeforeStart(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('startDate');
      const deadline = control.get('deadline');

      if (!startDate || !deadline) return { falseDate: true };

      if (new Date(startDate.value) < new Date(deadline.value)) {
        return { falseDeadline: true };
      }

      return null;
    };
  }
}
