import { CollectionAddComponent } from '@/collections/components/collection-add/collection-add.component';
import { CollectionDeleteComponent } from '@/collections/components/collection-delete/collection-delete.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { bookingKeys } from '@/shared/services/booking.service';
import { collectionKeys, CollectionsService } from '@/shared/services/collections.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { reviewKeys } from '@/shared/services/review.service';
import { ToastService } from '@/shared/services/toast.service';
import { trainingKeys, TrainingsService } from '@/shared/services/training.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { TrainingAdminTableComponent } from '@/trainings/components/training-admin-table/training-admin-table.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingCollectionUpsertDto } from '@euricom/angular-training-catalog-api/models';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'tc-collections-upsert',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    LoaderComponent,
    TrainingAdminTableComponent,
    CollectionAddComponent,
    CollectionDeleteComponent,
  ],
  providers: [CollectionsService, TrainingsService],
  templateUrl: './collections-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsUpsertComponent implements OnInit {
  private readonly _queryClient = useQueryClient();
  readonly collectionId = this._route.snapshot.paramMap.get('id');
  readonly returnUrl = this._route.snapshot.queryParamMap.get('returnUrl');

  readonly collection = useQuery(
    computed(() => collectionKeys.byId(this.collectionId)),
    () => {
      return this._collectionService.getTrainingCollectionAsync(this.collectionId ?? '');
    },
    { enabled: computed(() => !!this.collectionId) },
  );

  readonly trainings = useQuery(
    computed(() => trainingKeys.byCollectionId(this.collectionId)),
    async () => {
      return (await this._trainingService.getTrainingsAsync({ collectionId: this.collectionId ?? '' })).sort(
        (a, b) => b.season.year - a.season.year,
      );
    },
    {
      enabled: computed(() => !!this.collectionId),
    },
  );

  readonly upsertCollectionMutation = useMutation(this._collectionService.upsertTrainingCollectionAsync, {
    onSuccess: (trainingCollectionDto) => {
      this._toaster.success('Collection saved.');
      this._queryClient.invalidateQueries(collectionKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);

      if (this.returnUrl) {
        this._router.navigate([this.returnUrl], { queryParams: { collectionId: trainingCollectionDto.id } });
        return;
      }
      this._router.navigate(['/admin/collections']);
    },
  });

  readonly deleteCollectionMutation = useMutation(this._collectionService.deleteTrainingCollectionAsync, {
    onSuccess: () => {
      this._toaster.success('Collection removed.');
      this._router.navigate(['/admin/collections']);
      this._queryClient.invalidateQueries(collectionKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(reviewKeys.all);
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toaster: ToastService,
    private _collectionService: CollectionsService,
    private _trainingService: TrainingsService,
  ) {}

  onUpsertCollection(trainingCollectionUpsertDto: TrainingCollectionUpsertDto) {
    if (this.collectionId) {
      trainingCollectionUpsertDto.id = this.collectionId;
    }

    this.upsertCollectionMutation.mutate(trainingCollectionUpsertDto);
  }

  onDeleteCollection(withForce = false) {
    if (!this.collectionId) {
      this._toaster.error('No collection selected.');
      return;
    }
    this.deleteCollectionMutation.mutate({ collectionId: this.collectionId, withForce: withForce });
  }

  onClickTraining(trainingId: string) {
    this._router.navigate(['/admin/trainings', trainingId]);
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Collection');
  }
}
