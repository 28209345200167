import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'tc-review-delete',
  standalone: true,
  imports: [CommonModule, ButtonModule, CheckboxModule, DividerModule, InfoTooltipComponent],
  templateUrl: './review-delete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewDeleteComponent {
  @Output() delete = new EventEmitter();

  constructor(private _confirmationService: ConfirmationService) {}

  onDelete() {
    this.delete.emit();
  }

  onConfirmDelete() {
    this._confirmationService.confirm({
      message: `Are you sure you want to delete this review?`,
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.onDelete(),
    });
  }
}
