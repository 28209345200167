import { getFormatColor } from '@/shared/models/format';
import { Training } from '@/shared/models/training';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal, WritableSignal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Format } from '@euricom/angular-training-catalog-api/models';
import { SharedModule } from 'primeng/api';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'tc-trainings-data-view',
  standalone: true,
  imports: [CommonModule, DataViewModule, SharedModule, TagModule, RouterLink, FormatDisplayPipe, FormatDatePipe],
  templateUrl: './trainings-data-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingsDataViewComponent {
  readonly sortedTrainings: WritableSignal<Training[] | undefined> = signal(undefined);

  @Input()
  set trainings(value: Training[] | undefined) {
    if (!value) return;
    this.sortedTrainings.set(value);
  }

  constructor(private _router: Router) {}

  onClickRow(id: number) {
    this._router.navigate(['/trainings', id]);
  }

  getFormatColor(format: Format) {
    return getFormatColor(format);
  }
}
