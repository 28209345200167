<div class="flex justify-between">
  <h1 class="text-2xl">Request</h1>
  <tc-info-tooltip *ngIf="!training?.autoApprove"
    text="Requesting a training will add the training to your trainings and ask your Practice Manager for approval."
  />
</div>
<p-divider/>
<form [formGroup]="form">
  <div *ngIf="!training?.autoApprove">
    <label for="motivation">Motivation <span *ngIf="!training?.autoApprove ?? true" class="text-error">*</span></label>
    <textarea
      rows="5"
      id="motivation"
      name="motivation"
      formControlName="motivation"
      pInputTextarea
      class="w-full"
    ></textarea>
  </div>
  <div class="text-error">
    <p
      *ngIf="
        form.controls.motivation.dirty &&
        (form.controls.motivation.hasError('required') || form.controls.motivation.hasError('whitespace'))">
      Motivation is required.
    </p>
    <p *ngIf="form.controls.motivation.dirty && form.controls.motivation.hasError('maxlength')">
      Motivation is too long.
    </p>
    <p
      *ngIf="form.controls.optionSelected.dirty && form.controls.optionSelected.hasError('required')">
      Please select at least one option.
    </p>
    <p
      *ngIf="
        form.controls.noPassedOptionSelected.dirty &&
        form.controls.noPassedOptionSelected.hasError('required')"
    >
      A selected option is not available anymore.
    </p>
  </div>

  <div class="mt-3">
    <p-button
      [loading]="loading()"
      label="Request"
      type="submit"
      size="small"
      (onClick)="onSubmit()"
    />
  </div>
</form>
