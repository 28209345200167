<div class="flex justify-between">
  <h1 class="text-2xl">Review</h1>
  <tc-info-tooltip
    text="After completing the training, kindly provide feedback on your experience. Your colleagues will appreciate it."/>
</div>
<p-divider/>
<section class="mt-4">
  <form [formGroup]="form">
    <div class="flex flex-col">
      <label for="rating" class="font-bold">Rating <span class="text-error">*</span></label>
      <p-rating id="rating" [formControl]="form.controls.rating" [cancel]="false" [stars]="5" class="p-4">
        <ng-template pTemplate="onicon">
          <img src="assets/icons/green_heart.png" width="20px" height="20px" alt="green heart"/>
        </ng-template>
        <ng-template pTemplate="officon">
          <img src="assets/icons/grey_heart.png" width="20px" height="20px" alt="grey heart"/>
        </ng-template>
      </p-rating>
    </div>
    <div>
      <label for="review" class="font-bold">Review Comments <span class="text-error">*</span></label>
      <textarea
        [formControl]="form.controls.message"
        rows="5"
        id="review"
        name="review"
        pInputTextarea
        class="w-full"
      ></textarea>
      <p
        *ngIf="
          form.controls.message.dirty &&
          (form.controls.message.hasError('required') || form.controls.message.hasError('whitespace'))
        "
        class="text-error"
      >
        Comment is required.
      </p>
    </div>
    <div class="mt-2">
      <p-button label="Submit" size="small" (click)="onSubmit()"/>
    </div>
  </form>
</section>
