import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'tc-stand-in-delete',
  standalone: true,
  imports: [DividerModule, ButtonModule],
  templateUrl: './stand-in-delete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandInDeleteComponent {
  @Output() deleteStandIn = new EventEmitter<void>();

  constructor(private _confirmationService: ConfirmationService) {}

  onDelete() {
    this._confirmationService.confirm({
      message: 'Are you sure you want to delete this stand-in?',
      header: 'Confirm delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteStandIn.emit(),
    });
  }
}
