<p *ngIf="loadingForm()">Loading...</p>

<form
  *ngIf="!loadingForm()"
  class="mx-8"
  [formGroup]="form"
  [tcFormKeydownEvent]="{ onSubmit: onSubmit, onCancel: onCancel, context: this }"
>
  <p-divider align="left">
    <b>Collection</b>
  </p-divider>

  <div class="grid grid-cols-5 gap-4 mx-2 my-1">
    <section>
      <label for="season">Season <span class="text-error">*</span></label>
      <p-dropdown
        optionLabel="year"
        inputId="season"
        name="season"
        [options]="seasonOptions"
        [formControl]="form.controls.season"
        placeholder="Select a season"
        #dropdown
      />
      <p *ngIf="form.controls.season.dirty && form.controls.season.hasError('required')" class="text-error">
        Season is required
      </p>
    </section>
    <section class="col-span-2">
      <label for="collection">Collection <span class="text-error">*</span></label>
      <p-dropdown
        [filter]="true"
        filterBy="title"
        [showClear]="true"
        optionLabel="title"
        inputId="collection"
        name="collection"
        [options]="sortedCollections()"
        [formControl]="form.controls.collection"
        placeholder="Select a collection"
      />
      <p *ngIf="form.controls.collection.dirty && form.controls.collection.hasError('required')" class="text-error">
        Collection is required
      </p>
    </section>
    <section class="flex flex-col text-secondary">
      <label for="format">Format</label>
      <input type="text" id="format" name="format" pInputText [readOnly]="true" [formControl]="form.controls.format" />
    </section>
    <section class="mr-8 text-center mt-6">
      <p-button label="Create new" [link]="true" (onClick)="onCreateNewCollection()" />
    </section>
  </div>

  <p-divider align="left">
    <b>Info</b>
  </p-divider>

  <div class="flex gap-4 mx-2 my-1">
    <section class="w-full flex flex-col">
      <label for="name">Name <span class="text-error">*</span></label>
      <input
        type="text"
        id="name"
        name="name"
        pInputText
        [formControl]="form.controls.trainingName"
        [autocomplete]="true"
      />
      <p
        *ngIf="
          form.controls.trainingName.dirty &&
          (form.controls.trainingName.hasError('required') || form.controls.trainingName.hasError('whitespace'))
        "
        class="text-error"
      >
        Name is required
      </p>
      <p
        *ngIf="form.controls.trainingName.dirty && form.controls.trainingName.hasError('maxlength')"
        class="text-error"
      >
        Name is too long
      </p>
    </section>
  </div>

  <div class="grid grid-cols-2 gap-4 mx-2 my-1">
    <section class="w-full">
      <label for="domain">Domain <span class="text-error">*</span></label>
      <p-multiSelect
        inputId="domain"
        name="domain"
        optionLabel="name"
        [options]="sortedDomains()"
        [formControl]="form.controls.knowledgeDomains"
        placeholder="Select one or more domains"
      />
      <p
        *ngIf="
          form.controls.knowledgeDomains.dirty &&
          (form.controls.knowledgeDomains.hasError('required') || form.controls.knowledgeDomains.hasError('whitespace'))
        "
        class="text-error"
      >
        Domain is required
      </p>
    </section>
    <section class="w-full">
      <label for="responsible">Responsible <span class="text-error">*</span></label>
      <p-dropdown
        inputId="responsible"
        name="responsible"
        optionLabel="name"
        [options]="responsibleOptions.data()"
        [formControl]="form.controls.responsible"
        placeholder="Select a responsible"
      />
      <p *ngIf="form.controls.responsible.dirty && form.controls.responsible.hasError('required')" class="text-error">
        Responsible is required
      </p>
    </section>
  </div>

  <div class="flex gap-4 mx-2 my-1">
    <section class="flex flex-col w-full">
      <label for="link">Link</label>
      <input type="text" id="link" name="link" pInputText [formControl]="form.controls.website" />
      <p *ngIf="form.controls.website.dirty && form.controls.website.hasError('pattern')" class="text-error">
        No valid website (ex: https://www.euri.com)
      </p>
      <p *ngIf="form.controls.website.dirty && form.controls.website.hasError('maxlength')" class="text-error">
        Website is too long
      </p>
    </section>
    <section class="flex flex-col w-full">
      <label for="location">Location</label>
      <input type="text" id="location" name="location" pInputText [formControl]="form.controls.address" />
      <p *ngIf="form.controls.address.dirty && form.controls.address.hasError('maxlength')" class="text-error">
        Location is too long
      </p>
    </section>
  </div>

  <div class="flex gap-4 mx-2 my-1">
    <div class="my-1 w-full flex flex-col">
      <label for="description">Description (markdown enabled)</label>
      <textarea
        id="description"
        name="description"
        rows="3"
        pInputTextarea
        [formControl]="form.controls.description"
      ></textarea>
    </div>
  </div>

  <div class="flex gap-4 mx-2 my-1">
    <div class="my-1 w-full flex flex-col">
      <label for="info">Info (admin only)</label>
      <textarea id="info" name="info" rows="2" pInputTextarea [formControl]="form.controls.info"></textarea>
    </div>
  </div>

  <div class="flex gap-4 mx-2 my-1 justify-between">
    <section class="flex flex-row gap-2 items-center my-auto">
      <p-checkbox [binary]="true" inputId="showOnCalendar" class="py-2" [formControl]="form.controls.showOnCalendar" />
      <label class="w-max" for="showOnCalendar">Show on calendar</label>
    </section>
    <section class="flex flex-row gap-2 items-center my-auto">
      <p-checkbox
        [binary]="true"
        inputId="showParticipants"
        class="py-2"
        [formControl]="form.controls.showParticipants"
      />
      <label class="w-max" for="showParticipants">Show participants</label>
    </section>
    <section class="flex flex-row gap-2 items-center w-72">
      <label for="isActive">Active</label>
      <p-dropdown
        optionLabel="display"
        class="py-2 w-full"
        inputId="isActive"
        name="isActive"
        [options]="activeOptions"
        [formControl]="form.controls.trainingIsEnabled"
        #dropdown
      />
    </section>
    <section class="flex flex-row gap-2 items-center my-auto">
      <p-checkbox
        [binary]="true"
        inputId="allowMultipleBookings"
        class="py-2"
        [formControl]="form.controls.allowMultipleBookings"
      />
      <label class="w-max" for="allowMultipleBookings">Allow multiple bookings</label>
    </section>
    <section class="flex flex-row gap-2 items-center">
      <p-checkbox [binary]="true" inputId="autoBook" class="py-2" [formControl]="form.controls.autoBook" />
      <label class="w-max" for="autoBook">Auto book</label>
    </section>
  </div>

  <section *ngIf="form.controls.trainingOptions.controls.length === 1" class="mx-2">
    <tc-training-add-option-simple
      [form]="form.controls.trainingOptions.controls[0]"
      (startDateValueChange)="setOptionEndDate({ startDate: $event, id: 0 })"
      [season]="this.seasonChanges()"
      [saved]="formSaved()"
    />
  </section>

  <section *ngIf="form.controls.trainingOptions.controls.length > 1">
    <p-divider align="left">
      <b>Options</b>
    </p-divider>

    <div [ngClass]="getGridColumnClass()" formArrayName="trainingOptions">
      <ng-container *ngFor="let val of form.controls.trainingOptions.controls; let index = index">
        <section class="p-3">
          <tc-training-add-option
            [form]="val"
            [index]="index"
            [saved]="formSaved()"
            (deleteOption)="onDeleteOption(index)"
            (startDateValueChange)="setOptionEndDate({ startDate: $event, id: index })"
            (copyOption)="onCopyOption(index)"
            [deleteOptionIsDisabled]="checkIfOptionHasBookings(index)"
            [season]="this.seasonChanges()"
          />
        </section>
      </ng-container>
    </div>
  </section>

  <p-button label="Add option" [link]="true" (click)="onAddOption()" />
  <p-button
    *ngIf="form.controls.trainingOptions.controls.length === 1 && copyOptionIsPossible()"
    label="Copy option"
    [link]="true"
    (click)="onCopyOption(0)"
  />
  <p-button
    *ngIf="form.controls.trainingOptions.controls.length === 1 && form.controls.season.valid"
    (click)="onSetSeasonDates()"
    [link]="true"
    label="Take season dates"
  />

  <p-divider />

  <p *ngIf="formSaved() > 0 && (form.invalid || formArrayInvalid)" class="text-error pb-2">
    One or more input fields are invalid.
  </p>
  <p *ngIf="form.hasError('notActiveWhenNoStartEnd')" class="text-error pb-2">
    Training should be in draft state when no start and end date are set.
  </p>

  <div class="flex gap-4">
    <p-button size="small" label="Save" (click)="onSubmit()" [loading]="loadingUpsert()" />
    <p-button size="small" [outlined]="true" label="Cancel" (onClick)="onCancel()" />
  </div>
</form>
