<p-divider align="left">
  <b class="text-lg">Reviews</b>
</p-divider>
<section class="max-h-96 overflow-y-scroll">
  <div class="flex flex-col md:flex-row" *ngFor="let review of sortedReviews()">
    <p class="md:w-1/4 order-1 md:order-1"><span class="pi pi-user mr-2"></span>{{ review.reviewerName }}</p>
    <p class="md:w-2/4 order-3 md:order-2">{{ review.createdOn | fDate : 'short' }} - {{ review.message }}</p>
    <p-rating
      id="rating"
      [(ngModel)]="review.rating"
      [readonly]="true"
      [cancel]="false"
      [stars]="5"
      class="md:p-1 md:w-1/4 md:text-center order-2 md:order-3"
    >
      <ng-template pTemplate="onicon">
        <img src="assets/icons/green_heart.png" width="15px" height="15px" alt="green heart" class="cursor-default"/>
      </ng-template>
      <ng-template pTemplate="officon">
        <img src="assets/icons/grey_heart.png" width="15px" height="15px" alt="grey heart" class="cursor-default"/>
      </ng-template>
    </p-rating>
    <p-divider class="md:hidden" *ngIf="sortedReviews().length > 1"/>
  </div>
</section>
<section *ngIf="sortedReviews().length === 0">
  <p class="text-secondary">No reviews</p>
</section>
