<tc-loader *ngIf="trainings.isLoading()" />
<div class="flex flex-col lg:flex-row gap-4 p-4 min-h-full w-full max-w-full" *ngIf="!trainings.isLoading()">
  <div
    class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4"
    *ngIf="trainings.data() && trainings.data()!.length > 0"
  >
    <tc-filter-facet
      type="multiple"
      [selected]="formatFilters()"
      (selectedChange)="formatFilters.set($event)"
      [options]="formats()"
      header="Format"
      class="w-full"
      *ngIf="formats().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="domainFilters()"
      (selectedChange)="domainFilters.set($event)"
      [options]="domains()"
      header="Domain"
      class="w-full"
      *ngIf="domains().length > 0"
    />
  </div>
  <p-card class="w-full">
    <div class="flex justify-between gap-1 align-middle">
      <tc-training-filter-header [searchValue]="searchExpression()" (search)="searchExpression.set($event)" />
      <p-button
        class="pt-1 hidden md:block"
        [link]="true"
        label="+ new training"
        (onClick)="onRequestNewTraining()"
      ></p-button>
      <tc-sort class="w-44" (sort)="onSort($event)" [selected]="sort()" [options]="sortOptions" />
    </div>
    <div class="flex justify-center w-full mt-2">
      <p-button class="md:hidden" [link]="true" label="+ new training" (onClick)="onRequestNewTraining()"></p-button>
    </div>
    <p-divider />
    <tc-trainings-data-view [trainings]="filteredTrainings()" class="w-full" />
  </p-card>
</div>
