<form [formGroup]="form">
  <p-dropdown
    inputId="sort"
    name="sort"
    [formControl]="form.controls.sort"
    [options]="sortOptions"
    optionLabel="value"
    placeholder="Sort"
    (onChange)="onChangeDropdown()"
  />
</form>
