<div class="flex justify-between">
  <h1 class="text-2xl">Delete</h1>
  <tc-info-tooltip
    text="The season can only be delete when there are no related trainings."/>
</div>
<p-divider/>
<div class="w-full mt-4">
  <p-button
    label="Delete"
    (onClick)="onDelete()"
    size="small"
    [outlined]="true"
    severity="danger"
  />
</div>
