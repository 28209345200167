import { Pipe, PipeTransform } from '@angular/core';
import { BookingStatus } from '../models/BookingStatus';

@Pipe({ standalone: true, name: 'fStatus' })
export class StatusDisplayPipe implements PipeTransform {
  transform(value: BookingStatus | undefined) {
    return statusDisplay(value);
  }
}

export function statusDisplay(value: BookingStatus | undefined | null) {
  return value === 'PendingBooking' ? 'Pending booking' : value;
}
