<tc-loader *ngIf="review.isLoading()" />
<div *ngIf="!review.isLoading()" class="flex md:flex-row gap-4 p-4 h-full flex-col">
  <div class="w-full">
    <div class="flex flex-col gap-4">
      <p-card class="w-full">
        <tc-review-update-form
          [review]="review.data()"
          (newReview)="onUpdateReview($event)"
          [loadingUpdate]="updateReviewMutation.isLoading()"
        />
      </p-card>
    </div>
  </div>
  <div *ngIf="reviewId" class="flex flex-col gap-4 pb-4 md:w-1/3 lg:w-1/4 md:min-w-1/3 lg:min-w-1/4">
    <p-card class="w-full">
      <tc-review-delete (delete)="onDeleteReview()" />
    </p-card>
  </div>
</div>
