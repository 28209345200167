<div class="p-4">
  <div class="hidden md:block">
    <tc-loader *ngIf="seasons.isLoading() && !seasons.data()"/>

    <p-card class="w-full" *ngIf="seasons.data()">
      <div class="flex pb-4">
        <p-button size="small" (onClick)="onClickAddSeason()">Add</p-button>
      </div>
      <p-table
        #seasonTable
        [value]="sortedSeasons()!"
        [scrollable]="true"
        [sortField]="sort()"
        [sortOrder]="sortOrder"
        rowGroupMode="subheader"
        scrollHeight="75vh"
        [styleClass]="'p-datatable-sm'"
        [rowHover]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="year" (click)="setSort('year')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Year</div>
                <p-sortIcon field="year"/>
              </div>
            </th>
            <th pSortableColumn="startDate" (click)="setSort('startDate')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Start</div>
                <p-sortIcon field="startDate"/>
              </div>
            </th>
            <th pSortableColumn="endDate" (click)="setSort('endDate')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">End</div>
                <p-sortIcon field="endDate"/>
              </div>
            </th>
            <th pSortableColumn="creditMultiplier" (click)="setSort('creditMultiplier')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Credit multiplier</div>
                <p-sortIcon field="creditMultiplier"/>
              </div>
            </th>
            <th pSortableColumn="nrOfTrainings" (click)="setSort('nrOfTrainings')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Number of trainings</div>
                <p-sortIcon field="nrOfTrainings"/>
              </div>
            </th>
            <th pSortableColumn="nrOfBookings" (click)="setSort('nrOfBookings')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Number of bookings</div>
                <p-sortIcon field="nrOfBookings"/>
              </div>
            </th>
            <th pSortableColumn="totalBookingCredits" (click)="setSort('totalBookingCredits')">
              <div class="flex items-center">
                <div class="max-w-[90%] truncate ...">Total booking credits</div>
                <p-sortIcon field="totalBookingCredits"/>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-season>
          <tr class="w-fit overflow-x-auto cursor-pointer" (click)="onClickSeason(season.id)">
            <td>{{ season.year }}</td>
            <td>{{ season.startDate | fDate }}</td>
            <td>{{ season.endDate | fDate }}</td>
            <td>{{ season.creditMultiplier }}</td>
            <td>{{ season.nrOfTrainings }}</td>
            <td>{{ season.nrOfBookings }}</td>
            <td>{{ season.totalBookingCredits }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="seasons.data()?.length === 0 && !seasons.isLoading()">
        <p class="text-center font-semibold mt-3">No seasons found</p>
      </div>
    </p-card>
  </div>
</div>

<div class="p-4 pt-0">
  <div class="md:hidden">
    <p-card class="w-full">
      <p-table responsiveLayout="stack" [value]="seasons.data()!" [breakpoint]="'1000px'">
        <ng-template pTemplate="body" let-season>
          <tr>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold w-1/3">Year:</span
                ><span class="self-start">{{ season.year }}</span>
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Start:</span>
                {{ season.startDate | fDate }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">End:</span>
                {{ season.endDate | fDate }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Credit multiplier:</span>
                {{ season.creditMultiplier }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Number of trainings:</span>
                {{ season.nrOfTrainings }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Number of bookings:</span>
                {{ season.nrOfBookings }}
              </div>
            </td>
            <td style="padding: 0.3rem">
              <div class="grid grid-cols-[8.5rem_1fr]">
                <span class="p-column-title font-bold">Total booking credits:</span>
                {{ season.totalBookingCredits }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="seasons.data()?.length === 0 && !seasons.isLoading()">
        <p class="text-center font-semibold mt-3">No seasons found</p>
      </div>
    </p-card>
  </div>
</div>
