import { TrainingCollection } from '@/shared/models/training-collection';
import { formatDisplay } from '@/shared/pipes/format-display-pipe';
import { MaybeSignal, unsignal } from '@/shared/utils/signals';

export const filterAdminCollections = (
  collections: MaybeSignal<TrainingCollection[] | undefined>,
  searchExpression: MaybeSignal<string>,
  formats: MaybeSignal<(string | number)[]>,
) => {
  return (unsignal(collections) ?? [])
    .filter((item) => {
      const formatFilter = unsignal(formats) || [];
      return formatFilter.length === 0 || formatFilter.includes(item.format);
    })
    .filter((item) => {
      const searchValue = unsignal(searchExpression);
      const searchTerms = searchValue ? searchValue.split(' ') : [];
      const trainingText = concatCollection(item);
      return searchTerms.every((searchTerm) => trainingText.includes(searchTerm.toLowerCase()));
    });
};

export function concatCollection(collection: TrainingCollection) {
  const name = collection.title;
  const collectionFormat = collection.format;
  const collectionFormatDisplay = formatDisplay(collection.format);

  return [name, collectionFormat, collectionFormatDisplay].join(' ').toLowerCase();
}
