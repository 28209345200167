import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@euricom/angular-shared';
import { Observable, map } from 'rxjs';

export const guardNotLoggedIn: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _router: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const authService = inject(AuthenticationService);
  return authService.user$.pipe(map((user) => (!user ? true : router.createUrlTree(['/']))));
};
