import { getCollectionForm } from '@/collections/pages/collections-upsert/helpers';
import { KeydownDirective } from '@/shared/directives/form-keydown-events.directive';
import { TrainingCollection } from '@/shared/models/training-collection';
import { FrontendFormat } from '@/shared/pipes/format-display-pipe';
import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Format, TrainingCollectionUpsertDto } from '@euricom/angular-training-catalog-api/models';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'tc-collection-add',
  standalone: true,
  imports: [
    // primeng
    ButtonModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    CalendarModule,
    AutoFocusModule,
    // common
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    KeydownDirective,
  ],
  templateUrl: './collection-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionAddComponent {
  @Input() loadingUpsert = false;
  private readonly _collection = signal<TrainingCollection | undefined>(undefined);
  readonly form = getCollectionForm();

  readonly formats = Object.entries(FrontendFormat).map((entry) => {
    return {
      displayValue: entry[1],
      key: entry[0],
    };
  });

  @Input() set collection(collection: TrainingCollection | undefined) {
    this._collection.set(collection);
  }
  get collection() {
    return this._collection();
  }

  @Output() newCollections = new EventEmitter<TrainingCollectionUpsertDto>();

  constructor(private _router: Router, private location: Location) {
    // set form values when collection is set
    effect(
      () => {
        const collection = this._collection();
        if (!collection) return;

        this.form.setValue({
          title: collection.title,
          format: collection.format,
        });
      },
      { allowSignalWrites: true },
    );
  }

  onSubmit() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;

    const formValue = this.form.value;

    const newCollection: TrainingCollectionUpsertDto = {
      title: formValue.title ?? '',
      format: formValue.format ?? Format.Certificate,
    };
    this.newCollections.emit(newCollection);
  }

  onCancel() {
    this.location.back();
  }
}
