import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcStartBeforeEnd]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: StartBeforeEndDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class StartBeforeEndDirective {
  static startBeforeEnd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('startDate');
      const endDate = control.get('endDate');

      if (!startDate || !endDate) return { falseDate: true };

      if (new Date(startDate.value) > new Date(endDate.value)) {
        return { falseDate: true };
      }

      return null;
    };
  }
}
