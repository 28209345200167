import { Injectable } from '@angular/core';
import {
  AuthenticationSettings,
  Configurations,
} from '@euricom/angular-shared';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';

@Injectable()
export class AppSettings {
  public static readonly key = '';

  [AuthenticationSettings.key]!: AuthenticationSettings;
  [TrainingCatalogApiSettings.key]!: TrainingCatalogApiSettings;
}

export const appSettings: Configurations = [
  { provide: AppSettings, key: AppSettings.key },
  { provide: AuthenticationSettings, key: AuthenticationSettings.key },
  { provide: TrainingCatalogApiSettings, key: TrainingCatalogApiSettings.key },
];
