import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcCommentRequiredApproveReject]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CommentRequiredApproveRejectValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class CommentRequiredApproveRejectValidatorDirective {
  static commentRequiredApproveReject(action: string, pmMail: string, userMail: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (action === 'Reject') {
        return (control.value || '').trim().length ? null : { rejectComment: true };
      }
      if (pmMail !== userMail) {
        return (control.value || '').trim().length ? null : { approveComment: true };
      }
      return null;
    };
  }
}
