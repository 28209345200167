import { InfoTooltipComponent } from '@/shared/components/info-tooltip/info-tooltip.component';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';

export type CancelResult = {
  comment: string | null;
  refundCredits: boolean | null;
};

@Component({
  selector: 'tc-booking-cancel-panel',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DividerModule,
    InputTextareaModule,
    ReactiveFormsModule,
    InfoTooltipComponent,
  ],
  templateUrl: './booking-cancel-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCancelPanelComponent {
  readonly form = this._setupForm();
  @Output() readonly cancel = new EventEmitter<CancelResult>();

  constructor(private _confirmationService: ConfirmationService) {}

  onCancel() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsDirty();
    });
    if (this.form.invalid) return;
    const value = this.form.value;

    this._confirmationService.confirm({
      message: 'Are you sure you want to cancel the booking?',
      header: 'Confirmation cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
        this.cancel.emit({
          comment: value.comment as string,
          refundCredits: value.refund ?? false,
        }),
    });
  }

  private _setupForm() {
    return new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(255), NoWhitespaceValidatorDirective.noWhitespace()],
      }),
      refund: new FormControl<boolean>(false, { nonNullable: true }),
    });
  }
}
