import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcNoWhitespace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoWhitespaceValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class NoWhitespaceValidatorDirective {
  static noWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (control.value || '').trim().length ? null : { whitespace: true };
    };
  }
}
