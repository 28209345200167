<div class="flex justify-between">
  <h1 class="text-2xl">Copy training</h1>
  <tc-info-tooltip text="By copying a training all fields and options are copied over to the new season."/>
</div>
<p-divider/>
<div class="mt-5 flex items-center">
  <p-button class="w-1/4" label="Copy" size="small" [outlined]="true" (click)="onConfirmCopyToSeason()"/>

  <div class="ml-4 w-3/4">
    <p-dropdown
      inputId="seasons"
      [options]="seasonOptions()"
      placeholder="Choose season"
      [formControl]="formControl"
      optionLabel="displayValue"
    />
  </div>
</div>
