<div class="flex justify-between">
  <h1 class="text-2xl">Book</h1>
  <tc-info-tooltip
    text="When you book the training the user is notified that his training is available or cancel it when its not possible to
  book it. Add additional comments when applicable."
  />
</div>
<p-divider />
<form [formGroup]="form">
  <label for="comment">Comment <span *ngIf="requireComment" class="text-error">*</span></label>
  <textarea
    rows="5"
    id="comment"
    name="comment"
    [formControl]="form.controls.comment"
    pInputTextarea
    class="w-full"
  ></textarea>
  <div class="flex flex-wrap text-error">
    <p
      *ngIf="
        form.controls.comment.dirty &&
        (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
      "
    >
      Please enter a comment when you change the options.
    </p>
    <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
      Comment is too long.
    </p>
  </div>
  <div class="mt-3 flex flex-col gap-3">
    <div class="flex flex-wrap text-error">
      <p *ngIf="!optionSelected">Please select at least one option.</p>
    </div>
    <p *ngIf="form.controls.noPassedOptionSelected.dirty && form.controls.noPassedOptionSelected.hasError('required')">
      A selected option is not available anymore.
    </p>
    <div>
      <p-button
        class="mr-1"
        label="Book"
        type="submit"
        [loading]="loading()"
        [disabled]="!optionSelected"
        size="small"
        (onClick)="onBook()"
      />
      <p-button label="Cancel" type="submit" size="small" [outlined]="true" severity="danger" (onClick)="onCancel()" />
    </div>
  </div>
</form>

<p-confirmDialog
  #cd
  key="dialogForCancel"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
  <ng-template pTemplate="message">
    <div>
      <div class="flex content-center">
        <i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem"></i>
        <p class="mb-4">Are you sure you want to cancel this booking?</p>
      </div>
      <form [formGroup]="form">
        <label for="motivation">Comment <span class="text-error">*</span></label>
        <textarea
          rows="3"
          id="motivation"
          name="motivation"
          pInputTextarea
          class="w-full"
          [formControl]="form.controls.comment"
        ></textarea>
        <div class="text-error">
          <p
            *ngIf="
              form.controls.comment.dirty &&
              (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
            "
          >
            Comment is required.
          </p>
          <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
            Comment is too long.
          </p>
        </div>
        <div class="flex align-baseline gap-2">
          <p-checkbox
            [value]="form.controls.refund"
            [binary]="true"
            inputId="refund"
            name="refund"
            [formControl]="form.controls.refund"
          />
          <label for="refund">Refund credits</label>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
