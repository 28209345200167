/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { SeasonDetailAdminDto } from '../models/season-detail-admin-dto';
import { SeasonDetailDto } from '../models/season-detail-dto';
import { SeasonDto } from '../models/season-dto';
import { SeasonUpsertDto } from '../models/season-upsert-dto';

@Injectable({ providedIn: 'root' })
export class SeasonsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSeasons()` */
  static readonly GetSeasonsPath = '/api/Seasons';

  /**
   * Retrieves a list of seasons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeasonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.GetSeasonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeasonDto>>;
      })
    );
  }

  /**
   * Retrieves a list of seasons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SeasonDto>> {
    return this.getSeasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeasonDto>>): Array<SeasonDto> => r.body)
    );
  }

  /** Path part for operation `upsertSeason()` */
  static readonly UpsertSeasonPath = '/api/Seasons';

  /**
   * Add or update a season.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertSeason$Response(
    params?: {
  
    /**
     * The season-upsert data.
     */
    body?: SeasonUpsertDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SeasonDto>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.UpsertSeasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SeasonDto>;
      })
    );
  }

  /**
   * Add or update a season.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertSeason(
    params?: {
  
    /**
     * The season-upsert data.
     */
    body?: SeasonUpsertDto
    },
    context?: HttpContext
  ): Observable<SeasonDto> {
    return this.upsertSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeasonDto>): SeasonDto => r.body)
    );
  }

  /** Path part for operation `getDetailedSeasons()` */
  static readonly GetDetailedSeasonsPath = '/api/Seasons/detail';

  /**
   * Retrieves a list of seasons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedSeasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedSeasons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeasonDetailDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.GetDetailedSeasonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeasonDetailDto>>;
      })
    );
  }

  /**
   * Retrieves a list of seasons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetailedSeasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedSeasons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SeasonDetailDto>> {
    return this.getDetailedSeasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeasonDetailDto>>): Array<SeasonDetailDto> => r.body)
    );
  }

  /** Path part for operation `getSeasonsAdmin()` */
  static readonly GetSeasonsAdminPath = '/api/Seasons/admin';

  /**
   * Retrieves a list of seasons for admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeasonsAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonsAdmin$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeasonDetailAdminDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.GetSeasonsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeasonDetailAdminDto>>;
      })
    );
  }

  /**
   * Retrieves a list of seasons for admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeasonsAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonsAdmin(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SeasonDetailAdminDto>> {
    return this.getSeasonsAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeasonDetailAdminDto>>): Array<SeasonDetailAdminDto> => r.body)
    );
  }

  /** Path part for operation `getSeason()` */
  static readonly GetSeasonPath = '/api/Seasons/{id}';

  /**
   * Retrieves a seasons by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeason$Response(
    params: {

    /**
     * The season id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SeasonDetailDto>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.GetSeasonPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SeasonDetailDto>;
      })
    );
  }

  /**
   * Retrieves a seasons by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeason(
    params: {

    /**
     * The season id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<SeasonDetailDto> {
    return this.getSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeasonDetailDto>): SeasonDetailDto => r.body)
    );
  }

  /** Path part for operation `deleteSeason()` */
  static readonly DeleteSeasonPath = '/api/Seasons/{id}';

  /**
   * Delete a season.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSeason$Response(
    params: {

    /**
     * The season id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SeasonsService.DeleteSeasonPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a season.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSeason(
    params: {

    /**
     * The season id.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
