<form [formGroup]="form">
  <p-divider align="left">
    <b>People</b>
  </p-divider>

  <div class="flex flex-col md:flex-row gap-2 mt-2 w-full">
    <div class="flex flex-col w-full">
      <label for="absentee">Absentee <span class="text-error">*</span></label>
      <p-dropdown
        inputId="absentee"
        name="absentee"
        [options]="mappedUsers()"
        [formControl]="form.controls.people.controls.absentee"
        placeholder="Select a user"
        optionLabel="name"
        filterBy="name"
        [filter]="true"
        [showClear]="true"
      />
      <p *ngIf="form.controls.people.controls.absentee.dirty && form.controls.people.controls.absentee.hasError('required')" class="text-error">
        Absentee is required
      </p>
      <p
        *ngIf="(form.controls.people.dirty || form.controls.people) && form.controls.people.hasError('sameEmail')"
        class="text-error"
      >
        Absentee and replacement should be different
      </p>
    </div>
    <div class="flex flex-col w-full">
      <label for="replacement">Replacement <span class="text-error">*</span></label>
      <p-dropdown
        inputId="replacement"
        name="replacement"
        [options]="mappedUsers()"
        [formControl]="form.controls.people.controls.replacement"
        placeholder="Select a user"
        optionLabel="name"
        filterBy="name"
        [filter]="true"
        [showClear]="true"
      />
      <p *ngIf="form.controls.people.controls.replacement.dirty && form.controls.people.controls.replacement.hasError('required')" class="text-error">
        Replacement is required
      </p>
    </div>
  </div>

  <p-divider align="left">
    <b>Period</b>
  </p-divider>

  <div class="flex flex-col md:flex-row gap-2 mt-2 w-full">
    <div class="flex flex-col w-full">
      <label for="start">Start date <span class="text-error">*</span></label>
      <p-calendar
        inputId="start"
        name="start"
        [firstDayOfWeek]="1"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        [formControl]="form.controls.date.controls.startDate"
      />
      <p
        *ngIf="(form.controls.date.dirty || form.controls.date) && form.controls.date.hasError('falseDate')"
        class="text-error"
      >
        End date should be after start date
      </p>
      <p
        *ngIf="
            (form.controls.date.controls.startDate.dirty || form.controls.date.controls.startDate) &&
            form.controls.date.controls.startDate.hasError('required')
          "
        class="text-error"
      >
        Start date is required
      </p>
    </div>
    <div class="flex flex-col w-full">
      <label for="end">End date <span class="text-error">*</span></label>
      <p-calendar
        inputId="end"
        name="end"
        [firstDayOfWeek]="1"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        [formControl]="form.controls.date.controls.endDate"
      />
      <p
        *ngIf="
            (form.controls.date.controls.endDate.dirty || form.controls.date.controls.endDate) &&
            form.controls.date.controls.endDate.hasError('required')
          "
        class="text-error"
      >
        End date is required
      </p>
    </div>
  </div>

  <div class="flex flex-row gap-2 mt-4">
    <p-button label="Save" size="small" (onClick)="onSubmit()" [loading]="loading" />
    <p-button label="Cancel" size="small" [outlined]="true" (onClick)="onCancel()" />
  </div>
</form>
