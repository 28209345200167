import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '@euricom/angular-shared';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private _authenticationService: AuthenticationService) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this._authenticationService.user$.pipe(map((user) => (user ? true : this.router.createUrlTree(['/login']))));
  }
}
