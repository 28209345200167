import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcSeasonYearAlreadyExist]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SeasonYearAlreadyExistValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class SeasonYearAlreadyExistValidatorDirective {
  static seasonYearAlreadyExist(seasons: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!seasons) return null;

      if (seasons.includes(Number(control.value))) {
        return { seasonYearAlreadyExist: true };
      }
      return null;
    };
  }
}
