import { Season } from '@/shared/models/season';
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[tcStartBeforeEnd]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: StartAndEndInSeasonValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class StartAndEndInSeasonValidatorDirective {
  static startAndEndInSeason(season: Season): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('startDate');
      const endDate = control.get('endDate');

      if (!startDate || !endDate) return null;
      if (!startDate.value || !endDate.value) return null;

      if (startDate.value < season.startDate || season.endDate < endDate.value) {
        return { startAndEndNotInSeason: true };
      }

      return null;
    };
  }
}
