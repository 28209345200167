import { StandIn } from '@/shared/models/stand-in';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { StandInAddCommand, StandInDto, StandInUpdateCommand } from '@euricom/angular-training-catalog-api/models';
import { StandInsService as RootStandInsService } from '@euricom/angular-training-catalog-api/services';
import { firstValueFrom } from 'rxjs';

export function mapStandIn(dto: StandInDto): StandIn {
  return {
    id: dto.id ?? 0,
    absenteeName: dto.absenteeName ?? '',
    absenteeEmail: dto.absenteeEmail ?? '',
    replacementName: dto.replacementName ?? '',
    replacementEmail: dto.replacementEmail ?? '',
    startDate: dto.startDate ? new Date(dto.startDate) : new Date(),
    endDate: dto.endDate ? new Date(dto.endDate) : new Date(),
  };
}

export const standInKeys = {
  all: ['stand-ins'] as const,
  byId: (id: number) => [...standInKeys.all, id] as const,
};

@Injectable()
export class StandInService extends RootStandInsService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  getStandInsAsync = async (): Promise<StandIn[]> => {
    const data = await firstValueFrom(this.getStandIns());
    return data.map(mapStandIn);
  };

  getStandInByIdAsync = async (id: number): Promise<StandIn> => {
    const data = await firstValueFrom(this.getStandIn({ id }));
    return mapStandIn(data);
  };

  upsertStandInAsync = async (payload: StandInAddCommand | StandInUpdateCommand): Promise<StandIn> => {
    let result: StandInDto;
    if ('id' in payload) result = await firstValueFrom(this.updateStandIn({ id: payload.id!, body: payload }));
    else result = await firstValueFrom(this.addStandIn({ body: payload }));
    return mapStandIn(result);
  };

  deleteStandInAsync = async (id: number): Promise<void> => {
    await firstValueFrom(this.deleteStandIn({ id }));
  };
}
