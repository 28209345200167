<div class="p-4">
  <tc-loader *ngIf="standIns.isLoading() && !standIns.data()" />

  <p-card class="w-full" *ngIf="standIns.data()">
    <div class="flex pb-4">
      <a routerLink="/follow-up/stand-ins/add" class="p-button p-button-sm">Add</a>
    </div>

    <p-table
      class="hidden md:block"
      #standInsTable
      [value]="sortedStandIns()!"
      [scrollable]="true"
      [sortField]="sort()"
      [sortOrder]="sortOrder"
      rowGroupMode="subheader"
      scrollHeight="75vh"
      styleClass="p-datatable-sm"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="absenteeName" (click)="setSort('absenteeName')">
            <div class="flex items-center">
              <div class="max-w-[90%] truncate ...">Absentee</div>
              <p-sortIcon field="absenteeName" />
            </div>
          </th>
          <th pSortableColumn="replacementName" (click)="setSort('replacementName')">
            <div class="flex items-center">
              <div class="max-w-[90%] truncate ...">Replacement</div>
              <p-sortIcon field="replacementName" />
            </div>
          </th>
          <th pSortableColumn="startDate" (click)="setSort('startDate')">
            <div class="flex items-center">
              <div class="max-w-[90%] truncate ...">Start date</div>
              <p-sortIcon field="startDate" />
            </div>
          </th>
          <th pSortableColumn="endDate" (click)="setSort('endDate')">
            <div class="flex items-center">
              <div class="max-w-[90%] truncate ...">End date</div>
              <p-sortIcon field="endDate" />
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-standIn>
        <tr class="w-fit overflow-x-auto cursor-pointer" (click)="onClickRow(standIn.id)">
          <td>{{ standIn.absenteeName }}</td>
          <td>{{ standIn.replacementName }}</td>
          <td>{{ standIn.startDate | fDate }}</td>
          <td>{{ standIn.endDate | fDate }}</td>
        </tr>
      </ng-template>
    </p-table>

    <p-table class="md:hidden" responsiveLayout="stack" [value]="standIns.data()!" breakpoint="1000px">
      <ng-template pTemplate="body" let-standIn>
        <tr (click)="onClickRow(standIn.id)">
          <td style="padding: 0.3rem">
            <div class="grid grid-cols-[8.5rem_1fr]">
              <span class="p-column-title font-bold">Absentee</span>
              <span>{{ standIn.absenteeName }}</span>
            </div>
          </td>
          <td style="padding: 0.3rem">
            <div class="grid grid-cols-[8.5rem_1fr]">
              <span class="p-column-title font-bold">Replacement</span>
              <span>{{ standIn.replacementName }}</span>
            </div>
          </td>
          <td style="padding: 0.3rem">
            <div class="grid grid-cols-[8.5rem_1fr]">
              <span class="p-column-title font-bold">Start date</span>
              <span>{{ standIn.startDate | fDate }}</span>
            </div>
          </td>
          <td style="padding: 0.3rem">
            <div class="grid grid-cols-[8.5rem_1fr]">
              <span class="p-column-title font-bold">End date</span>
              <span>{{ standIn.endDate | fDate }}</span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div *ngIf="standIns.data()?.length === 0 && !standIns.isLoading()">
      <p class="text-center font-semibold mt-3">No stand-ins found</p>
    </div>
  </p-card>
</div>
