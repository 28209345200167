import { PageTitleService } from '@/shared/services/page-title.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  imports: [ButtonModule],
  selector: 'tc-page-not-found',
  templateUrl: './page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit {
  constructor(private _pageTitleService: PageTitleService, public _router: Router) {}

  ngOnInit() {
    this._pageTitleService.setPageTitle('Page not found');
  }
}
